import React from "react"
import { Box, BoxProps, IconButton } from "@chakra-ui/react"
import Icon from "@components/Icon"

const ProductQuickViewButton = ({ ...boxProps }: BoxProps) => (
  <Box {...boxProps}>
    <IconButton
      aria-label="Quick view product"
      variant="ghost"
      bg="brand.beige.dark"
      borderRadius="full"
      p={1}
      minW="auto"
      height="unset"
      icon={<Icon name="plus" size="16px" />}
    />
  </Box>
)

export default ProductQuickViewButton
