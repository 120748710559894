import React, { Suspense } from "react"

import Meta from "@components/Meta"
import Main from "@components/Main"
import Header from "@components/Header/Header"
import YotpoCustomer from "@app/components/YotpoCustomer"
import { withLayout } from "@app/hoc/Layout"
import Announcement from "@app/components/Announcement"
import Benefits from "@app/components/Benefits/Benefits"
import Global from "@app/theme/foundations/global"
import type { PageProps } from "@root/types/global"
const CartMiniDrawer = React.lazy(() => import("@app/components/CartMini/CartMiniDrawer"))
const AccountDrawer = React.lazy(() => import("@app/components/Account/AccountDrawer"))
const Footer = React.lazy(() => import("./Footer/Footer"))
const Newsletter = React.lazy(() => import("./Newsletter"))
const RewardsPopup = React.lazy(() => import("./Account/RewardsPopup"))
const CookieBanner = React.lazy(() => import("@app/components/CookieBanner/CookieBanner"))

const Layout: React.FC<PageProps<any>> = ({ children, data, location }) => {
  return (
    <>
      <Global />
      <YotpoCustomer />
      <Meta data={data} location={location} />
      <Announcement />
      <Header />
      <Main>{children}</Main>
      <Benefits />
      <Suspense fallback={<></>}>
        <CartMiniDrawer location={location} />
        <AccountDrawer />
        <Newsletter location={location} />
        <Footer />
        <RewardsPopup location={location} />
        <CookieBanner />
      </Suspense>
    </>
  )
}

export default withLayout(Layout)
