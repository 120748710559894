import React, { memo, useMemo, useState, useCallback, Dispatch, SetStateAction } from "react"
import { AspectRatio, Box } from "@chakra-ui/react"
import ImageHotspot from "@app/components/ImageHotspot"
import { useShopify } from "@app/hooks/useShopify"
import { useAnalytics } from "@app/hooks/useAnalytics"
import { useImage } from "@app/hooks/useImage"
import Image from "@app/components/Image/Image"

export interface ProductCardProps {
  onQuickView?: Dispatch<SetStateAction<BundleProduct | null>>
}

const ProductShopTheLookProductCardImages: React.FC<any> = ({ item, onQuickView, image, stlProductCard = false }) => {
  const { getProducts } = useShopify()
  const { trackProductView } = useAnalytics()
  const { getGatsbyImage } = useImage()

  const [isLoading, setIsLoading] = useState<string>("")
  const defaultImage = stlProductCard ? image : getGatsbyImage(image ? image : item?.images[0]?.src)
  const urlChecker = image && typeof window !== "undefined" ? window?.location?.pathname.includes("products") : false

  const imageProps = {
    src: stlProductCard ? defaultImage : urlChecker ? defaultImage?.src : defaultImage,
    border: "1px",
    borderColor: "brand.divider",
    borderRadius: "sm",
    overflow: "hidden",
  }

  const imageHotspots = useMemo(
    () =>
      item?.tags
        ?.filter(tag => tag?.includes("stl:"))
        ?.map(hotspot => ({
          handle: hotspot?.split(":")[1],
          positionY: hotspot?.split(":")[2],
          positionX: hotspot?.split(":")[3],
        })),
    [item]
  )

  const handleQuickViewClick = useCallback(
    (e, product) => {
      e.preventDefault()
      trackProductView(item, item?.variants?.[0], false)
      onQuickView?.(product)
    },
    [item, onQuickView, trackProductView]
  )

  const onSetActiveLook = async (e, handle: string) => {
    e.preventDefault()
    setIsLoading(handle)
    const product = await getProducts({ firstImages: 10, firstVariants: 10, handles: [handle] })
    if (product?.[0]) handleQuickViewClick(e, product?.[0])
    setIsLoading(null)
  }

  return (
    <Box position={`relative`}>
      <AspectRatio ratio={1}>
        <Image {...imageProps} backgroundColor={`white`} overflow={`hidden`} />
      </AspectRatio>
      {imageHotspots?.map(hotspot => (
        <ImageHotspot
          key={hotspot?.handle}
          posX={hotspot.positionX}
          posY={hotspot.positionY}
          isLoading={isLoading}
          handle={hotspot?.handle}
          onClick={e => onSetActiveLook && onSetActiveLook(e, hotspot?.handle)}
        />
      ))}
    </Box>
  )
}

export default memo(ProductShopTheLookProductCardImages)
