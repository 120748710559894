import React from "react"
import config from "./config.js"
import Layout from "./src/components/Layout"
import Providers from "./src/providers/providers"

export const wrapRootElement = ({ element }) => <Providers>{element}</Providers>

export const wrapPageElement = ({ element, props }) => (
  <Layout {...props} settings={config.settings}>
    {element}
  </Layout>
)

export const onRouteUpdate = props => {
  const { location, prevLocation } = props
  if (
    location?.pathname !== prevLocation?.pathname &&
    /collection|search/.test(location?.pathname) &&
    /collection|search/.test(prevLocation?.pathname)
  )
    window.dispatchEvent(new Event("refreshSearch"))
}
