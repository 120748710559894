import React, { PropsWithChildren, memo, useCallback, useEffect, useState } from "react"
import { ApolloClient, ApolloError, InMemoryCache, useLazyQuery, HttpLink } from "@apollo/client"

import { useSearch } from "@usereactify/search"
import GET_SEARCH_DATA from "@app/queries/getSearchData"
import useSearchNormaliser from "@hooks/useSearchNormaliser"
import config from "../../config.js"

type SanityResultsState = {
  learnPages: NormalisedArticle[]
  collections: NormalisedCollection[]
  pages: NormalisedPage[]
}

type QueryResults = {
  learnPages: GatsbyTypes.SanityArticle[]
  collections: GatsbyTypes.SanityCollection[]
  pages: GatsbyTypes.SanityPageFlexible[]
}

type ContextProps = {
  searchQuery?: string
  sanityResults: SanityResultsState
  loading: boolean
  error?: ApolloError
}

export interface SanitySearchProviderProps extends PropsWithChildren {
  limit?: number
}

export const SanitySearchContext = React.createContext<ContextProps | undefined>(undefined)

const client = new ApolloClient({
  link: new HttpLink({
    uri: `https://${config.store.sanityProjectId}.apicdn.sanity.io/v1/graphql/${config.store.sanityDataset}/default`,
  }),
  cache: new InMemoryCache(),
})

const initialState: SanityResultsState = { learnPages: [], collections: [], pages: [] }

const SanitySearchProvider = ({ children, limit = 4 }: SanitySearchProviderProps) => {
  const { searchTerm } = useSearch()
  const { normaliseLearnPages, normaliseCollections, normalisePages } = useSearchNormaliser()

  const [getSearchData, { loading, error }] = useLazyQuery<QueryResults>(GET_SEARCH_DATA, { client })
  const [sanityResults, setSanityResults] = useState<SanityResultsState>(initialState)

  const refetch = useCallback(
    async (searchQuery: string) => {
      const search = `*${searchQuery}*`
      const { data } = await getSearchData({ variables: { search, limit } })

      const newState: SanityResultsState = data
        ? {
            learnPages: normaliseLearnPages(data.learnPages),
            collections: normaliseCollections(data.collections),
            pages: normalisePages(data.pages),
          }
        : initialState

      setSanityResults(newState)
    },
    [getSearchData, limit, normaliseCollections, normaliseLearnPages, normalisePages]
  )

  useEffect(() => {
    if (!searchTerm) return setSanityResults(initialState)
    refetch(searchTerm)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm])

  const value: ContextProps = { searchQuery: searchTerm, sanityResults, loading, error }

  return <SanitySearchContext.Provider value={value}>{children}</SanitySearchContext.Provider>
}

export default memo(SanitySearchProvider)
