import { useMemo } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useConfigContext } from "@providers/config"
import { useCore } from "@hooks/useCore"

export const usePromotions = () => {
  const {
    settings: { params },
  } = useConfigContext()
  const {
    helpers: { edgeNormaliser, getUrlParameter, handleize },
  } = useCore()

  const { settings, promotions, homepages } = useStaticQuery<GatsbyTypes.StaticPromotionsQuery>(graphql`
    query StaticPromotions {
      settings: sanitySettingPromotions {
        active
      }
      promotions: allSanityPromotion {
        edges {
          node {
            title
            handle {
              current
            }
            menu {
              handle {
                current
              }
            }
            homepage {
              handle {
                current
              }
            }
          }
        }
      }
      homepages: allSanityPageHomepage {
        edges {
          node {
            title
            handle {
              current
            }
          }
        }
      }
    }
  `)

  const defaultPromotion = useMemo(
    () => ({
      title: "Default",
      handle: "default",
      menu: "default",
      homepage: "default",
    }),
    []
  )

  const preview = useMemo(() => getUrlParameter(params.preview), [getUrlParameter, params.preview])
  const active = useMemo(() => handleize(preview || settings?.active || "default"), [handleize, preview, settings])

  const promotionItems =
    useMemo(
      () =>
        edgeNormaliser(promotions)?.map((item: any) => ({
          title: item.title || defaultPromotion.title,
          handle: item.handle?.current || defaultPromotion.handle,
          menu: item.menu?.handle?.current || defaultPromotion.menu,
          homepage: item.homepage?.handle?.current || defaultPromotion.homepage,
        })),
      [edgeNormaliser, promotions, defaultPromotion]
    ) || []

  const homepageItems =
    useMemo(
      () =>
        edgeNormaliser(homepages)?.map((item: any) => ({
          title: item.title,
          handle: item.handle?.current,
          menu: defaultPromotion.menu,
          homepage: item.handle?.current,
        })),
      [edgeNormaliser, homepages, defaultPromotion]
    ) || []

  const promotion =
    promotionItems?.find(({ handle }: { handle: string }) => handle === active) ||
    homepageItems?.find(({ handle }: { handle: string }) => handle === active) ||
    defaultPromotion

  return promotion
}
