import React from "react"
import { Box, IconButton } from "@chakra-ui/react"

import { useAppContext } from "@providers/app"
import Icon from "@components/Icon/Icon"

const NavigationHeaderWidget: React.FC = () => {
  const { state, dispatch } = useAppContext()

  const onToggle = React.useCallback(() => {
    dispatch({
      type: "menu",
      payload: !state.activeMenu,
    })
  }, [dispatch, state?.activeMenu])

  return (
    <Box w={12} mx={1}>
      <IconButton variant="ghost" px={0} aria-label="Manage account" icon={<Icon name="menu" size="20px" />} size="xs" onClick={onToggle} />
    </Box>
  )
}

export default React.memo(NavigationHeaderWidget)
