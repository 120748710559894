import React, { memo, useMemo } from "react"
import { Box, IconButton } from "@chakra-ui/react"
import { useAppContext } from "@providers/app"
import { useCustomerContext } from "@providers/customer"
import { useConfigContext } from "@providers/config"
import { useMedia } from "@hooks/useMedia"
import { useCore } from "@hooks/useCore"
import Link from "@components/Link"

import { UnorderedList, ListItem, Container } from "@chakra-ui/react"
import { useCustomerLogout } from "@hooks/useCustomer"

const AccountWidget: React.FC = () => {
  const {
    settings: { routes },
  } = useConfigContext()
  const {
    helpers: { isBrowser },
  } = useCore()
  const { customer } = useCustomerContext()
  const { logoutCustomer } = useCustomerLogout()
  const { isBase } = useMedia()
  const { state, dispatch, setAccountDrawer } = useAppContext()
  const [active, setActive] = React.useState<boolean>(false)

  const handleActiveAccountDrawer = () => {
    dispatch({
      type: "accountDrawer",
      payload: {
        activeAccountDrawer: !state.activeAccountDrawer,
      },
    }),
      setAccountDrawer("login")
  }

  const accountDetails = [
    { title: "Your Details", url: `${routes.DETAILS}`, type: "link" },
    { title: "Orders", url: `${routes.ORDERS}`, type: "link" },
    { title: "Rewards", url: `${routes.REWARDS}`, type: "link" },
    { title: "Wishlist", url: `${routes.WISHLIST}`, type: "link" },
    { title: "Gift Registry", url: `${routes.GIFTREGISTRY}`, type: "link" },
  ]

  const icon = useMemo(() => {
    return customer ? (
      <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <title id={`Account`}></title>
        <path d="M10 13.0742C4.14568 13.0743 1 17.3748 1 20.0742H19C19 17.644 15.9063 13.0741 10 13.0742Z" fill="currentColor" />
        <path
          d="M1 20.0742H0.5V20.5742H1V20.0742ZM10 13.0742L9.99999 12.5742L10 13.0742ZM19 20.0742V20.5742H19.5V20.0742H19ZM1.5 20.0742C1.5 17.6843 4.38724 13.5743 10 13.5742L9.99999 12.5742C3.90411 12.5743 0.5 17.0653 0.5 20.0742H1.5ZM10 13.5742C12.8153 13.5742 14.9438 14.6605 16.3692 16.0242C17.8142 17.4065 18.5 19.0341 18.5 20.0742H19.5C19.5 18.6841 18.639 16.8117 17.0605 15.3016C15.4624 13.7728 13.0909 12.5742 9.99999 12.5742L10 13.5742ZM1 20.5742H19V19.5742H1V20.5742Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 5.93444C5.00374 8.69781 7.24555 10.9355 10.0085 10.9336C11.3335 10.9336 12.6041 10.4067 13.5404 9.46896C14.4767 8.53125 15.0018 7.25967 15 5.93444C15.0022 4.60891 14.4773 3.33691 13.541 2.39882C12.6047 1.46074 11.3338 0.933593 10.0085 0.933595C7.24489 0.931725 5.0028 3.17041 5 5.93444H5Z"
          fill="currentColor"
          stroke="currentColor"
        />
      </svg>
    ) : (
      <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <title id={`Account`}></title>
        <path
          d="M0.5 20.0742C0.5 20.3504 0.723858 20.5742 1 20.5742C1.27614 20.5742 1.5 20.3504 1.5 20.0742H0.5ZM10 13.0742L10 13.5742L10 13.0742ZM18.5 20.0742C18.5 20.3504 18.7239 20.5742 19 20.5742C19.2761 20.5742 19.5 20.3504 19.5 20.0742H18.5ZM1.5 20.0742C1.5 17.6843 4.38724 13.5743 10 13.5742L9.99999 12.5742C3.90411 12.5743 0.5 17.0653 0.5 20.0742H1.5ZM10 13.5742C12.8153 13.5742 14.9438 14.6605 16.3692 16.0242C17.8142 17.4065 18.5 19.0341 18.5 20.0742H19.5C19.5 18.6841 18.639 16.8117 17.0605 15.3016C15.4624 13.7728 13.0909 12.5742 9.99999 12.5742L10 13.5742Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 5.93444C5.00374 8.69781 7.24555 10.9355 10.0085 10.9336C11.3335 10.9336 12.6041 10.4067 13.5404 9.46896C14.4767 8.53125 15.0018 7.25967 15 5.93444C15.0022 4.60891 14.4773 3.3369 13.541 2.39882C12.6047 1.46074 11.3338 0.933593 10.0085 0.933595C7.24489 0.931725 5.0028 3.17041 5 5.93444H5Z"
          stroke="currentColor"
        />
      </svg>
    )
  }, [customer])

  return isBrowser && !isBase ? (
    customer ? (
      <Box pos={"relative"}>
        <Link onMouseEnter={() => setActive(true)} onMouseLeave={() => setActive(false)} to={routes.DASHBOARD}>
          <IconButton
            variant="ghost"
            aria-label="Manage account"
            icon={icon}
            _focus={{ outlineColor: "none", backgroundColor: "none" }}
            _active={{ outlineColor: "none", backgroundColor: "none" }}
          />
        </Link>
        <Container
          display={active ? "block" : "none"}
          zIndex="99999"
          padding="0 !important"
          width="0"
          position="absolute"
          bottom={0}
          right={0}
          transform={"translate(-50%)"}
          left={"50%"}
        >
          <UnorderedList
            onMouseEnter={() => setActive(true)}
            onMouseLeave={() => setActive(false)}
            backgroundColor="#F3F2EE"
            position="absolute"
            width="max-content"
            listStyleType="none"
            margin={0}
            transform={"translate(-50%)"}
            left={"50%"}
          >
            {accountDetails?.map(item => (
              <Link to={item.url} key={item.title} _focus={{ outline: "none", backgroundColor: "none" }}>
                <ListItem padding="15px 21px" fontSize="16px" fontWeight="400px" color="#1F1F1E">
                  {item.title}
                </ListItem>
              </Link>
            ))}
            <ListItem padding="15px 21px" fontSize="16px" fontWeight="400px" color="#1F1F1E" cursor="pointer" onClick={logoutCustomer}>
              Logout
            </ListItem>
          </UnorderedList>
        </Container>
      </Box>
    ) : (
      <IconButton
        variant="ghost"
        onClick={handleActiveAccountDrawer}
        aria-label="Manage account"
        icon={icon}
        _focus={{ outlineColor: "none", backgroundColor: "none" }}
        _active={{ outlineColor: "none", backgroundColor: "none" }}
      />
    )
  ) : null
}
export default memo(AccountWidget)
