export const styles = {
  global: {
    body: {
      bg: "white",
      color: "text.primary",
    },
    a: {
      color: "text.primary",
      _hover: {
        textDecoration: "underline",
      },
      _focus: { outline: "none !important", boxShadow: "none !important" },
    },
    'input[type="date"]::-webkit-inner-spin-button': {
      display: "none",
      WebkitAppearance: "none",
    },
    'input[type="date"]::-webkit-calendar-picker-indicator': {
      display: "none",
      WebkitAppearance: "none",
    },
    button: {
      _focus: { outline: "none !important" },
    },
  },
}
