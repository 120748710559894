import React from "react"
import { sha256 } from "js-sha256"
import { useConfigContext } from "@app/providers/config"
import { useCustomerContext } from "@app/providers/customer"

const YotpoCustomer: React.FC = () => {
  const {
    store: {
      yotpo: { yotpoApiKey },
    },
  } = useConfigContext()
  const { customer } = useCustomerContext()

  // Yotpo customer hash token
  const yotpoCustomerHashToken = React.useMemo(() => sha256(customer?.email + yotpoApiKey), [customer?.email, yotpoApiKey])

  if (!customer?.email) return null

  return (
    <>
      {/* Yotpo Secure customer identification */}
      <div
        id="swell-customer-identification"
        data-authenticated="true"
        data-email={`${customer?.email}`}
        data-id={`${customer?.id?.split("/").pop()}`}
        data-token={`${yotpoCustomerHashToken}`}
        data-tags={JSON.stringify(customer?.tags)}
        style={{ display: "none" }}
      ></div>
    </>
  )
}

export default YotpoCustomer
