export const Breadcrumb = {
  baseStyle: {
    ol: {
      marginLeft: "0px",
    },
    link: {
      textTransform: "uppercase",
      fontSize: "12px",
      fontWeight: "semibold",
    },
  },
}
