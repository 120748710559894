import React from "react"
import { Global as EmotionGlobal } from "@emotion/react"
import { colors } from "../colors"
// import { colors } from "../colors"

export const Global: React.FC = () => (
  <EmotionGlobal
    styles={`
      @font-face {
        font-family: "Sofia Pro"; 
        src: url("/fonts/SofiaPro-Light.woff2") format("woff2"), url("/fonts/SofiaPro-Light.woff") format("woff");
        font-weight: 300;
        font-style: normal;
        font-display: swap;
      }
      @font-face {
        font-family: "Sofia Pro";
        src: url("/fonts/SofiaPro-RegularItalic.woff2") format("woff2"), url("/fonts/SofiaPro-RegularItalic.woff") format("woff");
        font-weight: 400;
        font-style: italic;
        font-display: swap;
      }
      @font-face {
        font-family: "Sofia Pro";
        src: url("/fonts/SofiaPro-Regular.woff2") format("woff2"), url("/fonts/SofiaPro-Regular.woff") format("woff");
        font-weight: 400;
        font-style: normal;
        font-display: swap;
      }
      @font-face {
        font-family: "Sofia Pro";
        src: url("/fonts/SofiaPro-SemiBold.woff2") format("woff2"), url("/fonts/SofiaPro-SemiBold.woff") format("woff");
        font-weight: 600;
        font-style: normal;
        font-display: swap;
      }
      @font-face {
        font-family: "Sofia Pro";
        src: url("/fonts/SofiaPro-BoldItalic.woff2") format("woff2"), url("/fonts/SofiaPro-BoldItalic.woff") format("woff");
        font-weight: bold;
        font-style: italic;
        font-display: swap;
      }
      @font-face {
        font-family: "Sofia Pro";
        src: url("/fonts/SofiaPro-Bold.woff2") format("woff2"), url("/fonts/SofiaPro-Bold.woff") format("woff");
        font-weight: bold;
        font-style: normal;
        font-display: swap;
      }
      @font-face {
        font-family: 'password';
        font-style: normal;
        font-weight: 400;
        src: url(https://jsbin-user-assets.s3.amazonaws.com/rafaelcastrocouto/password.ttf);
      }
      html {
        scroll-padding-top: 100px;
      }
      input[type="search"]::-webkit-search-decoration,
      input[type="search"]::-webkit-search-cancel-button,
      input[type="search"]::-webkit-search-results-button,
      input[type="search"]::-webkit-search-results-decoration {
        -webkit-appearance:none;
      }

      .swiper-pagination-bullet {
        width: 10px !important;
        height: 10px !important;
        background: none !important;
        border: 1px solid white !important;
        opacity: 1 !important;
      }

      .swiper-pagination-bullet-active {
        background: white !important;
      }

      .swiper-scrollbar {
        background: ${colors.brand.divider} !important;
      }

      .swiper-scrollbar-drag {
        background: ${colors.neutral} !important;
      }

      .swiper-horizontal>.swiper-pagination-bullets, 
      .swiper-pagination-bullets.swiper-pagination-horizontal, 
      .swiper-pagination-custom, 
      .swiper-pagination-fraction {
        bottom: 22px !important;
        left: 0;
      },
      ul,ol {
        margin:0;
        padding-bottom: var(--chakra-space-2);
        line-height:normal;
        text-align:start;
      }
      ul  li  ul {
        list-style-type: circle!important;
      }
      .standard-list ul, .standard-list ol {
        padding-inline-start: 28px !important;
      }
      ul  li  ul,
      ol li  ol {
        padding-bottom: 0;
        padding-top: var(--chakra-space-1);
        @media (min-width:1024px) {
          padding-top: var(--chakra-space-2);
        }
        div {
          &:last-of-type {
            padding-bottom:0;
          }
        }
      }
.rs-price-slider .thumb {
  cursor: pointer;
  background-color: #000000;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  position: absolute;
  top: -50%;
  left: 0;
  margin-top: 1px;
  transform: translateY(-50%);
}

.rs-price-slider .thumb:focus-visible,
.rs-price-slider .thumb:focus,
.rs-price-slider .thumb:hover,
.rs-price-slider .thumb:active {
  outline: 0 !important;
}

.rs-price-slider .thumb.thumb-0:after {
  content: "$" attr(aria-valuenow);
  position: absolute;
  top: 100%;
  left: 0;
  font-size: 12px;
}

.rs-price-slider .thumb.thumb-1:after {
  content: "$" attr(aria-valuenow);
  position: absolute;
  top: 100%;
  right: 0;
  font-size: 12px;
}

.rs-price-slider .track {
  height: 2px;
  background-color: black;
}

.rs-price-slider .track.track-0,
.rs-price-slider .track.track-2 {
  background: #f8f8f8;
}

      .pac-container {
        z-index:9999;
      }
      `}
  />
)

// m={0} pb={PADDING_BOTTOM} lineHeight="normal" textAlign={"start"}

export default Global
