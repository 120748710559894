import { useState, useCallback } from "react"
import { useStaticQuery, graphql, navigate } from "gatsby"
import { useCore, useStorage } from "@hooks/useCore"
import { useConfigContext } from "@providers/config"

import type { Location } from "@root/types/global"

const INITIAL_STATE = {
  password: "",
}

export const useMaintenance = (location: Location) => {
  const {
    settings: { keys, params, routes },
  } = useConfigContext()
  const {
    helpers: { isBrowser, decodeBase64, encodeBase64, getUrlParameter },
  } = useCore()
  const { getStorage, setStorage, removeStorage } = useStorage()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [data, setData] = useState(INITIAL_STATE)

  const { maintenance } = useStaticQuery<GatsbyTypes.StaticMaintenanceQuery>(graphql`
    query StaticMaintenance {
      maintenance: sanitySettingMaintenance {
        title
        password
        enabled
        content
        backgroundColour {
          hex
        }
        backgroundImage {
          asset {
            url
          }
        }
      }
    }
  `)

  const saved: string = getStorage(keys.password)
  const authorised: boolean = !maintenance?.enabled || (saved && maintenance?.password === decodeBase64(saved)) || false
  // const authorised = false
  // maintenance.enabled = true
  const active: boolean = location.pathname.startsWith(routes.PASSWORD)

  const navigateToSite = useCallback(
    () => navigate(`${getUrlParameter(params?.continue) || routes.HOMEPAGE}`, { replace: true }),
    [getUrlParameter, params, routes.HOMEPAGE]
  )

  if (isBrowser) {
    if (maintenance?.enabled) {
      setStorage(keys.maintenance, maintenance?.enabled)
      if (!authorised && !active)
        navigate(`${routes.PASSWORD}${location.pathname !== routes.HOMEPAGE ? `?${params?.continue}=${location.pathname}` : ""}`, {
          replace: true,
        })
    }

    if ((active && !maintenance?.enabled) || (active && maintenance?.enabled && authorised)) {
      removeStorage(keys.maintenance)
      navigateToSite()
    }
  }

  const validatePassword = useCallback(() => {
    setLoading(false)
    if (maintenance?.password === data?.password) {
      setStorage(keys.password, encodeBase64(data?.password))
      setData(INITIAL_STATE)
      setError(false)
      navigateToSite()
    } else {
      removeStorage(keys.password)
      setData(INITIAL_STATE)
      setError(true)
    }
  }, [keys, navigateToSite, setStorage, removeStorage, encodeBase64, setData, setError, maintenance, data])

  const handleSubmit = useCallback(
    (event: React.SyntheticEvent) => {
      setLoading(true)
      event.preventDefault()
      setTimeout(() => {
        validatePassword()
      }, 400)
    },
    [validatePassword]
  )

  const handleChange = useCallback(
    ({ target: { type, name, value, checked } }: React.ChangeEvent<HTMLInputElement>) => {
      setData(prevState => ({
        ...prevState,
        [name]: type === "checkbox" ? checked : value,
      }))
      setError(false)
    },
    [setData, setError]
  )

  return { active, authorised, loading, error, data, handleSubmit, handleChange, validatePassword }
}
