import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useApolloClient, useLazyQuery, useMutation, useQuery } from "@apollo/client"
import { useCore } from "@hooks/useCore"
import { useShop } from "@hooks/useShop"
import { useAppContext } from "@providers/app"
import { useAnalytics } from "@hooks/useAnalytics"
import { useConfigContext } from "@providers/config"
import { useCheckoutContext } from "@providers/checkout"
import { useCustomerContext } from "@providers/customer"
import GET_PRODUCT_PRICE_RANGES from "@app/queries/getProductPriceRanges"

export const useShopify = () => {
  const client = useApolloClient()
  const { shop } = useShop()
  const { customer } = useCustomerContext()
  const {
    settings: { routes },
  } = useConfigContext()
  const { checkout, countryCode } = useCheckoutContext()
  const {
    graphql: {
      queries: { GET_COLLECTION_PRODUCT_COMPLETE, GET_PRODUCTS_BY_HANDLE, GET_COLLECTIONS_BY_HANDLE, GET_PRODUCTS_BY_HANDLE_LIGHT },
    },
    helpers: { edgeNormaliser, encodeShopifyId },
  } = useCore()

  const formatErrors = (errors: any) => {
    const formatted = errors?.message
      ? { message: errors?.message }
      : //@ts-ignore
        Object.assign(...errors.map((value: any) => ({ [value?.field?.[1]]: { code: value?.code, message: value?.message } })))
    return formatted
  }

  const formatMoney = (amount: number, currency = "AUD") =>
    new Intl.NumberFormat(`en-${shop?.primaryDomain?.localization?.country || "AU"}`, {
      style: "currency",
      currency: currency,
    }).format(amount)

  const formatDate = (date: string) =>
    new Intl.DateTimeFormat(`en-${shop?.primaryDomain?.localization?.country || "AU"}`, {
      timeZone: "Australia/Melbourne",
    }).format(new Date(date))

  const imageUrl = (src: string, size: string | number): any => {
    const dimensions = `${size}x${size}`
    const match = typeof src === "string" ? src?.match(/\.(jpg|jpeg|gif|png|bmp|bitmap|tiff|tif)(\?v=\d+)?$/i) : false
    return match && src?.includes(`shopify.com`) && size && size !== "master"
      ? `${src?.split(match[0])[0]}_${dimensions}${match[0]}`.replace(/http(s)?:/, "")
      : src
  }

  const imageSrcSets = (src: string, size: string | number) =>
    typeof src === "string"
      ? src?.includes(`shopify.com`) &&
        [1, 250, 500, 750, 1000, 1250, 1500, 1750, 2000]
          .filter(set => !size || (size && size >= set))
          .map(set => `${imageUrl(src, set)} ${set}w`)
          .join(`,`)
      : null

  const onSale = (price: string, compareAtPrice: string) => compareAtPrice && price && parseInt(compareAtPrice) > parseInt(price)

  const getHandle = (item: any) => item?.handle || item?.shopify?.handle

  const addressNormaliser = (address: any) => ({
    ...address,
    default: address?.id === customer?.defaultAddress?.id,
  })

  const orderNormaliser = (orders: any) =>
    edgeNormaliser(orders)?.map((order: any) => ({ ...order, lineItems: edgeNormaliser(order?.lineItems) }))

  const imageNormaliser = (image: any, size: string | number = "") => ({
    alt: image?.altText || image?.alt || image?.asset?.alt || "",
    src: imageUrl(image?.originalSrc || image?.src || image?.asset?.url || image || "", size),
    srcSet: imageSrcSets(image?.originalSrc || image?.src || image?.asset?.url || image || "", size),
  })

  const videoNormaliser = (video: any, size: string | number = "") => ({
    alt: video?.alt || "",
    preview: imageNormaliser(video?.previewImage || "", size),
    src: video?.sources
      ?.filter(({ format }: { format: string }) => format === "mp4")
      ?.sort((a: any, b: any) => (a.height < b.height ? 1 : -1))?.[0],
  })

  const modelNormaliser = (model: any, size: string | number = "") => ({
    alt: model?.alt || "",
    preview: imageNormaliser(model?.previewImage || "", size),
    sources: model?.sources,
  })

  const priceNormaliser = (presentmentPrices: any, field = "") =>
    Object.assign(
      {},
      ...edgeNormaliser(presentmentPrices)
        .filter(
          (item: any) => (field && item?.[field] ? item[field] : item)?.currencyCode === (checkout?.currencyCode || shop?.currencyCode)
        )
        .map((item: any) => priceFieldNormaliser(item, field))
    )

  const priceFieldNormaliser = (item: any, field = "") => {
    const price = field && item?.[field] ? item[field] : item
    return {
      amount: `${price?.amount}`,
      local: formatMoney(price?.amount),
      afterpay: formatMoney(price?.amount / 4),
      currencyCode: `${price?.currencyCode || price?.currency_code}`,
    }
  }

  const productNormaliser = (product: any) => ({
    ...product,
    collections:
      edgeNormaliser(product?.collections)?.map((collection: any) => ({
        ...collection,
        image: imageNormaliser(collection?.image),
      })) || [],
    images:
      edgeNormaliser(product?.images)?.length > 0
        ? edgeNormaliser(product?.images)?.map((image: any) => imageNormaliser(image))
        : edgeNormaliser(product?.media)
            ?.filter((media: any) => media?.mediaContentType === "IMAGE")
            ?.map((media: any) => imageNormaliser(media?.image)),
    media: edgeNormaliser(product?.media)?.map((media: any) =>
      media?.mediaContentType === "VIDEO"
        ? videoNormaliser(media)
        : media?.mediaContentType === "IMAGE"
        ? imageNormaliser(media?.image)
        : null
    ),
    priceRange: product?.priceRange || {
      maxVariantPrice: {
        amount: product?.presentment_price_ranges?.max_variant_price?.[0]?.amount,
        currencyCode: product?.presentment_price_ranges?.max_variant_price?.[0]?.currency_code,
      },
      minVariantPrice: {
        amount: product?.presentment_price_ranges?.min_variant_price?.[0]?.amount,
        currencyCode: product?.presentment_price_ranges?.min_variant_price?.[0]?.currency_code,
      },
    },
    metafields: edgeNormaliser(product?.metafields),
    models: edgeNormaliser(product?.media)
      ?.filter((media: any) => media?.mediaContentType === "MODEL_3D")
      ?.map((media: any) => modelNormaliser(media)),
    variants: variantsNormaliser(product?.variants) || [],
    videos: edgeNormaliser(product?.media)
      ?.filter((media: any) => media?.mediaContentType === "VIDEO" || media?.mediaContentType === "EXTERNAL_VIDEO")
      ?.map((media: any) => videoNormaliser(media)),
  })

  const variantsNormaliser = (variants: any) => edgeNormaliser(variants)?.map(variantNormaliser)

  const variantNormaliser = (variant: any) => ({
    ...variant,
    ...(variant?.image && { image: imageNormaliser(variant?.image) }),
    ...(variant?.metafields && { metafields: edgeNormaliser(variant?.metafields) }),
  })

  const staticVariantNormaliser = (variant: any) => ({
    ...variant,
    price: {
      amount: variant?.priceV2,
      currencyCode: shop?.currencyCode,
    },
    compareAtPrice: {
      amount: variant?.compareAtPriceV2,
      currencyCode: shop?.currencyCode,
    },
    priceV2: {
      amount: variant?.priceV2,
      currencyCode: shop?.currencyCode,
    },
    compareAtPriceV2: {
      amount: variant?.compareAtPriceV2,
      currencyCode: shop?.currencyCode,
    },
  })

  const adminProductNormaliser = (product: any, config?: any) => {
    return {
      ...product,
      url: routes.PRODUCT,
      availableForSale: product?.variants?.filter(({ available }: { available: boolean }) => available)?.length > 0,
      id: encodeShopifyId(product?.id, "Product"),
      images: product?.images?.map((image: any) => imageNormaliser(image, config?.imageSize || false)) || [],
      legacyId: product?.id,
      productType: product?.product_type,
      priceRange: {
        minVariantPrice: adminPriceNormaliser(product?.presentment_price_ranges?.min_variant_price, "price"),
        maxVariantPrice: adminPriceNormaliser(product?.presentment_price_ranges?.max_variant_price, "price"),
      },
      variants: product?.variants?.map((variant: any) => ({
        ...variant,
        availableForSale: variant?.available,
        id: encodeShopifyId(variant?.id, "ProductVariant"),
        legacyId: variant?.id,
        priceV2: priceNormaliser(adminPresentmentPriceNormaliser(variant?.presentment_prices), "price"),
        compareAtPriceV2: priceNormaliser(adminPresentmentPriceNormaliser(variant?.presentment_prices), "compareAtPrice"),
      })),
    }
  }

  const adminPriceNormaliser = (presentmentPrices: any, field = "") =>
    Object.assign(
      {},
      ...presentmentPrices
        .filter(
          (item: any) => (field && item?.[field] ? item[field] : item)?.currency_code === (checkout?.currencyCode || shop?.currencyCode)
        )
        .map((item: any) => priceFieldNormaliser(item, field))
    )

  const adminPresentmentPriceNormaliser = (presentment_prices: any) =>
    presentment_prices?.map((presentmentPrice: any) => ({
      compareAtPrice: {
        amount: presentmentPrice?.compare_at_price?.amount,
        currencyCode: presentmentPrice?.compare_at_price?.currency_code,
      },
      price: {
        amount: presentmentPrice?.price?.amount,
        currencyCode: presentmentPrice?.price?.currency_code,
      },
    }))

  const collectionNormaliser = (collection: any) => ({
    ...collection,
    id: parseInt(collection?.shopify?.id) || collection?.id,
    handle: collection?.shopify?.handle || collection?.handle,
    image: imageNormaliser(collection?.image),
    ...(collection?.metafields && { metafields: edgeNormaliser(collection?.metafields) }),
    products: collection?.products?.edges?.length ? edgeNormaliser(collection?.products).map(productNormaliser) : [],
    bottomText: collection?.bottomText || "",
  })

  const checkoutNormaliser = (checkout: any) => ({
    ...checkout,
    lineItems: edgeNormaliser(checkout.lineItems) || [],
    discountApplications: edgeNormaliser(checkout.discountApplications),
  })

  const getCollection = async ({
    firstCollections = 0,
    firstImages = 0,
    firstMedia = 0,
    metafieldIdentifiers = [],
    firstProducts = 0,
    firstVariants = 0,
    handle = "",
  }) => {
    const { data } = await client.query({
      query: GET_COLLECTION_PRODUCT_COMPLETE,
      variables: {
        countryCode,
        handle,
        firstCollections,
        firstImages,
        firstMedia,
        metafieldIdentifiers,
        firstProducts,
        firstVariants,
      },
    })

    return collectionNormaliser(data?.collection)
  }

  const getCollections = async ({
    countryCode = "AU",
    handles = [],
    firstCollections = 0,
    firstImages = 0,
    firstMedia = 0,
    metafieldIdentifiers = [],
    firstVariants = 0,
  }) => {
    const { data } = await client.query({
      query: GET_COLLECTIONS_BY_HANDLE(handles),
      variables: {
        countryCode,
        firstCollections,
        firstImages,
        firstMedia,
        metafieldIdentifiers,
        firstVariants,
      },
    })

    //@ts-ignore
    return handles?.map(handle => collectionNormaliser(data[`product${handle?.replace(/-/g, "")}`]))
  }

  type GetProductPricesConfig = { countryCode?: string; handles: string[] }

  const getProductPriceRanges = async ({ countryCode = "AU", handles = [] }: GetProductPricesConfig) => {
    const { data } = await client.query({
      query: GET_PRODUCT_PRICE_RANGES(handles),
      variables: {
        countryCode,
      },
    })

    return handles?.map(handle => {
      const product = data[`product${handle?.replace(/-/g, "")}`]

      return {
        handle: product?.handle,
        priceRange: product?.priceRange,
      }
    })
  }

  const getProducts = async ({
    countryCode = "AU",
    handles = [] as string[],
    firstCollections = 0,
    firstImages = 0,
    firstMedia = 0,
    metafieldIdentifiers = [],
    firstVariants = 0,
  }) => {
    const filteredHandles = handles.filter(handle => handle)
    if (!filteredHandles.length) return []

    const { data } = await client.query({
      query: GET_PRODUCTS_BY_HANDLE(filteredHandles),
      variables: {
        countryCode,
        firstCollections,
        firstImages,
        firstMedia,
        metafieldIdentifiers,
        firstVariants,
      },
    })

    //@ts-ignore
    return filteredHandles?.map(handle => productNormaliser(data[`product${handle?.replace(/-/g, "")}`]))
  }

  const getProductsLight = async ({ firstImages = 0, firstVariants = 0, handles = [] }) => {
    const { data } = await client.query({
      query: GET_PRODUCTS_BY_HANDLE_LIGHT(handles),
      variables: {
        countryCode,
        firstImages,
        firstVariants,
      },
    })

    //@ts-ignore
    return handles?.map(handle => productNormaliser(data[`product${handle?.replace(/-/g, "")}`]))
  }

  return {
    client,
    useQuery,
    useLazyQuery,
    useMutation,
    formatErrors,
    onSale,
    imageUrl,
    imageSrcSets,
    formatDate,
    formatMoney,
    imageNormaliser,
    checkoutNormaliser,
    orderNormaliser,
    addressNormaliser,
    productNormaliser,
    variantNormaliser,
    variantsNormaliser,
    staticVariantNormaliser,
    collectionNormaliser,
    adminProductNormaliser,
    getHandle,
    getCollection,
    getCollections,
    getProducts,
    getProductPriceRanges,
    getProductsLight,
  }
}

export const useShopifyProduct = () => {
  const { activeProduct, setActiveProduct } = useAppContext()
  const { trackProductView } = useAnalytics()

  const selectProduct = useCallback(
    (product, path) => {
      if (path?.includes("products") && product) {
        let currentProduct = product

        try {
          currentProduct = product?.shopify?.raw ? JSON.parse(product?.shopify?.raw) : product
        } catch (e) {
          console.error((e as Error).message)
        }

        if (!activeProduct || activeProduct.id !== currentProduct.id) {
          setActiveProduct(currentProduct)
          trackProductView(currentProduct, currentProduct.variants[0], false)
        }
      } else {
        if (activeProduct !== false) setActiveProduct(false)
      }
    },
    [activeProduct, setActiveProduct, trackProductView]
  )

  return { activeProduct, selectProduct }
}

export const useShopifyProductRaw = (product: any) => {
  const { staticVariantNormaliser } = useShopify()
  return useMemo(() => {
    try {
      const rawProduct = product?.shopify?.raw ? JSON.parse(product?.shopify?.raw) : product
      return {
        product: {
          ...rawProduct,
          variants: rawProduct.variants.map(staticVariantNormaliser),
        },
      }
    } catch (err) {
      // console.error("Error parsing raw shopify product")
      return { product }
    }
  }, [product])
}

export const useShopifyProductLive = (product: any) => {
  const {
    helpers: { edgeNormaliser },
    graphql: {
      queries: { GET_PRODUCT },
    },
  } = useCore()
  const {
    store: { locationRegion },
  } = useConfigContext()
  const { countryCode } = useCheckoutContext()
  const { variantsNormaliser } = useShopify()

  const [getProduct, { data, called, loading, error }] = useLazyQuery(GET_PRODUCT, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    variables: {
      countryCode: countryCode || locationRegion,
      handle: product?.handle,
      metafieldIdentifiers: [{ namespace: "dotapparel21", key: "info_and_care" }],
      firstVariants: 50,
      firstImages: 10,
      firstMedia: 10,
      firstCollections: 10,
    },
  })

  useEffect(() => {
    if (product?.handle && countryCode && !called) getProduct()
  }, [product?.handle, called, countryCode, getProduct])

  return useMemo(() => {
    if (error) console.error(error)
    let liveProduct = undefined
    try {
      liveProduct = data?.product && {
        ...product,
        metafields: edgeNormaliser(data?.product?.metafields),
        variants: product.variants.map((variant: any, index: number) => ({
          ...variant,
          ...variantsNormaliser(data.product.variants)[index],
        })),
      }
      return { product: liveProduct, loading, error }
    } catch (err) {
      console.error("Error parsing live shopify product")
      return { product, loading, error }
    }
  }, [product, data, loading, error])
}

export const useShopifyVariants = ({ firstAvailable = true, useParameter = false, loading = false, useContext = true, product }) => {
  const {
    helpers: { encodeShopifyId, decodeShopifyId, getUrlParameter, setUrlParameter },
  } = useCore()
  const { setActiveVariant: setActiveVariantContext } = useAppContext()
  const [activeVariant, setActiveVariant] = useState(null)
  const { checkout } = useCheckoutContext()
  const { shop } = useShop()
  const selectedOptionRef = React.useRef({})
  const {
    settings: { params },
  } = useConfigContext()
  const { id, variants } = useMemo(() => {
    return product || {}
  }, [product])

  const currentVariant = getUrlParameter(params?.variant)
  const defaultVariant =
    product?.defaultWishlistVariant ||
    (useParameter && variants?.find(({ id }: { id: string }) => id === encodeShopifyId(currentVariant, "ProductVariant"))) ||
    (firstAvailable && variants?.find(({ availableForSale }: { availableForSale: boolean }) => availableForSale)) ||
    variants?.[0]

  const [selectedOptions, setSelectedOptions] = useState(defaultVariant?.selectedOptions || [])

  const handleOptions = useCallback(
    option => {
      setSelectedOptions(
        selectedOptions?.map((selectedOption: any) => {
          return selectedOption.name === option.name ? option : selectedOption
        })
      )
      selectedOptionRef.current.option = option
    },
    [activeVariant, decodeShopifyId, defaultVariant?.id, params.variant, selectedOptions, setUrlParameter]
  )

  useEffect(() => {
    if (selectedOptionRef?.current?.option?.changeURL) {
      window.history.replaceState(
        null,
        window.document.title,
        setUrlParameter(params.variant, decodeShopifyId(activeVariant.id, "ProductVariant"))
      )
    }
  }, [activeVariant, defaultVariant, decodeShopifyId, params.variant, selectedOptionRef, setUrlParameter])

  useEffect(() => {
    if (
      (checkout?.currencyCode || shop?.currencyCode) &&
      !loading &&
      activeVariant &&
      !activeVariant?.priceV2?.amount &&
      defaultVariant?.priceV2?.amount
    ) {
      setSelectedOptions(defaultVariant?.selectedOptions)
    }
    // Intentionally only run at selected times
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkout?.currencyCode, id, loading, shop?.currencyCode, variants?.length, defaultVariant?.selectedOptions])

  // intentionally exclude variants from dependencies
  const hasVariantWithOption = useCallback(() => {
    return true
  }, [])

  useEffect(() => {
    if (useParameter) {
      const activeVariant =
        variants?.find(
          ({ selectedOptions: variantOptions }: { selectedOptions: any }) =>
            variantOptions?.filter(
              (variantOption: any) =>
                variantOption.value === selectedOptions.find((selectedOption: any) => selectedOption.name === variantOption.name)?.value
            )?.length === selectedOptions?.length
        ) || null

      if (!activeVariant) {
        const selectedSwatchOption = selectedOptions.find((selectedOption: any) => selectedOption.swatch)
        const handle = selectedSwatchOption?.swatch?.handle || product?.handle
        if (handle) {
          window.location.href = `/products/${handle}`
        }
      }
      setActiveVariant(activeVariant)
      if (useContext) setActiveVariantContext(activeVariant)
    } else {
      const activeVariant =
        variants?.find(
          ({ selectedOptions: variantOptions }: { selectedOptions: any }) =>
            variantOptions?.filter(
              (variantOption: any) =>
                variantOption.value === selectedOptions.find((selectedOption: any) => selectedOption.name === variantOption.name)?.value
            )?.length === selectedOptions?.length
        ) || null
      setActiveVariant(activeVariant)
      if (useContext) setActiveVariantContext(activeVariant)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions, variants])

  return { activeVariant, handleOptions, selectedOptions, hasVariantWithOption }
}

export const useShopifyPrice = (variant: any, updatedQty = 1) => {
  const { checkout } = useCheckoutContext()
  const { formatMoney } = useShopify()

  return useMemo(() => {
    if (!variant || !checkout?.currencyCode) {
      return {
        onSale: false,
        price: undefined,
        currencyCode: "AUD",
        formattedPrice: undefined,
        compareAtPrice: undefined,
        formattedCompareAtPrice: undefined,
      }
    }

    const currencyCode = checkout?.currencyCode ?? "AUD"

    const priceValue = variant?.priceV2?.amount || Number(variant?.priceV2) || variant?.price || 0
    const price = priceValue ? Number(priceValue) * updatedQty : 0

    const compareAtPriceValue = variant?.compareAtPriceV2?.amount || Number(variant?.compareAtPriceV2) || variant?.compare_at_price || 0
    const compareAtPrice = compareAtPriceValue ? Number(compareAtPriceValue) * updatedQty : 0

    const onSale = !!compareAtPrice && !!price && compareAtPrice > price
    const formattedPrice = formatMoney(price, currencyCode)
    const formattedCompareAtPrice = formatMoney(compareAtPrice, currencyCode)

    return {
      price,
      onSale,
      currencyCode,
      compareAtPrice,
      formattedPrice,
      formattedCompareAtPrice,
    }
  }, [variant, checkout?.currencyCode, formatMoney])
}

export const useShopifyPriceRange = (product: any, separator = " to ") => {
  const { checkout } = useCheckoutContext()
  const { formatMoney } = useShopify()
  const {
    helpers: { formatPrice },
  } = useCore()

  return useMemo(() => {
    if (!product || !checkout?.currencyCode) {
      return {
        priceMin: undefined,
        priceMax: undefined,
        currencyCode: "AUD",
        formattedPriceRange: undefined,
      }
    }

    const currencyCode = checkout?.currencyCode ?? "AUD"
    const priceRangeMin = product?.priceRange?.minVariantPrice || 0
    const priceRangeMax = product?.priceRange?.maxVariantPrice || 0
    const priceMin = priceRangeMin?.amount ? Number(priceRangeMin?.amount) : 0
    const priceMax = priceRangeMax?.amount ? Number(priceRangeMax?.amount) : 0
    const formattedPriceRange =
      priceMin < priceMax && priceMin > 0
        ? `${formatMoney(priceMin, currencyCode)}${separator}${formatPrice(`${priceMax}`)}`
        : formatMoney(priceMax, currencyCode)

    //calculate discount price
    const onSale = product?.discount_amount
    const compareAtPriceMax = onSale ? priceMax + product?.discount_amount || 0 : 0
    const compareAtPriceMin = onSale ? priceMin + product?.discount_amount || 0 : 0
    const formattedSalePriceRange =
      compareAtPriceMin < compareAtPriceMax && compareAtPriceMin > 0
        ? `${formatMoney(compareAtPriceMin, currencyCode)}${separator}${formatPrice(`${compareAtPriceMax}`)}`
        : formatMoney(compareAtPriceMax, currencyCode)

    return {
      priceMin,
      priceMax,
      currencyCode,
      formattedPriceRange,
      formattedSalePriceRange,
      onSale,
    }
  }, [product, checkout?.currencyCode, separator, formatMoney, formatPrice])
}
