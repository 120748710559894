import React from "react"
import { ImageProps, Stack } from "@chakra-ui/react"

import Image from "@components/Image/Image"
import TextButton from "@components/TextButton"
import Link from "./Link"

export interface TileLinkProps extends Pick<ImageProps, "width" | "w" | "height" | "h"> {
  item: MenuItem
}

const TileLink = ({ item, ...imageProps }: TileLinkProps) => {
  return (
    <Link to={item.url}>
      <Stack>
        <Image src={item.image} borderRadius="xs" {...imageProps} />
        <TextButton icon="chevronRight" size="24px" w="fit-content" lineHeight={2}>
          {item.title}
        </TextButton>
      </Stack>
    </Link>
  )
}

export default TileLink
