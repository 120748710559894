import React from "react"

import NavigationHeaderDesktop from "./Desktop/NavigationHeaderDesktop"
import NavigationHeaderMobile from "./Mobile/NavigationHeaderMobile"
import { Box } from "@chakra-ui/react"
import { useMedia } from "@hooks/useMedia"

export interface NavigationHeaderProps {
  active: string | null
  setActive: (key: string | null) => void
}

const NavigationHeader = (props: NavigationHeaderProps) => {
  const { isHydrated, isBase } = useMedia()

  return (
    <>
      {!isHydrated ||
        (isBase && (
          <Box display={["block", "none"]}>
            <NavigationHeaderMobile />
          </Box>
        ))}
      {!isHydrated ||
        (!isBase && (
          <Box display={["none", "block"]}>
            <NavigationHeaderDesktop {...props} />
          </Box>
        ))}
    </>
  )
}

export default React.memo(NavigationHeader)
