import React from "react"
import { TabList, Button, Stack, Box, Tabs, Tab, TabPanels, TabPanel, Heading } from "@chakra-ui/react"
import { Results, useSearch } from "@usereactify/search"

import useSearchSettings from "@hooks/useSearchSettings"
import useSanitySearch from "@hooks/useSanitySearch"

import { LoadingResultsMobile } from "./Product"
import SearchTermList from "./Search"
import { useConfigContext } from "@app/providers/config"
import ProductCard from "@app/components/Product/ProductCard"
import Link from "@app/components/Link"
import { useYotpoScript } from "@app/hooks/useYotpoScript"

const InstantSearchMobile = ({ onClose }) => {
  const { suggestions } = useSearchSettings()
  const { searchTerm } = useSearch()

  const {
    sanityResults: { learnPages, collections },
    searchQuery,
  } = useSanitySearch()

  const tabs = ["Products", "Categories", "Learn"]

  const collectionTitles = collections.map(collection => collection.title as string)
  const learnPageTitles = learnPages.map(page => page.title as string)
  const learnPageSuggestions = suggestions.learnPages

  const {
    settings: { routes },
  } = useConfigContext()

  const [noResults, setNoResults] = React.useState(true)
  useYotpoScript(searchQuery)
  return (
    <Tabs>
      <TabList>
        {tabs.map(tab => (
          <Tab key={tab} p={2} w={"38%"}>
            <Heading as="h4" size="h4" color="inherit">
              {tab}
            </Heading>
          </Tab>
        ))}
      </TabList>
      <TabPanels>
        <TabPanel>
          <Box>
            <Heading as="h3" size="h3" fontWeight="semibold" mb={2.5}>
              {noResults ? "Popular Products" : "Suggested Products"}
            </Heading>
            <Stack spacing={4.5}>
              <Results
                pageSize={8}
                renderResultCardCallout={() => <></>}
                renderResultCardProduct={props => {
                  setNoResults(false)
                  return (
                    <Box onClick={() => onClose()} key={props.product.id}>
                      <ProductCard item={props.product} location={null} fullSize isInstantSearch={true} />
                    </Box>
                  )
                }}
                listStyle={{
                  display: "grid",
                  gap: "12px",
                  gridTemplateColumns: `repeat(2, 1fr)`,
                }}
                renderNoResults={() => {
                  setNoResults(true)
                  return (
                    <Box display="flex" flexWrap="wrap" justifyContent="center">
                      {suggestions.products.map(({ product }) => (
                        <Box key={product.id} onClick={() => onClose()} w="45%" mx="5px">
                          <ProductCard item={product} location={null} fullSize isInstantSearch={true} />
                        </Box>
                      ))}
                    </Box>
                  )
                }}
                renderLoading={() => {
                  setNoResults(true)
                  return <LoadingResultsMobile />
                }}
              />
              {searchTerm !== "" && !noResults && (
                <Button as={Link} to={`${routes.SEARCH}?q=${searchQuery}`} size="lg" variant="blue" onClick={() => onClose()}>
                  View all products
                </Button>
              )}
            </Stack>
          </Box>
        </TabPanel>
        <TabPanel>
          {collectionTitles.length ? (
            <SearchTermList title="Categories" items={collectionTitles} query={searchQuery} />
          ) : (
            <SearchTermList title="Popular Searches" items={suggestions.searchTerms} />
          )}
        </TabPanel>
        <TabPanel>
          {learnPageTitles.length ? (
            <SearchTermList title="Learn Pages" items={learnPageTitles} query={searchQuery} />
          ) : (
            <SearchTermList title="Popular Learn Pages" items={learnPageSuggestions} link={true} onClose={onClose} />
          )}
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}

export default InstantSearchMobile
