import React from "react"
import { useTheme, useMediaQuery } from "@chakra-ui/react"

import { useHydration } from "./useHydration"

type UseMedia = {
  isBase: boolean
  isSmall: boolean
  isMedium: boolean
  isLarge: boolean
  isXLarge: boolean
  isTouch: boolean
  isHydrated: boolean
}

export const useMedia = (): UseMedia => {
  const { breakpoints } = useTheme()
  const { isHydrated } = useHydration()

  const parseBreakpoint = (breakpoint: string) => parseInt(breakpoint.replace("rem", "").replace("em", "")) * 16

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const queries = {
    base: useMediaQuery(`(max-width: ${parseBreakpoint(breakpoints.sm) - 1}px)`)?.[0],
    sm: useMediaQuery(`(min-width: ${parseBreakpoint(breakpoints.sm)}px)`)?.[0],
    md: useMediaQuery(`(min-width: ${parseBreakpoint(breakpoints.md)}px)`)?.[0],
    lg: useMediaQuery(`(min-width: ${parseBreakpoint(breakpoints.lg)}px)`)?.[0],
    xl: useMediaQuery(`(min-width: ${parseBreakpoint(breakpoints.xl)}px)`)?.[0],
    touch: useMediaQuery(`(hover: none)`)?.[0],
  }

  const isBase = React.useMemo(() => {
    return isHydrated ? queries.base : true
  }, [isHydrated, queries])
  const isSmall = React.useMemo(() => {
    return isHydrated ? queries.sm : false
  }, [isHydrated, queries])
  const isMedium = React.useMemo(() => {
    return isHydrated ? queries.md : false
  }, [isHydrated, queries])
  const isLarge = React.useMemo(() => {
    return isHydrated ? queries.lg : false
  }, [isHydrated, queries])
  const isXLarge = React.useMemo(() => {
    return isHydrated ? queries.xl : false
  }, [isHydrated, queries])
  const isTouch = React.useMemo(() => {
    return isHydrated ? queries.touch : false
  }, [isHydrated, queries])

  return { isBase, isSmall, isMedium, isLarge, isXLarge, isTouch, isHydrated }
}
