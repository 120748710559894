// Illustrations
import pureLove from "@static/images/illustrations/pure-love.png"
import bundle from "@static/images/illustrations/bundle.png"
import clothes from "@static/images/illustrations/clothes.png"
import cotton from "@static/images/illustrations/cotton.png"
import fieldToFamily from "@static/images/illustrations/field-to-family.png"
import nest from "@static/images/illustrations/nest.png"
import pureBabyPureLove from "@static/images/illustrations/pure-baby-pure-love.png"
import sizing from "@static/images/illustrations/sizing.png"
import dandelion from "@static/images/illustrations/dandelion.png"
import spores from "@static/images/illustrations/spores.png"
import spores2 from "@static/images/illustrations/spores-2.png"
import spores3 from "@static/images/illustrations/spores-3.png"

// Benefit Icons
import antiBacterial from "@static/images/benefits/anti-bacterial.png"
import applique from "@static/images/benefits/applique.png"
import bamboo from "@static/images/benefits/bamboo.png"
import beechWood from "@static/images/benefits/beech-wood.png"
import bpaFree from "@static/images/benefits/bpa-free.png"
import coconutButtons from "@static/images/benefits/coconut-buttons.png"
import cuffedArm from "@static/images/benefits/cuffed-arm.png"
import cuffedLeg from "@static/images/benefits/cuffed-leg.png"
import dishwasherSafe from "@static/images/benefits/dishwasher-safe.png"
import droppedCrotch from "@static/images/benefits/dropped-crotch.png"
import foodGradeSilicon from "@static/images/benefits/food-grade-silicon.png"
import handEyeCoordination from "@static/images/benefits/hand-eye-coordination.png"
import layeringFriendly from "@static/images/benefits/layering-friendly.png"
import linenRich from "@static/images/benefits/linen-rich.png"
import machineWashable from "@static/images/benefits/machine-washable.png"
import nonToxic from "@static/images/benefits/non-toxic.png"
import quickDrying from "@static/images/benefits/quick-drying.png"
import ruffles from "@static/images/benefits/ruffles.png"
import teethingFriendly from "@static/images/benefits/teething-friendly.png"
import woolBlend from "@static/images/benefits/wool-blend.png"

export const Illustrations = {
  pureLove,
  bundle,
  clothes,
  cotton,
  fieldToFamily,
  nest,
  pureBabyPureLove,
  sizing,
  dandelion,
  spores,
  spores2,
  spores3,
}

export const Benefits = {
  antiBacterial,
  applique,
  bamboo,
  beechWood,
  bpaFree,
  coconutButtons,
  cuffedArm,
  cuffedLeg,
  dishwasherSafe,
  droppedCrotch,
  foodGradeSilicon,
  handEyeCoordination,
  layeringFriendly,
  linenRich,
  machineWashable,
  nonToxic,
  quickDrying,
  ruffles,
  teethingFriendly,
  woolBlend,
}
