import { graphql, useStaticQuery } from "gatsby"
import useFeaturedProducts from "./useFeaturedProducts"
import useSearchNormaliser from "./useSearchNormaliser"

export interface SearchSuggestions {
  // TODO: Add proper type annotation for products
  products: any[]
  searchTerms: string[]
  learnPages: NormalisedArticle[]
  pages: NormalisedPage[]
}

export interface SearchSettings {
  suggestions: SearchSuggestions
  carouselSettings?: GatsbyTypes.SanityCarouselSettings
}

const useSearchSettings = (): SearchSettings => {
  const { settings } = useStaticQuery<GatsbyTypes.StaticSettingSearchQuery>(graphql`
    query StaticSettingSearch {
      settings: sanitySettingSearch {
        productSuggestions {
          _type
          shopify {
            handle
          }
        }
        searchTermSuggestions
        learnPageSuggestions {
          _key
          _type
          title
          description
          image {
            asset {
              _id
              url
            }
            alt
          }
          url
          handle {
            current
          }
          category {
            _key
            title
            handle {
              current
            }
          }
          attributes {
            readDuration
            publishedAt
          }
        }
        pageSuggestions {
          _key
          title
          description
          handle {
            current
          }
        }
        carouselSettings {
          enablePagination
          enableNavigation
          enableScrollbar
          enableAutoplay
          enableLoop
          slideDuration
        }
      }
    }
  `)

  const { normaliseLearnPages, normalisePages } = useSearchNormaliser()
  const productSuggestions = useFeaturedProducts(undefined, settings?.productSuggestions as GatsbyTypes.SanityProduct[])
  const carouselSettings = settings?.carouselSettings as GatsbyTypes.SanityCarouselSettings

  const suggestions = {
    products: productSuggestions,
    searchTerms: (settings?.searchTermSuggestions || []) as string[],
    learnPages: normaliseLearnPages(settings?.learnPageSuggestions as GatsbyTypes.SanityArticle[]),
    pages: normalisePages(settings?.pageSuggestions as GatsbyTypes.SanityPageFlexible[]),
  }

  return { suggestions, carouselSettings }
}

export default useSearchSettings
