import { extendTheme } from "@chakra-ui/react"

import { breakpoints } from "./breakpoints"
import { styles } from "./styles"
import { colors } from "./colors"
import { space } from "./space"
import { borderRadius } from "./borderRadius"
import { fonts } from "./fonts"
import { sizes } from "./sizes"
import * as Components from "./components"

const overrides = {
  breakpoints,
  styles,
  colors,
  sizes,
  fonts,
  space,
  ...borderRadius,
  components: {
    ...Components,
  },
}

export default extendTheme(overrides)
