import { FormLabelProps } from "@chakra-ui/react"
import { colors } from "../colors"

type LabelSizes = "sm"

type LabelThemeProps = {
  baseStyle: FormLabelProps
  sizes: Record<LabelSizes, FormLabelProps>
}

export const FormLabel: LabelThemeProps = {
  baseStyle: {
    color: colors.text.secondary,
    fontSize: "16px",
    fontFamily: "Sofia Pro",
    lineHeight: "24px",
    textTransform: "capitalize",
    _peerDisabled: {
      color: colors.text.disabled,
    },
  },
  sizes: {
    sm: {
      color: colors.text.primary,
      fontSize: "12px",
      lineHeight: "12px",
      fontWeight: "semibold",
    },
  },
}
