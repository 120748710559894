import React, { useEffect, useState } from "react"
import { Box, Container, Grid, GridItem, HStack } from "@chakra-ui/react"

import Brand from "@components/Brand"
import CartWidget from "@components/Cart/CartWidget"
import WishlistWidget from "@components/Wishlist/WishlistWidget"
import SearchWidget from "@components/Search/Widget/SearchWidget"
import AccountWidget from "@components/Account/Widget/AccountWidget"
import Stores from "@components/Stores"
import ContactUs from "@components/ContactUs"
import NavigationHeader from "@components/Navigation/Header/NavigationHeader"
import NavigationHeaderWidget from "@components/Navigation/Header/NavigationHeaderWidget"
import NavigationHeaderSticky from "@components/Navigation/Header/NavigationHeaderSticky"

const Header = () => {
  const [menuActive, setMenuActive] = useState<string | null>(null)
  const [isScrollPastThreshold, setScrollPastThreshold] = useState<boolean>(false)

  useEffect(() => {
    addEventListener("scroll", () => {
      window.scrollY >= 100 ? setScrollPastThreshold(true) : setScrollPastThreshold(false)
    })
  }, [])

  return (
    <Container id="purebaby-header" as="header">
      <Grid alignItems="center" py={[0.5, 1.5]} gridTemplateColumns={`repeat(12,1fr)`}>
        <GridItem colSpan={4} display={{ base: "none", md: "block" }}>
          <HStack spacing={2.5}>
            <Stores />
            <ContactUs />
          </HStack>
        </GridItem>
        <GridItem colSpan={6} display={{ base: `block`, md: `none` }}>
          <HStack spacing={0}>
            <NavigationHeaderWidget />
            <Brand />
          </HStack>
        </GridItem>
        <GridItem colSpan={4} display={{ base: `none`, md: `flex` }} justifyContent={`center`}>
          <Brand desktopActive={true} />
        </GridItem>
        <GridItem colSpan={{ base: 6, md: 4 }} display={`flex`} justifyContent={`flex-end`}>
          <HStack spacing={[0, 1.5]}>
            <SearchWidget />
            <AccountWidget />
            <WishlistWidget />
            <CartWidget />
          </HStack>
        </GridItem>
      </Grid>
      <Box as="span">
        <NavigationHeader active={isScrollPastThreshold ? null : menuActive} setActive={setMenuActive} />
      </Box>
      <Box as="span" display={isScrollPastThreshold ? "block" : "none"}>
        <NavigationHeaderSticky menuActive={menuActive} setMenuActive={setMenuActive} />
      </Box>
    </Container>
  )
}

export default React.memo(Header)
