export const space = {
  0.5: "4px",
  1: "8px",
  1.5: "14px",
  2: "16px",
  2.25: "18px",
  2.5: "24px",
  3: "32px",
  3.5: "40px",
  4: "40px",
  4.25: "43px",
  4.5: "48px",
  4.75: "52px",
  5: "56px",
  5.5: "60px",
  6: "64px",
  6.5: "74px",
  7: "116px",
}
