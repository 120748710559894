import { StyleFunctionProps } from "@chakra-ui/theme-tools"
import { colors } from "../colors"

export const Button = {
  baseStyle: {
    fontWeight: "semibold",
    fontFamily: "Sofia Pro",
    textTransform: "uppercase",
    letterSpacing: "1px",
    borderRadius: "xxs",
    color: colors.text.primary,
    _focus: {
      outlineOffset: 1,
      outlineColor: colors.system.focus,
      boxShadow: "none",
    },
    _hover: {
      _disabled: {
        bg: colors.brand.divider,
      },
    },
    _disabled: {
      bg: colors.brand.divider,
      color: colors.text.disabled,
      opacity: 1,
    },
  },
  sizes: {
    xl: {
      fontSize: "24px",
    },
    lg: {
      fontSize: "18px",
      height: "55px",
      px: 7,
    },
    md: {
      fontSize: "14px",
      height: "48px",
      px: 2.5,
    },
    smd: {
      fontSize: "14px",
      height: "38px",
      px: "22px",
      fontWeight: "normal",
      textTransform: "capitalize",
    },
    sm: {
      fontSize: "14px",
      height: "32px",
      px: 2.25,
      fontWeight: "normal",
      textTransform: "capitalize",
    },
    xs: {
      fontSize: "12px",
    },
  },
  variants: {
    green: {
      bg: colors.system.success.light,
      color: colors.white,
      _hover: {
        bg: colors.system.success.dark,
      },
    },
    white: {
      bg: colors.white,
      color: colors.text.primary,
      _active: {
        bg: colors.brand.beige.light,
      },
      _hover: {
        bg: colors.neutral,
        _disabled: {
          bg: colors.text.icon,
        },
      },
      _disabled: {
        bg: colors.text.icon,
        color: colors.text.primary,
      },
    },
    blue: {
      bg: colors.brand.primary,
      color: colors.black,
      _hover: {
        bg: colors.brand.hover,
      },
    },
    navy: {
      bg: colors.brand.navy.light,
      color: colors.white,
      _hover: {
        bg: colors.brand.navy.dark,
      },
    },
    outline: ({ size }: StyleFunctionProps) => ({
      bg: "none",
      borderColor: size === "sm" ? colors.neutral : colors.text.secondary,
      _active: {
        bg: colors.neutral,
      },
      _hover: {
        bg: colors.brand.beige.dark,
      },
      _disabled: {
        border: "none",
      },
    }),
    text: ({ size }: StyleFunctionProps) => {
      const fontSizes: Record<string, string | string[]> = {
        xl: ["21px", "24px"],
        lg: "18px",
        md: "14px",
        sm: "14px",
        xs: "12px",
      }

      return {
        bg: "none",
        border: "none",
        height: "auto",
        px: 0,
        fontWeight: size === "xl" ? "light" : "normal",
        fontSize: fontSizes[size],
        letterSpacing: "0",
        textTransform: "capitalize",
        borderRadius: "xs",
        color: colors.text.secondary,
        _active: {
          color: colors.brand.navy.dark,
          textDecoration: "underline",
          outline: "none",
          boxShadow: "none",
        },
        _hover: {
          textDecoration: "underline",
          _disabled: {
            bg: "none",
            textDecoration: "none",
          },
        },
        _disabled: {
          bg: "none",
        },
      }
    },
    icon: {
      height: "48px",
      width: "48px",
      bg: colors.brand.beige.dark,
      borderRadius: "lg",
    },
  },
  defaultProps: {
    size: "md",
    variant: "green",
  },
}
