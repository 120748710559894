export { default } from "./Icon"

// Brand Icons
import logo from "@static/images/logo.svg"

// UI Icons
import contact from "@static/images/icons/ui/contact.svg"
import accountSelected from "@static/images/icons/ui/account-selected.svg"
import account from "@static/images/icons/ui/account.svg"
import arrowLeft from "@static/images/icons/ui/arrow-left.svg"
import arrowDown from "@static/images/icons/ui/arrow-down.svg"
import arrowRight from "@static/images/icons/ui/arrow-right.svg"
import calendar from "@static/images/icons/ui/calendar.svg"
import cart from "@static/images/icons/ui/cart.svg"
import greenTick from "@static/images/icons/ui/green-tick.svg"
import simpleTick from "@static/images/icons/ui/simple-tick.svg"

import chevronDown from "@static/images/icons/ui/chevron-down.svg"
import chevronLeft from "@static/images/icons/ui/chevron-left.svg"
import chevronRight from "@static/images/icons/ui/chevron-right.svg"
import chevronUp from "@static/images/icons/ui/chevron-up.svg"
import close from "@static/images/icons/ui/close.svg"
import currency from "@static/images/icons/ui/currency.svg"
import trash from "@static/images/icons/ui/delete.svg"
import delivery from "@static/images/icons/ui/delivery.svg"
import directions from "@static/images/icons/ui/directions.svg"
import edit from "@static/images/icons/ui/edit.svg"
import error from "@static/images/icons/ui/error.svg"
import externalLink from "@static/images/icons/ui/external-link.svg"
import fitAssistant from "@static/images/icons/ui/fit-assistant.svg"
import lock from "@static/images/icons/ui/lock.svg"
import menu from "@static/images/icons/ui/menu.svg"
import minus from "@static/images/icons/ui/minus.svg"
import passwordHide from "@static/images/icons/ui/password-hide.svg"
import passwordShow from "@static/images/icons/ui/password-show.svg"
import plus from "@static/images/icons/ui/plus.svg"
import rating from "@static/images/icons/ui/rating.svg"
import registry from "@static/images/icons/ui/registry.svg"
import search from "@static/images/icons/ui/search.svg"
import share from "@static/images/icons/ui/share.svg"
import sizeGuide from "@static/images/icons/ui/size-guide.svg"
import storeCollection from "@static/images/icons/ui/store-collection.svg"
import stores from "@static/images/icons/ui/stores.svg"
import tick from "@static/images/icons/ui/tick.svg"
import upload from "@static/images/icons/ui/upload.svg"
import useMyLocation from "@static/images/icons/ui/use-my-location.svg"
import wishlist from "@static/images/icons/ui/wishlist.svg"
import play from "@static/images/icons/ui/play.svg"
import quote from "@static/images/icons/ui/quote.svg"
import hotspot from "@static/images/icons/ui/hotspot.svg"
import gift from "@static/images/icons/ui/gift.svg"

// Social Icons
import pinterest from "@static/images/icons/social/pinterest.svg"
import twitter from "@static/images/icons/social/twitter.svg"
import facebook from "@static/images/icons/social/facebook.svg"
import instagram from "@static/images/icons/social/instagram.svg"

// Payment Icons
import zip from "@static/images/icons/payment/zip.svg"
import afterpay from "@static/images/icons/payment/afterpay.svg"
import klarna from "@static/images/icons/payment/klarna.svg"
import mastercard from "@static/images/icons/payment/mastercard.svg"
import visa from "@static/images/icons/payment/visa.svg"
import applePay from "@static/images/icons/payment/apple-pay.svg"
import googlePay from "@static/images/icons/payment/google-pay.svg"
import paypal from "@static/images/icons/payment/paypal.svg"
import zipBadge from "@static/images/icons/payment/zip-badge.svg"
import afterpayBadge from "@static/images/icons/payment/afterpay-badge.svg"
import klarnaBadge from "@static/images/icons/payment/klarna-badge.svg"

// Map Icons
import markerStore from "@static/images/icons/map/marker-store.svg"
import markerStockist from "@static/images/icons/map/marker-stockist.svg"
import pinStore from "@static/images/icons/map/pin-store.svg"
import pinStockist from "@static/images/icons/map/pin-stockist.svg"

export const BrandIcons = {
  logo,
}

export const UIIcons = {
  contact,
  accountSelected,
  account,
  arrowLeft,
  arrowRight,
  arrowDown,
  calendar,
  cart,
  greenTick,
  simpleTick,
  chevronDown,
  chevronLeft,
  chevronRight,
  chevronUp,
  close,
  currency,
  trash,
  delivery,
  directions,
  edit,
  error,
  externalLink,
  fitAssistant,
  lock,
  menu,
  minus,
  passwordHide,
  passwordShow,
  plus,
  rating,
  registry,
  stores,
  search,
  share,
  sizeGuide,
  storeCollection,
  tick,
  upload,
  useMyLocation,
  wishlist,
  play,
  quote,
  hotspot,
  gift,
}

export const SocialIcons = {
  facebook,
  instagram,
  twitter,
  pinterest,
}

export const PaymentIcons = {
  zip,
  afterpay,
  klarna,
  mastercard,
  visa,
  applePay,
  googlePay,
  paypal,
  zipBadge,
  afterpayBadge,
  klarnaBadge,
}

export const MapIcons = {
  markerStore,
  markerStockist,
  pinStore,
  pinStockist,
}
