import React from "react"

import { SuggestionsProps, useSearch } from "@usereactify/search"
import { Box, Heading, Stack, Text } from "@chakra-ui/react"

export type SearchSuggestionsProps = React.ComponentProps<NonNullable<SuggestionsProps["render"]>>

export const SearchSuggestions: React.FC<SearchSuggestionsProps> = props => {
  const { setSearchTerm } = useSearch()

  return (
    <Stack spacing={2}>
      <Heading as="h3" size="h3" fontWeight={["semibold", "light"]}>
        {`Categories`}
      </Heading>
      {props.suggestions.map((suggestion, index) => (
        <Box onClick={() => setSearchTerm(suggestion.text)} key={index} cursor="pointer">
          <Text size="lg">{suggestion.text}</Text>
        </Box>
      ))}
    </Stack>
  )
}
