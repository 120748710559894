import React, { memo, useEffect, useState } from "react"
import { Box } from "@chakra-ui/react"

const ProductReviewsBadge: React.FC<any> = ({ product }) => {
  const [id, setId] = useState<string>("")
  useEffect(() => {
    // Decode the Base64-encoded cursor, split the decoded string by "/", and retrieve the last part (ID)
    // Used to test if the product id is a given code or GID. PDP gives code, Home page gives GID
    const pattern = /^gid:\/\/shopify\/Product\/\d+$/

    if (pattern.test(product?.id)) {
      setId(product?.id?.split("/").pop())
    } else {
      try {
        setId(
          atob(product?.id)
            .split("/")
            .pop()
        )
      } catch (error) {
        setId(product?.id)
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return id ? (
    <>
      <Box>
        <Box
          height="18px"
          id={`yotpo-star__${id}`}
          className={`yotpo-widget-instance`}
          display="flex"
          alignItems="flex-end"
          data-yotpo-instance-id="432064"
          data-yotpo-product-id={id}
        />
      </Box>
    </>
  ) : null
}

export default memo(ProductReviewsBadge)
