import React, { Suspense, useMemo, useRef } from "react"
import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from "body-scroll-lock"

import { IconButton, useDisclosure } from "@chakra-ui/react"
import SearchOverlay from "../Overlay"

export const SearchWidget = () => {
  const drawerRef = useRef<HTMLDivElement>(null)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const setDrawerOpen = (isOpen: boolean) => {
    if (isOpen) {
      disableBodyScroll(drawerRef?.current)
    } else {
      enableBodyScroll(drawerRef?.current)
    }
    onOpen()
  }

  const onDrawerClose = () => {
    clearAllBodyScrollLocks()
    onClose()
  }

  const icon = useMemo(
    () => (
      <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <title id={`Search`}></title>
        <path
          d="M8.13468 1.25391C4.33552 1.25308 1.25411 4.33067 1.25 8.13004C1.25341 10.911 2.93004 13.4166 5.49932 14.4803C8.06861 15.5441 11.0256 14.9569 12.9936 12.9922C14.2815 11.7023 15.0034 9.95293 15 8.13004C15.0028 6.30734 14.2807 4.55834 12.9929 3.26851C11.7051 1.97868 9.95728 1.2539 8.13468 1.25391"
          stroke="currentColor"
        />
        <path
          d="M13.4782 12.775C13.2829 12.5797 12.9663 12.5797 12.7711 12.775C12.5758 12.9702 12.5758 13.2868 12.7711 13.4821L13.4782 12.775ZM18.9248 19.6358C19.1201 19.8311 19.4367 19.8311 19.6319 19.6358C19.8272 19.4406 19.8272 19.124 19.6319 18.9287L18.9248 19.6358ZM12.7711 13.4821L18.9248 19.6358L19.6319 18.9287L13.4782 12.775L12.7711 13.4821Z"
          fill="currentColor"
        />
      </svg>
    ),
    []
  )

  return (
    <>
      <IconButton
        variant="ghost"
        onClick={setDrawerOpen}
        icon={icon}
        aria-label="Open search"
        _focus={{ outlineColor: "none", backgroundColor: "none" }}
        _active={{ outlineColor: "none", backgroundColor: "none" }}
        _hover={{ outlineColor: "none", backgroundColor: "none" }}
      />
      <Suspense fallback={<></>}>
        <SearchOverlay ref={drawerRef} isOpen={isOpen} onClose={onDrawerClose} />
      </Suspense>
    </>
  )
}

export default SearchWidget
