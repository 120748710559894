import { gql } from "@apollo/client"

const GET_CUSTOMERMETAFIELDS = gql`
  query GetCustomer($customerAccessToken: String!) {
    customer(customerAccessToken: $customerAccessToken) {
      loyalty: metafield(namespace: "cheetah", key: "loyalty") {
        key
        value
      }
    }
  }
`
export default GET_CUSTOMERMETAFIELDS
