import { colors } from "../colors"

export const Badge = {
  baseStyle: {
    borderRadius: "md",
    textTransform: "none",
    color: colors.text.primary,
    fontWeight: "normal",
  },
  variants: {
    primary: {
      bg: colors.brand.primary,
    },
    secondary: {
      bg: colors.text.secondary,
      color: colors.white,
    },
    neutral: {
      bg: colors.neutral,
      color: colors.text.secondary,
    },
    turquoise: {
      bg: colors.brand.turquoise,
    },
    navy: {
      bg: colors.brand.navy.light,
      color: colors.white,
    },
    success: {
      bg: colors.system.success.light,
      color: colors.white,
    },
    error: {
      bg: colors.system.error.dark,
      color: colors.white,
    },
    warning: {
      bg: colors.system.warning,
    },
    text: {
      bg: "none",
      color: colors.text.secondary,
    },
    sale: {
      bg: colors.brand.sale,
      color: colors.white,
    },
    pink: {
      bg: colors.brand.pink,
    },
  },
  sizes: {
    lg: {
      fontSize: "14px",
      px: 1.5,
      py: "4px",
    },
    md: {
      fontSize: "12px",
      px: "11.75px",
      py: "4px",
    },
    sm: {
      borderRadius: "xxl",
      fontSize: "14px",
      px: "10px",
      py: "2.5px",
    },
  },
  defaultProps: {
    variant: "primary",
    size: "md",
  },
}
