import React, { useState, useEffect } from "react"
import { Box, Text } from "@chakra-ui/react"
import { useAnnouncement } from "@hooks/useAnnouncement"
import Link from "@components/Link"
import Icon from "@components/Icon/Icon"
import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay } from "swiper"
import "swiper/css/autoplay"

const Announcement = () => {
  const { items, show, hide, parseTitle, duration } = useAnnouncement()
  const [itemsData, setItemsData] = useState([...items])

  function buildTimeString(endDate) {
    const now = new Date().getTime()
    const endTime = new Date(endDate).getTime()
    const timeDifference = endTime - now

    if (endDate == null) {
      return null // no timer set
    }

    if (timeDifference <= 0) {
      return false // timer expired
    }

    const totalSeconds = Math.floor(timeDifference / 1000)
    const totalMinutes = Math.floor(totalSeconds / 60)
    const totalHours = Math.floor(totalMinutes / 60)

    const seconds = totalSeconds % 60
    const minutes = totalMinutes % 60
    const hours = totalHours

    return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setItemsData(prevItems =>
        prevItems.map(item => {
          if (item?.countdownEndDate) {
            item.timer = buildTimeString(item.countdownEndDate)
          }
          return item
        })
      )
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return show && items ? (
    <Box as="section" bg="brand.navy.light" px={1.5} py="5px" position={`relative`} overflow={`hidden`}>
      <Swiper
        modules={[Autoplay]}
        autoplay={{
          delay: duration,
          disableOnInteraction: false,
        }}
        autoHeight={true}
        navigation={false}
        loop={true}
        slidesPerView={1}
      >
        {items?.map(({ countdownEndDate, link, title, fallbackTitle, showCountdown }, index: number) => {
          const timer = buildTimeString(countdownEndDate)
          return link?.link ? (
            <SwiperSlide key={index}>
              <Text
                as={Link}
                size="sm"
                to={link?.link}
                textDecoration="none"
                color="white"
                display="flex"
                justifyContent="center"
                gap={0.5}
              >
                {(timer == null && title) || (timer && title) ? (
                  <>
                    {parseTitle(title)}
                    <Text fontSize="14px" fontWeight="400" color="white" lineHeight="21px">
                      {timer && showCountdown == true ? ` ends in ${timer}` : null}
                    </Text>
                  </>
                ) : null}
                {!timer && timer != null && fallbackTitle ? <>{parseTitle(fallbackTitle)}</> : null}
              </Text>
            </SwiperSlide>
          ) : (
            <SwiperSlide key={index}>
              <Text size="sm" color="white" textAlign="center" display="flex" justifyContent={"center"}>
                {(timer == null && title) || (timer && title) ? (
                  <>
                    {parseTitle(title)}
                    <Text fontSize="14px" fontWeight="400" color="white" lineHeight="21px">
                      {timer && showCountdown == true ? ` ends in ${timer}` : null}
                    </Text>
                  </>
                ) : null}
                {!timer && timer != null && fallbackTitle ? <>{parseTitle(fallbackTitle)}</> : null}
              </Text>
            </SwiperSlide>
          )
        })}
      </Swiper>
      <Icon
        name="close"
        cursor="pointer"
        onClick={hide}
        size="14px"
        color="white"
        style={{ position: `absolute`, top: `50%`, right: `var(--chakra-space-1-5)`, transform: `translateY(-50%)`, zIndex: `2` }}
      />
    </Box>
  ) : null
}

export default React.memo(Announcement)
