import React from "react"
import { Search, Sensors, useSearch } from "@usereactify/search"
import { Box, Button, InputGroup, InputLeftElement, InputRightElement } from "@chakra-ui/react"

import Icon from "@components/Icon/Icon"

// @ts-ignore
import * as styles from "./SearchForm.module.css"

const SearchForm = () => {
  const { setSearchTerm } = useSearch()

  return (
    <>
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <Icon name="search" size="20px" />
        </InputLeftElement>
        <Box className={styles.input} w="full">
          <Search />
        </Box>
        <InputRightElement mr={1}>
          <Button variant="text" onClick={() => setSearchTerm("")}>
            Clear
          </Button>
        </InputRightElement>
      </InputGroup>
      <Sensors />
    </>
  )
}

export default React.memo(SearchForm)
