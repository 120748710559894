import React from "react"
import { Button } from "@chakra-ui/react"
import config from "../../config.js"
import Link from "@components/Link"
import Icon from "@components/Icon/Icon"

const Stores = () => (
  <Button
    as={Link}
    to={config.settings.routes.STORE}
    variant="text"
    width="fit-content"
    fontSize={["18px", "14px"]}
    leftIcon={<Icon name="stores" size="22px" color="inherit" />}
  >
    Stores
  </Button>
)

export default React.memo(Stores)
