import { gql } from "@apollo/client"

export const modifyCheckoutFragment = GQL => {
  const discountApplication = gql`
    query {
      discountApplication {
        targetSelection
        targetType
      }
    }
  `

  const checkoutFragment = GQL?.definitions?.find(definition => definition.name?.value === "CheckoutFragment")
  if (checkoutFragment) {
    const discountAllocationsSelections =
      checkoutFragment?.selectionSet?.selections?.[24]?.selectionSet?.selections?.[1]?.selectionSet?.selections?.[1]?.selectionSet
        ?.selections?.[5]?.selectionSet?.selections

    if (discountApplication?.definitions?.[0]?.selectionSet?.selections?.[0]) {
      discountAllocationsSelections?.push(discountApplication?.definitions?.[0]?.selectionSet?.selections?.[0])
    }
  }
}
