import React, { useState, useEffect, PropsWithChildren } from "react"
import TagManager, { DataLayerArgs, TagManagerArgs } from "react-gtm-module"
import { useCore } from "@hooks/useCore"
import { useConfigContext } from "./config"

export type ContextProps = {
  tracked: boolean
  setTracked: React.Dispatch<React.SetStateAction<boolean>>
  latestVersion: boolean
  trackedLists: any
  setTrackedLists: React.Dispatch<React.SetStateAction<any>>
  colourOptionName: string
  gtm: {
    dataLayer: (dataLayerArgs: DataLayerArgs) => void
    initialize: (tagManagerArgs: TagManagerArgs) => void
  }
}

export const TrackingContext = React.createContext<ContextProps | undefined>(undefined)

export const TrackingProvider = ({ children }: PropsWithChildren) => {
  const {
    store: { gtm },
    settings: {
      product: { colourOptionName },
    },
  } = useConfigContext()
  const {
    helpers: { isDomReady },
  } = useCore()
  const [trackedLists, setTrackedLists] = useState<any>({})
  const [tracked, setTracked] = useState(false)

  const contextValue = React.useMemo<ContextProps>(
    () => ({
      tracked,
      setTracked,
      trackedLists,
      setTrackedLists,
      latestVersion: true, //"GA4"
      colourOptionName, //"Colour"
      gtm: TagManager,
    }),
    [tracked, trackedLists, colourOptionName]
  )

  useEffect(() => {
    if (gtm) {
      TagManager.initialize({
        gtmId: gtm,
      })
      setTracked(true)
    }
  }, [gtm])

  return isDomReady ? <TrackingContext.Provider value={contextValue}>{children}</TrackingContext.Provider> : <>{children}</>
}

export const useTrackingContext = (): ContextProps => ({ ...React.useContext(TrackingContext) }) as ContextProps
