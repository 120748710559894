import React, { useCallback, useEffect, useState } from "react"
import { useCore } from "@app/hooks/useCore"
import { useConfigContext } from "@app/providers/config"
import { useShopify } from "@app/hooks/useShopify"

export type ContextProps = {
  recentlyViewed: Array<any>
  addRecentlyViewed: (handle: string) => void
}

export const RecentlyViewedContext = React.createContext<ContextProps | undefined>(undefined)

export const RecentlyViewedProvider: React.FC = ({ children }) => {
  const {
    helpers: { storage },
  } = useCore()
  const {
    settings: { keys },
  } = useConfigContext()
  const { getProductsLight } = useShopify()
  const [recentlyViewedHandles, setRecentlyViewedHandles] = useState<any>(null)
  const [rawRecentlyViewed, setRawRecentlyViewed] = useState<any>(null)
  const [recentlyViewed, setRecentlyViewed] = useState<any>(null)

  const getRecentlyViewed = useCallback(() => {
    try {
      return storage.get(keys.recentlyViewed) || []
    } catch (e) {
      return []
    }
  }, [keys.recentlyViewed, storage])

  const addRecentlyViewed = useCallback(
    (product: any) => {
      let data = getRecentlyViewed()
      data = data.filter(
        (productCheck: any) => productCheck && !(typeof productCheck === "string") && productCheck?.handle !== product?.handle
      )
      data.unshift(product)

      storage.set(keys.recentlyViewed, data.slice(0, 6))
      setRawRecentlyViewed(data)
    },
    [getRecentlyViewed, keys.recentlyViewed, storage]
  )

  const fetchProducts = useCallback(async () => {
    if (!rawRecentlyViewed?.length) {
      return
    }

    const handles = rawRecentlyViewed?.map((item: any) => item?.handle || item?.shopify.handle)

    const items: any = (await getProductsLight({ firstImages: 2, firstVariants: 1, handles }))?.filter((item: any) => item?.handle)
    setRecentlyViewed(items)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recentlyViewedHandles, rawRecentlyViewed])

  useEffect(() => {
    setRecentlyViewedHandles(getRecentlyViewed())
  }, [getRecentlyViewed])

  useEffect(() => {
    fetchProducts()
  }, [fetchProducts])

  const contextValue = React.useMemo<ContextProps>(
    () => ({
      recentlyViewed,
      addRecentlyViewed,
    }),
    [recentlyViewed, addRecentlyViewed]
  )

  return <RecentlyViewedContext.Provider value={contextValue}>{children}</RecentlyViewedContext.Provider>
}

export const useRecentlyViewedContext = (): ContextProps => ({ ...React.useContext(RecentlyViewedContext) }) as ContextProps
