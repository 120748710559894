import React, { RefObject, useEffect } from "react"
import { AlertDialog, AlertDialogOverlay } from "@chakra-ui/react"
import { ReactifySearchProvider } from "@usereactify/search"

import { useCore } from "@hooks/useCore"
import { useConfigContext } from "@providers/config"
import SanitySearchProvider from "@providers/sanitySearch"
import SearchOverlayMobile from "./SearchOverlayMobile"
import SearchOverlayDesktop from "./SearchOverlayDesktop"
import { useMedia } from "@hooks/useMedia"
export interface SearchOverlayProps {
  onClose: VoidFunction
  isOpen: boolean
}

export interface SearchOverlayComponentProps {
  cancelRef: RefObject<HTMLButtonElement>
  onClose: VoidFunction
}

const SearchOverlay = React.forwardRef<HTMLDivElement, SearchOverlayProps>(({ onClose, isOpen }: SearchOverlayProps) => {
  const {
    helpers: { ErrorBoundary },
  } = useCore()
  // const { shop } = useShop() // TODO: Fix shop config on Sanity
  const cancelRef = React.useRef<HTMLButtonElement>(null)

  const { isBase } = useMedia()

  const {
    store: { shopifyShopDomain },
    settings: { search },
  } = useConfigContext()

  const searchRef = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (typeof window !== "undefined")
      setTimeout(() => {
        const input = searchRef?.current?.querySelector("input")
        if (input) {
          input?.focus()
        }
      }, 500)
  }, [isOpen])

  const props: SearchOverlayComponentProps = { cancelRef, onClose }

  return (
    <AlertDialog isOpen={isOpen} isCentered={false} leastDestructiveRef={cancelRef} onClose={onClose}>
      <AlertDialogOverlay />
      {/* @ts-ignore */}
      <ReactifySearchProvider
        mode="instant-search"
        shopifyPermanentDomain={shopifyShopDomain ?? ""}
        includeFields={search.searchIncludeFields}
        excludeFields={search.searchExcludeFields}
      >
        <SanitySearchProvider>
          <React.Suspense fallback={<div />}>
            <ErrorBoundary>
              {isBase ? <SearchOverlayMobile ref={searchRef} {...props} /> : <SearchOverlayDesktop ref={searchRef} {...props} />}
            </ErrorBoundary>
          </React.Suspense>
        </SanitySearchProvider>
      </ReactifySearchProvider>
    </AlertDialog>
  )
})

export default SearchOverlay
