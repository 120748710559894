import { colors } from "../colors"

export const Input = {
  baseStyle: {
    field: {
      fontFamily: "Sofia Pro",
      boxShadow: "none",
      borderRadius: "xxs",
      color: colors.text.primary,
      bg: colors.white,
      fontSize: "md",
      h: "48px",
      _invalid: {
        bg: colors.system.error.light,
        borderColor: colors.brand.sale,
        boxShadow: "none",
      },
      _disabled: {
        color: colors.brand.border,
        opacity: 1,
        _placeholder: {
          color: colors.brand.border,
        },
      },
      _placeholder: {
        color: colors.text.secondary,
      },
      _focus: {
        boxShadow: "none",
        outline: "none",
      },
    },
  },
  variants: {
    outline: {
      field: {
        bg: colors.white,
        borderColor: colors.text.secondary,
      },
    },
    filled: {
      field: {
        bg: colors.white,
        borderColor: colors.white,
        _focus: {
          bg: colors.white,
        },
      },
    },
  },
  defaultProps: {
    size: "base",
    variant: "outline",
  },
}
