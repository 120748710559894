import { IconButton } from "@chakra-ui/react"
import React from "react"
import Icon from "../Icon"

export interface ImageHotspotProps {
  title?: string
  handle?: string
  posX?: number
  posY?: number
  onClick?: VoidFunction
  isLoading?: string
}

const ImageHotspot = ({ title, handle, posX = 0, posY = 0, isLoading, onClick }: ImageHotspotProps) => (
  <IconButton
    pointerEvents={`all`}
    cursor="pointer"
    zIndex={3}
    position="absolute !important"
    borderRadius="full"
    bg="rgba(0, 0, 0, 0.2)"
    w="50px"
    h="50px"
    top={`${posY}%`}
    left={`${posX}%`}
    transform={`translate(-${posX}%, -${posY}%)`}
    onClick={onClick}
    display={`flex`}
    alignItems={`center`}
    justifyContent={`center`}
    icon={<Icon name="hotspot" title={title} size="24px" />}
    isLoading={isLoading == handle}
  />
)

export default ImageHotspot
