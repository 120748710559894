import React from "react"
import { Flex, Text } from "@chakra-ui/react"

const CartCount: React.FC<{ count: number }> = ({ count }) => {
  return (
    <Flex
      pointerEvents={`none`}
      backgroundColor={"#E84F4F"}
      h={"15px"}
      w={"15px"}
      borderRadius={"100%"}
      pos={"absolute"}
      top={1}
      right={0.5}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Text as="span" fontSize={"9px"} color={"white"} lineHeight={`13.5px`}>
        {count}
      </Text>
    </Flex>
  )
}

export default React.memo(CartCount)
