import React from "react"
import Link from "@components/Link"

type BrandProps = {
  desktopActive?: boolean
}

const Brand: React.FC<BrandProps> = ({ desktopActive = false }) => (
  <Link
    to="/"
    _focus={{
      outline: "none !important",
    }}
  >
    <svg
      height={!desktopActive ? "31px" : "43px"}
      width={!desktopActive ? "129px" : "180px"}
      viewBox="0 0 392 91"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47 44.8778C47 32.2463 36.4895 22.0254 23.5 22.0254C10.5105 22.0254 0 32.2463 0 44.8778C0 50.7597 2.28059 56.1594 6.04852 60.2092V87.015C6.04852 88.6542 7.43671 90.0041 9.12236 90.0041C10.7089 90.0041 12.097 88.6542 12.097 87.015V64.8375C15.4684 66.6696 19.3354 67.7302 23.5 67.7302C36.4895 67.7302 47 57.5093 47 44.8778ZM23.5 61.8484C13.8819 61.8484 6.04852 54.2309 6.04852 44.8778C6.04852 35.5247 13.8819 27.9072 23.5 27.9072C33.1181 27.9072 40.9515 35.5247 40.9515 44.8778C40.9515 54.2309 33.1181 61.8484 23.5 61.8484Z"
        fill="#938E88"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M235 45.1302C235 32.4987 224.489 22.2778 211.5 22.2778C207.335 22.2778 203.468 23.3384 200.196 25.0741V2.89662C200.196 1.35384 198.808 0.00390625 197.122 0.00390625C195.437 0.00390625 194.049 1.25741 194.049 2.89662V29.7988C190.281 33.8486 188 39.2484 188 45.1302C188 57.7617 198.511 67.9826 211.5 67.9826C224.489 67.9826 235 57.7617 235 45.1302ZM211.5 62.1008C201.882 62.1008 194.049 54.4833 194.049 45.1302C194.049 35.7771 201.882 28.1596 211.5 28.1596C221.118 28.1596 228.951 35.7771 228.951 45.1302C228.951 54.4833 221.118 62.1008 211.5 62.1008Z"
        fill="#938E88"
      />
      <path
        d="M98 28.7084C98 27.0917 96.6031 25.8555 95.0067 25.8555C93.3104 25.8555 91.9135 27.1868 91.9135 28.7084V46.5865C91.9135 55.1451 84.5299 62.1823 75.5499 62.1823C66.4701 62.1823 59.1863 55.1451 59.1863 46.5865V28.7084C59.1863 27.0917 57.7894 25.8555 56.0931 25.8555C54.3969 25.8555 53 27.1868 53 28.7084V46.5865C53 58.3784 63.0776 67.9831 75.4501 67.9831C87.8226 67.9831 97.9002 58.3784 97.9002 46.5865L98 28.7084Z"
        fill="#938E88"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M341 45.1302C341 32.4987 330.489 22.2778 317.5 22.2778C313.335 22.2778 309.468 23.3384 306.097 25.0741V2.89662C306.097 1.35384 304.709 0.00390625 303.023 0.00390625C301.437 0.00390625 300.049 1.25741 300.049 2.89662V29.7988C296.281 33.8486 294 39.2484 294 45.1302C294 57.7617 304.511 67.9826 317.5 67.9826C330.489 67.9826 341 57.7617 341 45.1302ZM317.5 62.1008C307.882 62.1008 300.049 54.4833 300.049 45.1302C300.049 35.7771 307.882 28.1596 317.5 28.1596C327.118 28.1596 334.951 35.7771 334.951 45.1302C334.951 54.4833 327.118 62.1008 317.5 62.1008Z"
        fill="#938E88"
      />
      <path
        d="M392 46.5732V28.7065C392 27.0909 390.603 25.8555 389.007 25.8555C387.31 25.8555 385.914 27.186 385.914 28.7065V46.5732C385.914 55.1264 378.53 62.159 369.55 62.159C360.47 62.159 353.186 55.1264 353.186 46.5732V28.7065C353.186 27.0909 351.789 25.8555 350.093 25.8555C348.397 25.8555 347 27.186 347 28.7065V46.5732C347 57.4072 355.481 66.3406 366.457 67.7661V87.0583C366.457 88.6739 367.854 90.0044 369.55 90.0044C371.246 90.0044 372.643 88.6739 372.643 87.0583V67.7661C383.519 66.3406 392 57.4072 392 46.5732Z"
        fill="#938E88"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M264.5 22.9824C277.489 22.9824 288 33.0457 288 45.4824V45.6723V65.1343C288 66.6533 286.711 67.9824 285.025 67.9824C283.34 67.9824 281.951 66.7482 281.951 65.1343V60.5774C277.688 65.1343 271.441 67.9824 264.5 67.9824C251.511 67.9824 241 57.9191 241 45.4824C241 33.0457 251.511 22.9824 264.5 22.9824ZM247.049 45.4824C247.049 54.6913 254.882 62.1913 264.5 62.1913C274.118 62.1913 281.951 54.6913 281.951 45.4824C281.951 36.2736 274.118 28.7736 264.5 28.7736C254.882 28.7736 247.049 36.2736 247.049 45.4824Z"
        fill="#938E88"
      />
      <path
        d="M181.136 49.3748C179.529 48.8052 177.821 49.5647 177.118 51.0837L176.415 52.6976C173.603 58.2989 167.476 62.1913 160.446 62.1913C150.703 62.1913 142.769 54.6913 142.769 45.4824C142.769 36.2736 150.703 28.7736 160.446 28.7736C166.974 28.7736 172.699 32.1913 175.812 37.2229L153.917 46.8115C152.411 47.4761 151.708 49.185 152.411 50.7039C153.114 52.128 154.922 52.7925 156.428 52.128L181.136 41.3052C182.642 40.6406 183.345 38.9318 182.642 37.5077C179.227 29.0584 170.59 22.9824 160.446 22.9824C153.716 22.9824 147.59 25.6406 143.271 29.9128C139.153 25.6406 133.227 22.9824 126.598 22.9824C114.144 22.9824 104 32.571 104 44.3432V65.0394C104 66.6533 105.406 67.9824 107.013 67.9824C108.721 67.9824 110.026 66.6533 110.026 65.0394V44.3432C110.026 35.7039 117.459 28.7736 126.498 28.7736C131.721 28.7736 136.441 31.147 139.454 34.7546C137.646 37.9824 136.542 41.59 136.542 45.4824C136.542 57.9191 147.188 67.9824 160.345 67.9824C170.389 67.9824 178.926 62.0963 182.441 53.8369C182.542 53.647 182.743 53.0774 182.743 53.0774C183.446 51.4634 182.642 49.9444 181.136 49.3748Z"
        fill="#938E88"
      />
    </svg>
  </Link>
)

export default React.memo(Brand)
