import React from "react"
import { Box } from "@chakra-ui/react"

const Main = ({ children, ...props }: { children: any }) => {
  return (
    <Box as="main" {...props}>
      {children}
    </Box>
  )
}

export default React.memo(Main)
