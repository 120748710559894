/* eslint-disable */
import { useCallback, useEffect, useMemo, useState } from "react"
import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
// import { useGoogleLogout } from "react-google-login"
import { navigate } from "gatsby"
import { useCore, useStorage } from "@hooks/useCore"
import { useKlaviyo } from "@hooks/useKlaviyo"
import { useFunctions } from "@hooks/useFunctions"
import { useConfigContext } from "@providers/config"
import { useWishlistContext } from "@providers/wishlist"
import { useCheckoutContext } from "@providers/checkout"
import { useCustomerContext } from "@providers/customer"
import { useShopify } from "@hooks/useShopify"
import { useValidation } from "./useValidation"
import { useAnalytics } from "@hooks/useAnalytics"

export const useCustomerAccessToken = () => {
  const {
    graphql: {
      mutations: { CUSTOMER_ACCESS_TOKEN_CREATE, CUSTOMER_ACCESS_TOKEN_CREATE_MULTIPASS },
    },
  } = useCore()
  const { getCustomer, saveCustomer } = useCustomerContext()

  const [customerAccessTokenCreate] = useMutation(CUSTOMER_ACCESS_TOKEN_CREATE)
  const [customerAccessTokenCreateWithMultipass] = useMutation(CUSTOMER_ACCESS_TOKEN_CREATE_MULTIPASS)

  const createAccessToken = useCallback(
    async (email, password) => {
      if (email?.length && password?.length) {
        try {
          const {
            data: {
              customerAccessTokenCreate: { customerAccessToken, customerUserErrors },
            },
          } = await customerAccessTokenCreate({
            variables: { input: { email, password } },
          })

          if (customerAccessToken && !customerUserErrors?.length) saveCustomer(customerAccessToken)

          return { customerAccessToken, customerUserErrors }
        } catch (err) {
          console.error(err)
          return null
        }
      }
    },
    [customerAccessTokenCreate, saveCustomer]
  )

  const createAccessTokenWithMultipass = useCallback(
    async multipassToken => {
      try {
        const {
          data: {
            customerAccessTokenCreateWithMultipass: { customerAccessToken, customerUserErrors },
          },
        } = await customerAccessTokenCreateWithMultipass({
          variables: { multipassToken },
        })

        if (customerAccessToken && !customerUserErrors?.length) saveCustomer(customerAccessToken)

        return { customerAccessToken, customerUserErrors }
      } catch (err) {
        console.error(err)
        return null
      }
    },
    [customerAccessTokenCreateWithMultipass, saveCustomer]
  )

  return { createAccessToken, createAccessTokenWithMultipass, getCustomer }
}

export const useCustomerRegister = () => {
  const {
    graphql: {
      mutations: { CUSTOMER_CREATE },
      queries: { GET_CUSTOMER },
    },
    helpers: { decodeBase64, isBrowser, getUrlParameter },
  } = useCore()
  const { trackSignup } = useAnalytics()
  const {
    settings: { params, routes },
  } = useConfigContext()
  const { setCustomer } = useCustomerContext()
  const { createAccessToken } = useCustomerAccessToken()
  const { callFunction } = useFunctions()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<Array<string>>([])
  const [data, setData] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    acceptsMarketing: true,
    phone: "",
  })

  const [customerCreate] = useMutation(CUSTOMER_CREATE)
  const { refetch: getCustomerQuery } = useQuery(GET_CUSTOMER, {
    fetchPolicy: "no-cache",
    skip: true,
  })

  const handleSubmit = async (event: React.SyntheticEvent | null = null) => {
    event?.preventDefault()
    const filteredData = Object.entries(data).reduce((a, [k, v]) => (v ? ((a[k] = v), a) : a), {})
    await createCustomer(
      {
        ...filteredData,
      },
      []
    )
  }

  const handleChange = ({ target: { type, name, value, checked } }: React.ChangeEvent<HTMLInputElement>) => {
    setData(prevState => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }))
  }

  const createCustomer = useCallback(
    async ({ ...userData }, tags) => {
      setLoading(true)
      setErrors([])
      try {
        const {
          data: {
            customerCreate: { customerUserErrors: errors },
          },
        } = await customerCreate({
          variables: { input: { ...userData } },
        })

        if (errors?.length) {
          setErrors(errors)
          setLoading(false)
        } else {
          const data = await createAccessToken(userData?.email, userData?.password)
          const path = getUrlParameter(params.continue)
          const route = path ? path : routes.DASHBOARD
          //@ts-ignore
          const { customerUserErrors, customerAccessToken } = data
          if (!customerUserErrors?.length) {
            if (tags.length > 0 && customerAccessToken?.accessToken) {
              try {
                const {
                  data: { customer },
                } = await getCustomerQuery({
                  customerAccessToken: customerAccessToken?.accessToken,
                  countryCode: "AU",
                })

                const response = await callFunction("customer-tags", { id: decodeBase64(customer?.id), tags })
                if ("error" === response.status) throw new Error("Error updating customer tags")
                if (!response.body.userErrors?.length) setCustomer((prevState: any) => ({ ...prevState, tags }))
                trackSignup('email')
                isBrowser ? navigate(route, { replace: true }) : null
              } catch (err) {
                console.error(err)
              }
            } else {
              trackSignup('email')
              isBrowser ? navigate(route, { replace: true }) : null
            }
          } else {
            setErrors(customerUserErrors)
            setLoading(false)
          }
        }
      } catch (err) {
        console.error(err)
        setErrors([err.message])
        setLoading(false)
      }
    },
    [
      setLoading,
      setErrors,
      customerCreate,
      createAccessToken,
      callFunction,
      setCustomer,
      decodeBase64,
      getCustomerQuery,
      getUrlParameter,
      isBrowser,
      params,
      routes,
      trackSignup,
    ]
  )

  return { createCustomer, handleSubmit, handleChange, data, setData, loading, errors }
}

export const useCustomerLogin = () => {
  const {
    helpers: { getUrlParameter },
  } = useCore()
  const {
    settings: { params, routes },
  } = useConfigContext()
  const { createAccessToken } = useCustomerAccessToken()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<Array<string>>([])
  const [data, setData] = useState({ email: "", password: "" })

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault()
    await loginCustomer({
      ...data,
    })
  }

  const handleChange = ({ target: { type, name, value, checked } }: React.ChangeEvent<HTMLInputElement>) => {
    setData(prevState => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }))
  }

  const loginCustomer = useCallback(
    async ({ ...userData }) => {
      setLoading(true)
      setErrors([])

      const path = getUrlParameter(params.continue)
      const isCheckout = getUrlParameter(params.checkout)
      const route = isCheckout ? routes.CHECKOUT : path ? path : routes.DASHBOARD

      try {
        //@ts-ignore
        const { customerUserErrors } = await createAccessToken(userData?.email, userData?.password)
        if (!customerUserErrors?.length) {
          navigate(route, { replace: true })
        } else {
          setErrors(customerUserErrors)
          setData(prevState => ({ ...prevState, password: "" }))
          setLoading(false)
        }
      } catch (err) {
        console.error(err)
        setErrors([(err as Error).message])
        setData(prevState => ({ ...prevState, password: "" }))
        setLoading(false)
      }
    },
    [setLoading, setErrors, createAccessToken, getUrlParameter, params, routes]
  )

  return { loginCustomer, setData, data, handleSubmit, handleChange, loading, errors }
}

export const useCustomerSocialLogin = () => {
  const {
    helpers: { getUrlParameter },
  } = useCore()
  const {
    settings: { params, routes },
  } = useConfigContext()
  const { callFunction } = useFunctions()
  const { createAccessTokenWithMultipass } = useCustomerAccessToken()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<Array<string>>([])

  const loginCustomer = useCallback(
    async ({ email, method }: { email: string; method: "google" | "facebook" }) => {
      setLoading(true)
      setErrors([])

      const path = getUrlParameter(params.continue)
      const isCheckout = getUrlParameter(params.checkout)
      const route = isCheckout ? routes.CHECKOUT : path ? path : routes.DASHBOARD

      try {
        const response = await callFunction("customer-multipass", { email, method })
        if ("error" === response.status) throw new Error("Multipass token failed")

        //@ts-ignore
        const { customerUserErrors } = await createAccessTokenWithMultipass(response.body)

        if (!customerUserErrors?.length) {
          navigate(route, { replace: true })
        } else {
          setErrors(customerUserErrors)
          setLoading(false)
        }
      } catch (err) {
        console.error(err)
        navigate(routes.LOGIN, { replace: true })
        setErrors(["Unable to login to Shopify, please try again"])
        setLoading(false)
      }
    },
    [callFunction, createAccessTokenWithMultipass, getUrlParameter, params, routes]
  )

  const handleLoginGoogle = useCallback(
    async ({ profileObj: { email } }) => {
      setErrors([])
      if (email) {
        await loginCustomer({ email, method: "google" })
      } else {
        setErrors(["Unable to login with Google, please try again"])
      }
    },
    [setErrors, loginCustomer]
  )

  const handleLoginFacebook = useCallback(
    async ({ email }) => {
      setErrors([])
      if (email) {
        await loginCustomer({ email, method: "facebook" })
      } else {
        setErrors(["Unable to login with Facebook, please try again"])
      }
    },
    [setErrors, loginCustomer]
  )

  return {
    handleLoginGoogle,
    handleLoginFacebook,
    loading,
    errors,
  }
}

export const useCustomerLogout = () => {
  const {
    helpers: { isBrowser },
    graphql: {
      mutations: { CHECKOUT_CUSTOMER_DISASSOCIATE },
    },
  } = useCore()
  const {
    settings: { routes },
    // store: { googleAuthClientId },
  } = useConfigContext()

  const { track } = useKlaviyo()

  const { clearCustomer } = useCustomerContext()
  const { resetWishlist } = useWishlistContext()
  const { id: checkoutId, saveCheckout, countryCode } = useCheckoutContext()

  const [checkoutCustomerDisassociate] = useMutation(CHECKOUT_CUSTOMER_DISASSOCIATE)

  const logoutCustomer = useCallback(async () => {
    const {
      data: { checkoutCustomerDisassociateV2: data },
    } = await checkoutCustomerDisassociate({
      variables: { checkoutId, countryCode },
    })
    saveCheckout(data?.checkout)
    track("Account Logout", false)
    clearCustomer()

    if (isBrowser) {
      // await signOutGoogle()
      navigate(routes.HOMEPAGE)
    }
  }, [checkoutCustomerDisassociate, saveCheckout, clearCustomer, checkoutId, isBrowser, resetWishlist, track, routes]) //signOutGoogle

  return { logoutCustomer }
}

export const useCustomerRecover = () => {
  const {
    graphql: {
      mutations: { CUSTOMER_RECOVER },
    },
  } = useCore()
  const { clearCustomer } = useCustomerContext()
  const [customerRecover] = useMutation(CUSTOMER_RECOVER)

  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<Array<string>>([])
  const [data, setData] = useState({ email: "" })
  const [success, setSuccess] = useState(false)

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault()
    await recoverCustomer(data?.email)
  }

  const handleChange = ({ target: { type, name, value, checked } }: React.ChangeEvent<HTMLInputElement>) => {
    setData(prevState => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }))
  }

  const recoverCustomer = useCallback(
    async email => {
      setLoading(true)
      setErrors([])

      try {
        const {
          data: {
            customerRecover: { customerUserErrors },
          },
        } = await customerRecover({
          variables: { email },
        })

        if (!customerUserErrors?.length) {
          clearCustomer()
          setLoading(false)
          setSuccess(true)
        } else {
          setErrors(customerUserErrors)
          setLoading(false)
          setSuccess(false)
        }
      } catch (err) {
        console.error(err)
        setErrors([(err as Error).message])
        setLoading(false)
      }
    },
    [setLoading, setErrors, customerRecover, clearCustomer]
  )

  return { recoverCustomer, data, handleSubmit, handleChange, loading, errors, success }
}

export const useCustomerAccount = () => {
  const {
    helpers: { isBrowser, getUrlParameter },
    graphql: {
      mutations: { CUSTOMER_RESET, CUSTOMER_ACTIVATE },
    },
  } = useCore()
  const {
    settings: { routes, params },
  } = useConfigContext()
  const { saveCustomer } = useCustomerContext()
  const { track } = useKlaviyo()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<Array<string>>([])
  const [data, setData] = useState({ password: "" })

  const [customerReset] = useMutation(CUSTOMER_RESET)
  const [customerActivate] = useMutation(CUSTOMER_ACTIVATE)

  const handleChange = ({ target: { type, name, value, checked } }: React.ChangeEvent<HTMLInputElement>) => {
    setData(prevState => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }))
  }

  const { resetCustomerId, resetToken } = useMemo(() => {
    const resetParams = getUrlParameter(params?.customer)?.split("/") || []
    const resetCustomerId = resetParams?.[0] || null
    const resetToken = resetParams?.[1] || null
    if (!resetCustomerId || !resetToken) setErrors(["Your reset password link is invalid."])
    return { resetCustomerId, resetToken }
  }, [getUrlParameter, params?.customer])

  const handleResetSubmit = async event => {
    event.preventDefault()
    await resetCustomer(resetCustomerId, resetToken, data?.password)
  }

  const resetCustomer = useCallback(
    async (customerId, resetToken, password) => {
      setLoading(true)
      setErrors([])

      try {
        const id = btoa(`gid://shopify/Customer/${customerId}`)

        const {
          data: {
            customerReset: { customerAccessToken, customerUserErrors },
          },
        } = await customerReset({
          variables: { id, input: { resetToken, password } },
        })

        if (!customerUserErrors?.length) {
          saveCustomer(customerAccessToken)
          track("Account Reset", false)
          if (isBrowser) navigate(routes.DASHBOARD)
        } else {
          setErrors(customerUserErrors)
          setLoading(false)
        }
      } catch (err) {
        console.error(err)
        setErrors([(err as Error).message])
        setLoading(false)
      }
    },
    [setLoading, setErrors, customerReset, saveCustomer, isBrowser, track, routes]
  )

  const { activateCustomerId, activationToken } = useMemo(() => {
    const activateParams = getUrlParameter(params?.customer)?.split("/") || []
    const activateCustomerId = activateParams?.[0] || null
    const activationToken = activateParams?.[1] || null
    if (!activateCustomerId || !activationToken) setErrors(["Your activate account link is invalid."])
    return { activateCustomerId, activationToken }
  }, [getUrlParameter, params?.customer])

  const handleActivateSubmit = async (event: any) => {
    event.preventDefault()
    await activateCustomer(activateCustomerId, activationToken, data?.password)
  }

  const activateCustomer = useCallback(
    async (customerId, activationToken, password) => {
      setLoading(true)
      setErrors([])

      try {
        const id = btoa(`gid://shopify/Customer/${customerId}`)

        const {
          data: {
            customerActivate: { customerAccessToken, customerUserErrors },
          },
        } = await customerActivate({
          variables: { id, input: { activationToken, password } },
        })

        if (!customerUserErrors?.length) {
          saveCustomer(customerAccessToken)
          track("Account Activate", false)
          if (isBrowser) navigate(routes.DASHBOARD)
        } else {
          setErrors(customerUserErrors)
          setLoading(false)
        }
      } catch (err) {
        console.error(err)
        setErrors([(err as Error).message])
        setLoading(false)
      }
    },
    [setLoading, setErrors, customerActivate, saveCustomer, isBrowser, track, routes]
  )

  return { resetCustomer, activateCustomer, data, setData, handleChange, handleActivateSubmit, handleResetSubmit, loading, errors }
}

export const useCustomerOrders = (first: number) => {
  const {
    graphql: {
      queries: { GET_CUSTOMER_ORDERS },
    },
  } = useCore()
  const { getStorage } = useStorage()
  const {
    settings: { keys },
  } = useConfigContext()
  const { orderNormaliser } = useShopify()
  const { accessToken: customerAccessToken } = getStorage(keys.customer) || { accessToken: "" }

  const { data, loading, error } = useQuery(GET_CUSTOMER_ORDERS, {
    variables: {
      customerAccessToken,
      first,
      reverse: true,
      countryCode: "AU",
    },
  })

  const orders = orderNormaliser(data?.customer?.orders)

  return { orders, loading, error }
}

export const useCustomerOrder = (orderId: string, key: string) => {
  const {
    helpers: { encodeBase64 },
    graphql: {
      queries: { GET_ORDER },
    },
  } = useCore()
  const { orderNormaliser } = useShopify()
  const id = encodeBase64(`gid://shopify/Order/${orderId}${key}`)

  const { data, loading, error } = useQuery(GET_ORDER, {
    variables: {
      id,
    },
  })

  const order = data?.node ? orderNormaliser(data?.node)?.[0] : null

  return { order, loading, error }
}

export const useCustomerAddress = (customer: any) => {
  const {
    helpers: { edgeNormaliser },
    graphql: {
      mutations: { CUSTOMER_ADDRESS_CREATE, CUSTOMER_ADDRESS_UPDATE, CUSTOMER_ADDRESS_DELETE, CUSTOMER_DEFAULT_ADDRESS_UPDATE },
      queries: { GET_CUSTOMER },
    },
  } = useCore()
  const { getStorage } = useStorage()
  const {
    settings: { keys },
  } = useConfigContext()
  const { addressNormaliser } = useShopify()
  const { countryCode } = useCheckoutContext()
  const { track } = useKlaviyo()
  const [saving, setSaving] = useState(false)
  const [errors, setErrors] = useState<Array<string>>([])
  const initialData = useMemo(
    () => ({
      address1: "",
      address2: "",
      city: "",
      company: "",
      country: "",
      firstName: "",
      lastName: "",
      phone: "",
      province: "",
      zip: "",
    }),
    []
  )
  const initialAddresses = edgeNormaliser(customer?.addresses)?.map(addressNormaliser)
  const [address, setAddress] = useState({ ...initialData, id: "", action: "" })
  const [addresses, setAddresses] = useState(initialAddresses)
  const { accessToken: customerAccessToken } = getStorage(keys.customer) || { accessToken: "" }

  const [customerAddressCreate] = useMutation(CUSTOMER_ADDRESS_CREATE)
  const [customerAddressUpdate] = useMutation(CUSTOMER_ADDRESS_UPDATE)
  const [customerAddressDelete] = useMutation(CUSTOMER_ADDRESS_DELETE)
  const [customerDefaultAddressUpdate] = useMutation(CUSTOMER_DEFAULT_ADDRESS_UPDATE)

  const filterData = useCallback(
    address =>
      Object.keys(address)
        .filter(key => Object.keys(initialData).includes(key))
        .reduce((obj: any, key: string) => {
          obj[key] = address[key]
          return obj
        }, {}),
    [initialData]
  )

  const [getAll, { loading }] = useLazyQuery(GET_CUSTOMER, {
    fetchPolicy: "no-cache",
    variables: {
      countryCode,
      customerAccessToken,
    },
  })

  const setNewAddresses = useCallback(async () => {
    const { data } = await getAll()
    setAddresses(
      edgeNormaliser(data?.customer?.addresses)?.map((address: any) => ({
        ...address,
        default: address?.id === data?.customer?.defaultAddress?.id,
      }))
    )
  }, [getAll, setAddresses, edgeNormaliser])

  useEffect(() => {
    setNewAddresses()
  }, [])

  const createAddress = useCallback(
    async address => {
      setSaving(true)
      setErrors([])

      try {
        const {
          data: {
            customerAddressCreate: { customerUserErrors },
          },
        } = await customerAddressCreate({
          variables: { customerAccessToken, address: filterData(address) },
        })

        if (!customerUserErrors?.length) {
          setAddress({ ...initialData, id: "", action: "" })
          setSaving(false)
          setNewAddresses()
          track("Address Add", filterData(address))
        } else {
          setErrors(customerUserErrors)
          setSaving(false)
        }
      } catch (err) {
        console.error(err)
        setErrors([(err as Error).message])
        setSaving(false)
      }
    },
    [setNewAddresses, setSaving, setErrors, setAddress, customerAddressCreate, customerAccessToken, track, filterData, initialData]
  )

  const updateAddress = useCallback(
    async (id, address) => {
      setSaving(true)
      setErrors([])

      try {
        const {
          data: {
            customerAddressUpdate: { customerUserErrors },
          },
        } = await customerAddressUpdate({
          variables: { customerAccessToken, id, address: filterData(address) },
        })

        if (!customerUserErrors?.length) {
          setAddress({ ...initialData, id: "", action: "" })
          setSaving(false)
          setNewAddresses()
          track("Address Update", filterData(address))
        } else {
          setErrors(customerUserErrors)
          setSaving(false)
        }
      } catch (err) {
        console.error(err)
        setErrors([(err as Error).message])
        setSaving(false)
      }
    },
    [setNewAddresses, setSaving, setErrors, setAddress, customerAddressUpdate, customerAccessToken, track, filterData, initialData]
  )

  const defaultAddress = useCallback(
    async addressId => {
      setSaving(true)
      setErrors([])

      try {
        const {
          data: {
            customerDefaultAddressUpdate: { customerUserErrors },
          },
        } = await customerDefaultAddressUpdate({
          variables: { addressId, customerAccessToken },
        })

        if (!customerUserErrors?.length) {
          setSaving(false)
          setNewAddresses()
          track("Address Default", addressId)
        } else {
          setErrors(customerUserErrors)
          setSaving(false)
        }
      } catch (err) {
        console.error(err)
        setErrors([(err as Error).message])
        setSaving(false)
      }
    },
    [setNewAddresses, setSaving, setErrors, customerDefaultAddressUpdate, customerAccessToken, track]
  )

  const deleteAddress = useCallback(
    async id => {
      setSaving(true)
      setErrors([])

      try {
        const {
          data: {
            customerAddressDelete: { customerUserErrors },
          },
        } = await customerAddressDelete({
          variables: { id, customerAccessToken },
        })

        if (!customerUserErrors?.length) {
          setSaving(false)
          setNewAddresses()
          track("Address Delete", id)
        } else {
          setErrors(customerUserErrors)
          setSaving(false)
        }
      } catch (err) {
        console.error(err)
        setErrors([(err as Error).message])
        setSaving(false)
      }
    },
    [setNewAddresses, setSaving, setErrors, customerAddressDelete, customerAccessToken, track]
  )

  return {
    addresses,
    setAddress,
    address,
    createAddress,
    updateAddress,
    defaultAddress,
    deleteAddress,
    initialData,
    loading,
    saving,
    errors,
  }
}

export const useCustomerDetails = () => {
  const {
    graphql: {
      mutations: { CUSTOMER_UPDATE },
    },
  } = useCore()
  const { getStorage } = useStorage()
  const {
    settings: { keys },
  } = useConfigContext()
  const { formatErrors } = useShopify()
  const { formatPhoneNumber } = useValidation()

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [errors, setErrors] = useState<any>()
  const initialData = useMemo(
    () => ({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      password: "",
      acceptsMarketing: false,
    }),
    []
  )
  const [customerUpdate] = useMutation(CUSTOMER_UPDATE)
  const { customer: initialCustomer, setCustomer: saveCustomer } = useCustomerContext()
  const [customer, setCustomer] = useState(initialCustomer)
  const { accessToken: customerAccessToken } = getStorage(keys.customer) || { accessToken: "" }

  const filterData = useCallback(
    (data, hidePassword = false) => {
      return hidePassword
        ? Object.keys(data)
          .filter(key => Object.keys(initialData).includes(key))
          .filter(key => key !== "password")
          .reduce((obj: any, key: string) => {
            obj[key] = data[key]
            return obj
          }, {})
        : Object.keys(data)
          .filter(key => Object.keys(initialData).includes(key))
          .reduce((obj: any, key: string) => {
            obj[key] = data[key]
            return obj
          }, {})
    },
    [initialData]
  )

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault()
    await updateCustomer(customer)
  }

  const handleChange = ({ target: { type, name, value, checked } }: React.ChangeEvent<HTMLInputElement>) =>
    setCustomer(prevState => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }))

  const updateCustomer = useCallback(
    async customer => {
      setSuccess(false)
      setLoading(true)
      setErrors([])

      let newCustomer

      const phoneNumber = formatPhoneNumber(customer.phone)

      try {
        newCustomer = {
          ...customer,
          ...(phoneNumber ? { phone: phoneNumber } : { phone: null }),
        }
        setCustomer(newCustomer)
      } catch (e) {
        console.log(e)
        return
      }

      try {
        const {
          data: {
            customerUpdate: { customerUserErrors },
          },
        } = await customerUpdate({
          variables: { customerAccessToken, customer: filterData(newCustomer) },
        })

        if (!customerUserErrors?.length) {
          saveCustomer((prevCustomer: any) => ({
            ...prevCustomer,
            ...filterData(customer, true),
          }))
          setLoading(false)
        } else {
          setErrors(formatErrors(customerUserErrors))
          setLoading(false)
        }
      } catch (err) {
        setErrors(formatErrors(err))
        setLoading(false)
      }

      setSuccess(true)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setLoading, setErrors, saveCustomer, customerUpdate, customerAccessToken, filterData, formatErrors]
  )

  return { customer, setCustomer, updateCustomer, handleSubmit, handleChange, loading, errors, success }
}

export const useCustomerTags = (tags = ["sms", "birthday"]) => {
  const {
    helpers: { decodeBase64 },
  } = useCore()
  const {
    settings: {
      customer: { tagPrefix },
    },
  } = useConfigContext()
  // const { formatErrors } = useShopify()
  const { customer } = useCustomerContext()

  const extractTag = useCallback(
    (tag: string) => {
      const current = customer?.tags?.find((item: string) => item.includes(tag)) || ""
      const value = current?.split(":")?.[2]?.replace("true", true)
      return value
    },
    [customer?.tags]
  )

  //@ts-ignore
  const initialData = useMemo(() => Object.assign(...tags.map(tag => ({ [tag]: extractTag(tag) }))), [tags, extractTag])

  const { callFunction, loading, errors, data, handleChange } = useFunctions(initialData)

  const buildTags = useCallback(
    () => [
      ...customer.tags.filter((item: string) => !!tags.find(tag => item.includes(`${tagPrefix}:${tag}`))),
      ...Object.entries(data).map(item => `${tagPrefix}:${item?.[0]}:${item?.[1]}`),
    ],
    [data, tagPrefix, tags, customer?.tags]
  )

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault()
    await callFunction("customer-tags", { id: decodeBase64(customer?.id), tags: buildTags() })
  }

  return { data, handleSubmit, handleChange, loading, errors }
}

/* eslint-enable */
