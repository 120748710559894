import React, { useEffect } from "react"

import { useAppContext } from "@providers/app"

import { useAnalytics } from "@hooks/useAnalytics"
import { useMaintenance } from "@hooks/useMaintenance"
import { useShopifyProduct } from "@hooks/useShopifyProduct"

import { globalHistory } from "@reach/router"
import type { Location } from "@root/types/global"

export const withLayout =
  (Component: React.FC<{ data: any; serverData: any; location: Location }>) =>
  ({ name = "Layout", location, children, data, serverData }: any) => {
    const { active, authorised } = useMaintenance(location)
    const { selectProduct } = useShopifyProduct()
    const { trackPageView, tracked } = useAnalytics()
    const { dispatch } = useAppContext()

    useEffect(() => {
      selectProduct(serverData?.product, location?.pathname)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location?.pathname, serverData?.product, selectProduct])

    useEffect(() => {
      trackPageView()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location?.pathname, tracked])

    useEffect(() => {
      dispatch({ type: "initial" })
    }, [location?.pathname, dispatch])

    useEffect(() => {
      globalHistory.listen(({ location }) => {
        try {
          // store pathname in localstorage
          // store a max of 10 previous pathnames
          const historyString = window.localStorage.getItem("reachGlobalHistory")
          const history = historyString ? JSON.parse(historyString) : []

          if (history.length > 10) {
            history.shift()
          }

          history.push(location.pathname)

          window.localStorage.setItem("reachGlobalHistory", JSON.stringify(history))
        } catch (err) {
          console.error(err)
        }
      })
    }, [])

    Component.displayName = name
    return active ? (
      <>{children}</>
    ) : (
      authorised && (
        <Component data={serverData ? serverData : data} location={location}>
          {children}
        </Component>
      )
    )
  }
