export const Select = {
  variants: {
    outline: {
      field: {
        borderRadius: "xxs",
        borderColor: "brand.border",
        paddingInlineStart: "unset",
        paddingInlineEnd: "unset",
        WebkitPaddingStart: 15,
        WebkitPaddingEnd: 40,
        h: "44px",
      },
      icon: {
        fontSize: "36px",
      },
    },
  },
}
