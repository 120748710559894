export const borderRadius = {
  radii: {
    xxs: "5px",
    xs: "10px",
    sm: "16px",
    md: "46px",
    lg: "52px",
    xl: "60px",
    xxl: "71px",
  },
}
