import React, { useCallback, useState } from "react"
import { Box, BoxProps, IconButton } from "@chakra-ui/react"
import { useAppContext } from "@providers/app"

import Icon from "@components/Icon/Icon"
import TextButton from "../TextButton"
import { useWishlistContext } from "@providers/wishlist"
import { useCustomerContext } from "@providers/customer"
import { navigate } from "gatsby"
import { useConfigContext } from "@providers/config"
import { useAnalytics } from "@hooks/useAnalytics"

export interface ProductWishlistProps extends BoxProps {
  product: any
  variant?: "icon" | "text"
  size?: "sm" | "lg"
}

const ProductWishlist = ({ product, variant = "icon", size = "lg", ...boxProps }: ProductWishlistProps) => {
  const {
    settings: { routes },
  } = useConfigContext()
  const { activeVariant } = useAppContext()
  const { trackWishlistUpdate } = useAnalytics()
  const productVariant = activeVariant ?? product?.variants?.[0]
  const { fetched, isInWishlist, addToWishList, removeFromWishList } = useWishlistContext()
  const { isInWishList } = isInWishlist(product)
  const { customer } = useCustomerContext()

  // State setters
  const [loading, setLoading] = useState(false)

  const buttonLoading = !customer ? false : loading || !fetched
  const label = isInWishList ? "Remove from Wishlist" : "Add to Wishlist"
  const icon = <Icon name="wishlist" size={size === "lg" ? "19px" : "16px"} fill={isInWishList ? "text.icon" : "none"} />

  const handleButtonClick = useCallback(async () => {
    if (!customer) {
      navigate(`${routes.LOGIN}`)
    }

    // If the product is in the wishlist already, remove it
    if (isInWishList) {
      setLoading(true)
      await removeFromWishList(product)
      trackWishlistUpdate("remove", product)
      setLoading(false)
      return
    } else {
      setLoading(true)
      await addToWishList(product, activeVariant ? "pdp" : "plp")
      trackWishlistUpdate("add", product)
      setLoading(false)
      return
    }
  }, [activeVariant, addToWishList, customer, isInWishList, product, removeFromWishList, routes.LOGIN])

  return (
    <Box {...boxProps}>
      {variant === "icon" ? (
        <IconButton
          aria-label={label}
          onClick={handleButtonClick}
          variant="ghost"
          isLoading={buttonLoading}
          bg="brand.beige.dark"
          borderRadius="full"
          p={1.5}
          minW="auto"
          height="unset"
          icon={icon}
        />
      ) : (
        <TextButton
          size="m"
          color="text.primary"
          py={1}
          icon="wishlist"
          iconSize="19px"
          iconPlacement="left"
          iconSpacing={1}
          onClick={handleButtonClick}
          fill={isInWishList ? "text.icon" : "none"}
          isLoading={buttonLoading}
        >
          {label}
        </TextButton>
      )}
    </Box>
  )
}

export default React.memo(ProductWishlist)
