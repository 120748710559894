import React from "react"
import { IconButton, AlertDialogBody, AlertDialogContent, Container, Stack, HStack } from "@chakra-ui/react"

import Icon from "@components/Icon/Icon"

import type { SearchOverlayComponentProps } from "./SearchOverlay"
import { InstantSearchMobile } from "../InstantSearch"
import SearchForm from "../Form"

export const SearchOverlayMobile = React.forwardRef(({ cancelRef, onClose }: SearchOverlayComponentProps, ref) => {
  return (
    <AlertDialogContent ref={ref} mt={0} maxW="100%" borderRadius="0" minH="100vh">
      <AlertDialogBody px={0} pt={2}>
        <Container>
          <Stack spacing={0} mb={0}>
            <HStack justifyContent="space-between" spacing={4}>
              <HStack flexGrow={1}>
                {/* @ts-ignore */}
                <SearchForm />
                <IconButton
                  variant="ghost"
                  icon={<Icon name="close" size="24px" />}
                  ref={cancelRef}
                  onClick={onClose}
                  borderRadius="full"
                  aria-label="Close Search"
                  _focus={{ outlineColor: "none", backgroundColor: "none" }}
                  _active={{ outlineColor: "none", backgroundColor: "none" }}
                />
              </HStack>
            </HStack>
            <InstantSearchMobile onClose={onClose} />
          </Stack>
        </Container>
      </AlertDialogBody>
    </AlertDialogContent>
  )
})

export default SearchOverlayMobile
