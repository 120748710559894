import React from "react"
import { IconButton, AlertDialogBody, AlertDialogContent, Container, Stack, HStack } from "@chakra-ui/react"

import Brand from "@components/Brand"
import Icon from "@components/Icon/Icon"
import AccountWidget from "@components/Account/Widget/AccountWidget"
import WishlistWidget from "@components/Wishlist/WishlistWidget"
import CartWidget from "@components/Cart/CartWidget"

import { InstantSearchDesktop } from "../InstantSearch"
import SearchForm from "../Form"
import type { SearchOverlayComponentProps } from "./SearchOverlay"

export const SearchOverlayDesktop = React.forwardRef(({ cancelRef, onClose }: SearchOverlayComponentProps, ref) => {
  return (
    <AlertDialogContent ref={ref} mt={3} maxW="100%" borderRadius="0">
      <AlertDialogBody px={4} pt={2}>
        <Container>
          <Stack spacing={4.5} mb={3}>
            <HStack justifyContent="space-between" spacing={4}>
              <Brand desktopActive={true} />
              <HStack flexGrow={1}>
                {/* @ts-ignore */}
                <SearchForm />
                <HStack spacing={0}>
                  <IconButton
                    variant="ghost"
                    icon={<Icon name="close" size="24px" />}
                    ref={cancelRef}
                    onClick={onClose}
                    aria-label="Close Search"
                    _focus={{ outlineColor: "none", backgroundColor: "none" }}
                    _active={{ outlineColor: "none", backgroundColor: "none" }}
                  />
                  <AccountWidget />
                  <WishlistWidget />
                  <CartWidget />
                </HStack>
              </HStack>
            </HStack>
            <InstantSearchDesktop onClose={onClose} />
          </Stack>
        </Container>
      </AlertDialogBody>
    </AlertDialogContent>
  )
})

export default SearchOverlayDesktop
