import { useState, useCallback } from "react"

export const useFunctions = (initialData = {}) => {
  const [data, setData] = useState(initialData)
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<Array<string>>([])

  const handleChange = ({ target: { type, name, value, checked } }: React.ChangeEvent<HTMLInputElement>) => {
    setData(prevState => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }))
  }

  const callFunction = useCallback(async (name, data) => {
    try {
      setLoading(true)
      setErrors([])
      const response = await fetch(`/api/${name}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
      const body = await response.json()
      setLoading(false)
      return { ...body }
    } catch (err) {
      setLoading(false)
      setErrors([(err as Error).message])
      return { status: "error", body: (err as Error).message }
    }
  }, [])

  return { callFunction, loading, errors, data, handleChange }
}
