import React, { PropsWithChildren } from "react"
import config from "@root/config.js"
import { useLocationContext } from "@providers/location"

type ContextProps = Config

export const ConfigContext = React.createContext<ContextProps | undefined>(undefined)

export const ConfigProvider = ({ children }: PropsWithChildren) => {
  const { store } = useLocationContext() as StoreLocation

  const activeConfig = React.useMemo<ContextProps>(
    () =>
      ({
        ...config,
        store,
      }) as Config,
    [store]
  )

  const contextValue = React.useMemo<ContextProps>(() => activeConfig, [activeConfig])

  return <ConfigContext.Provider value={contextValue}>{children}</ConfigContext.Provider>
}

export const useConfigContext = (): ContextProps => ({ ...React.useContext(ConfigContext) }) as ContextProps
