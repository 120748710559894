import React from "react"
import { Text, Stack, StackProps, HStack } from "@chakra-ui/react"
import { useAppContext } from "@providers/app"
import { useShopifyPrice } from "@hooks/useShopify"

export interface ProductPriceProps {
  variant?: any
  loading?: boolean
  direction?: StackProps["direction"]
  updatedQty?: number
  discounts?: [any]
}

const ProductPrice = ({ variant, direction = "column", updatedQty = 1, discounts }: ProductPriceProps) => {
  const { activeVariant } = useAppContext()
  const itemVariant = variant ?? activeVariant

  const { formattedPrice, formattedCompareAtPrice, onSale, price } = useShopifyPrice(itemVariant, updatedQty)
  const discountsToShow = React.useMemo(() => {
    return discounts?.filter?.(discount => {
      return discount?.discountApplication?.targetSelection !== "ALL"
    })
  }, [discounts])

  const totalDiscount = React.useMemo(() => {
    // reduce all valid discounts into 1 discount amount
    return discountsToShow?.reduce?.(
      (acc, discount) => {
        return {
          allocatedAmount: {
            amount: acc?.allocatedAmount?.amount + Number(discount?.allocatedAmount?.amount),
          },
        }
      },
      {
        allocatedAmount: {
          amount: 0,
        },
      }
    )
  }, [discountsToShow])

  const isDiscount = React.useMemo(() => {
    return discountsToShow?.length
  }, [discountsToShow])

  const DiscountPrice = () => {
    return (
      <Stack>
        <HStack>
          <Text fontWeight={"regular"} color={"text.primary"}>
            ${(Math.round((price - totalDiscount.allocatedAmount.amount) * 100) / 100)?.toFixed(2)}
          </Text>
          <Text color="text.secondary" as="s">{`${formattedPrice}`}</Text>
        </HStack>
        <Text color="text.secondary" size={"xs"}>
          Save ${Number(totalDiscount?.allocatedAmount?.amount)?.toFixed?.(2)} off RRP
        </Text>
      </Stack>
    )
  }

  return isDiscount ? (
    <>{DiscountPrice()}</>
  ) : (
    <Stack spacing={direction === "column" ? 0 : 1} direction={direction}>
      {onSale ? (
        <Text color="text.secondary" as="s">
          {formattedCompareAtPrice}
        </Text>
      ) : null}
      {formattedPrice ? (
        <Text fontWeight={onSale ? "semibold" : "regular"} color={onSale ? "brand.sale" : "text.primary"}>
          {formattedPrice}
        </Text>
      ) : null}
    </Stack>
  )
}
export default React.memo(ProductPrice)
