import React from "react"
import { Container, Grid } from "@chakra-ui/react"
import useBenefits from "@hooks/useBenefits"
import BenefitItem from "./BenefitItem"

const Benefits = ({ removeTopMargin = false }: { removeTopMargin?: boolean }) => {
  const benefits = useBenefits()

  return (
    <Container mt={removeTopMargin ? 0 : 5} mb={4}>
      <Grid gap={4} gridTemplateColumns={["repeat(2, 1fr)", "repeat(4, 1fr)"]}>
        {benefits?.map(benefit => <BenefitItem key={benefit.key} {...benefit} />)}
      </Grid>
    </Container>
  )
}

export default React.memo(Benefits)
