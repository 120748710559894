import React, { useState, useReducer, PropsWithChildren } from "react"

type ContextProps = {
  state: {
    activeMenu: boolean
    activeCart: boolean
    activeAccountDrawer: boolean
    activeGiftWrappingDrawer: boolean
    activeGiftRegistryDrawer: boolean
    giftWrappingMode: string
    activeCartStore: object
    addedToCart: boolean
    activeSearch: boolean
    activeSubscribe: boolean
    activeRewards: boolean
    activeMobileAccountMenu: boolean
  }
  dispatch: React.Dispatch<any>
  activeOptions: any | null
  setActiveOptions: (state: any) => void
  activeVariant: any | null
  setActiveVariant: (state: any) => void
  activeProduct: any | null
  setActiveProduct: (state: any) => void
  activeCollection: any | null
  setActiveCollection: (state: any) => void
  accountDrawer: any | null
  setAccountDrawer: (state: any) => void
  accountDrawerData: any | null
  setAccountDrawerData: (state: any) => void
  giftWrappingDrawer: any | null
  setGiftWrappingDrawer: (state: any) => void
  giftRegistryDrawer: any | null
  setGiftRegistryDrawer: (state: any) => void
  activeMobileAccountMenu: any | null
  setActiveMobileAccountMenu: (state: any) => void
}

export const AppContext = React.createContext<ContextProps | undefined>(undefined)

export const AppProvider = ({ children }: PropsWithChildren) => {
  const [activeOptions, setActiveOptions] = useState(null)
  const [activeVariant, setActiveVariant] = useState(null)
  const [activeProduct, setActiveProduct] = useState(null)
  const [activeCartStore, setActiveCartStore] = useState(null)
  const [activeCollection, setActiveCollection] = useState(null)
  const [accountDrawer, setAccountDrawer] = useState(null)
  const [accountDrawerData, setAccountDrawerData] = useState(null)
  const [giftWrappingDrawer, setGiftWrappingDrawer] = useState(null)
  const [giftRegistryDrawer, setGiftRegistryDrawer] = useState(null)
  const [activeMobileAccountMenu, setActiveMobileAccountMenu] = useState(null)

  const initialValues = {
    activeMenu: false,
    activeCart: false,
    activeAccountDrawer: false,
    activeGiftWrappingDrawer: false,
    giftWrappingMode: "add", // add | edit
    activeGiftRegistryDrawer: false,
    addedToCart: false,
    activeCartStore: null,
    activeSearch: false,
    activeSubscribe: false,
    activeRewards: false,
    activeMobileAccountMenu,
  }

  const reducer = (state: any, action: any) => {
    switch (action.type) {
      case "initial":
        return { ...state, ...initialValues }
      case "menu":
        return {
          ...state,
          activeMenu: action.payload,
          activeSearch: false,
          activeAccountDrawer: false,
          activeGiftWrappingDrawer: false,
          activeGiftRegistryDrawer: false,
          activeCart: false,
          addedtoCart: false,
          activeSubscribe: false,
          activeRewards: false,
          activeMobileAccountMenu: false,
        }
      case "store":
        return {
          ...state,
          activeCartStore: action?.payload?.activeCartStore,
        }
      case "cart":
        return {
          ...state,
          activeCart: action.payload.activeCart,
          addedToCart: action.payload.addedToCart,
          activeSearch: false,
          activeMenu: false,
          activeSubscribe: false,
          activeAccountDrawer: false,
          activeGiftWrappingDrawer: false,
          activeGiftRegistryDrawer: false,
          activeRewards: false,
          activeMobileAccountMenu: false,
        }
      case "search":
        return {
          ...state,
          activeSearch: action.payload,
          activeCart: false,
          activeMenu: false,
          addedToCart: false,
          activeSubscribe: false,
          activeAccountDrawer: false,
          activeGiftWrappingDrawer: false,
          activeGiftRegistryDrawer: false,
          activeRewards: false,
          activeMobileAccountMenu: false,
        }
      case "subscribe":
        return {
          ...state,
          activeSubscribe: action.payload,
          activeSearch: false,
          activeCart: false,
          activeMenu: false,
          addedToCart: false,
          activeAccountDrawer: false,
          activeGiftWrappingDrawer: false,
          activeGiftRegistryDrawer: false,
          activeRewards: false,
          activeMobileAccountMenu: false,
        }
      case "accountDrawer":
        return {
          ...state,
          activeAccountDrawer: action.payload.activeAccountDrawer,
          activeGiftWrappingDrawer: false,
          activeGiftRegistryDrawer: false,
          activeCart: false,
          addedToCart: false,
          activeSearch: false,
          activeMenu: false,
          activeSubscribe: false,
          activeRewards: false,
          activeMobileAccountMenu: false,
        }
      case "giftWrappingDrawer":
        return {
          ...state,
          activeAccountDrawer: false,
          activeGiftWrappingDrawer: action.payload.activeGiftWrappingDrawer,
          giftWrappingMode: action.payload.giftWrappingMode,
          activeGiftRegistryDrawer: false,
          activeCart: false,
          addedToCart: false,
          activeSearch: false,
          activeMenu: false,
          activeSubscribe: false,
          activeRewards: false,
          activeMobileAccountMenu: false,
        }
      case "giftRegistryDrawer":
        return {
          ...state,
          activeAccountDrawer: false,
          activeGiftWrappingDrawer: false,
          giftWrappingMode: false,
          activeGiftRegistryDrawer: action.payload.activeGiftRegistryDrawer,
          activeCart: false,
          addedToCart: false,
          activeSearch: false,
          activeMenu: false,
          activeSubscribe: false,
          activeRewards: false,
          activeMobileAccountMenu: false,
        }
      case "rewards":
        return {
          ...state,
          activeAccountDrawer: false,
          activeGiftWrappingDrawer: false,
          activeGiftRegistryDrawer: false,
          activeCart: false,
          addedToCart: false,
          activeSearch: false,
          activeMenu: false,
          activeSubscribe: false,
          activeRewards: action.payload,
          activeMobileAccountMenu: false,
        }
    }
  }

  const [state, dispatch] = useReducer(reducer, initialValues)

  const contextValue = React.useMemo<ContextProps>(
    () => ({
      state,
      dispatch,
      activeOptions,
      setActiveOptions,
      activeVariant,
      setActiveVariant,
      activeProduct,
      setActiveProduct,
      activeCartStore,
      setActiveCartStore,
      activeCollection,
      setActiveCollection,
      accountDrawer,
      setAccountDrawer,
      accountDrawerData,
      setAccountDrawerData,
      giftWrappingDrawer,
      setGiftWrappingDrawer,
      giftRegistryDrawer,
      setGiftRegistryDrawer,
      activeMobileAccountMenu,
      setActiveMobileAccountMenu,
    }),
    [
      state,
      dispatch,
      activeOptions,
      setActiveOptions,
      activeVariant,
      setActiveVariant,
      activeProduct,
      activeCartStore,
      setActiveCartStore,
      setActiveProduct,
      activeCollection,
      setActiveCollection,
      accountDrawer,
      setAccountDrawer,
      accountDrawerData,
      setAccountDrawerData,
      giftWrappingDrawer,
      setGiftWrappingDrawer,
      giftRegistryDrawer,
      setGiftRegistryDrawer,
      activeMobileAccountMenu,
      setActiveMobileAccountMenu,
    ]
  )

  return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
}

export const useAppContext = (): ContextProps => ({ ...React.useContext(AppContext) }) as ContextProps
