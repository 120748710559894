import React from "react"
import { Box, Heading, Stack, Text } from "@chakra-ui/react"

import Link from "@components/Link"

export interface SearchTermListProps {
  title?: string
  query?: string
  link?: boolean
  items: string[]
  onClose?: () => void
}

const SearchTermList = ({ title, query, link, items, onClose }: SearchTermListProps) => {
  const regex = new RegExp(`(${query})`, "gi")

  const highlightSearchTerms = (link: string): (string | JSX.Element)[] => {
    const parts = link.split(regex)

    return parts.map((part, index) => {
      const color = regex.test(part) ? "text.primary" : "text.secondary"

      return (
        <Box as="span" key={index} color={color}>
          {part}
        </Box>
      )
    })
  }

  return (
    <Stack spacing={2}>
      {title && (
        <Heading as="h3" size="h3" fontWeight={["semibold", "light"]}>
          {title}
        </Heading>
      )}
      {items.map((search, index) =>
        link ? (
          <Link to={search?.link} key={index} cursor="pointer" onClick={onClose}>
            <Text size="lg">{query ? highlightSearchTerms(search?.title) : search?.title}</Text>
          </Link>
        ) : (
          <Link key={index} to={`/search?q=${search}`} onClick={onClose}>
            <Text size="lg" cursor="pointer">
              {search}
            </Text>
          </Link>
        )
      )}
    </Stack>
  )
}

export default React.memo(SearchTermList)
