import React, { useState, useMemo, useCallback, useEffect, PropsWithChildren, MouseEventHandler } from "react"
import { useQuery, useMutation } from "@apollo/client"
import { useCore, useStorage } from "@hooks/useCore"
import { useShopify } from "@hooks/useShopify"
import { useFunctions } from "@hooks/useFunctions"
import { useConfigContext } from "@providers/config"
import { useCustomerContext } from "@providers/customer"
import { useAnalytics } from "@hooks/useAnalytics"
import CHECKOUT_LINE_ITEMS_REPLACE from "@app/queries/lineItemsReplace"
import { useGiftRegistryContext } from "@app/providers/giftRegistry"

import { modifyCheckoutFragment } from "@hooks/useGQLMods"

type ContextProps = {
  id: string
  url: string
  count: number
  checkout: any
  loading: boolean
  countryCode: string
  refreshCheckout: () => void
  gotoCheckout: MouseEventHandler<HTMLButtonElement>
  giftRegistryCheckoutHandler: () => Promise<any>
  saveCheckout: (checkout: any) => void
  createCheckout: (countryCode: string | undefined, forceNew: boolean) => void
}

export const CheckoutContext = React.createContext<ContextProps | undefined>(undefined)

export const CheckoutProvider = ({ children }: PropsWithChildren) => {
  const {
    graphql: {
      queries: { GET_CHECKOUT },
      mutations: { CHECKOUT_CREATE },
    },
  } = useCore()
  const { getStorage, setStorage, removeStorage } = useStorage()
  const {
    store,
    settings: { keys },
  } = useConfigContext()
  const { decorateUrl } = useAnalytics()
  const { callFunction } = useFunctions()
  const { customer } = useCustomerContext()
  const { checkoutNormaliser } = useShopify()
  const { customerRegistryPurchase } = useGiftRegistryContext()

  modifyCheckoutFragment(GET_CHECKOUT)
  modifyCheckoutFragment(CHECKOUT_CREATE)

  const [lineItemsReplace] = useMutation(CHECKOUT_LINE_ITEMS_REPLACE)

  const { refetch: getCheckoutQuery } = useQuery(GET_CHECKOUT, { fetchPolicy: "no-cache", skip: true })
  const [checkoutCreate] = useMutation(CHECKOUT_CREATE)
  const [checkout, setCheckout] = useState<any>({})
  const [loading, setLoading] = useState(false)

  const id = useMemo(() => checkout?.id || getStorage(keys.checkout), [getStorage, keys.checkout, checkout?.id])

  const url = useMemo(
    () => (checkout?.webUrl ? decorateUrl(checkout.webUrl.replace(store.shopifyShopDomain, store.shopifyCheckoutUrl)) : ""),
    [checkout, store, decorateUrl]
  )

  const countryCode = useMemo(() => {
    const ret = checkout?.buyerIdentity?.countryCode || getStorage(keys.market) || "AU"
    return ret
  }, [getStorage, keys.market, checkout?.buyerIdentity?.countryCode])

  const count = useMemo(
    () =>
      checkout?.lineItems?.reduce(
        (count: number, lineItem: any, i: number) => (i ? count + parseInt(lineItem.quantity) : parseInt(lineItem.quantity)),
        0
      ) || 0,
    [checkout]
  )

  useEffect(() => {
    createCheckout(store?.locationRegion)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getCheckout = useCallback(async () => {
    try {
      if (id) {
        const countryCode = getStorage(keys.market) || store.locationRegion
        const {
          data: { node: checkout },
        } = await getCheckoutQuery({ countryCode, checkoutId: id })
        return checkout
      }
      return false
    } catch (e) {
      console.error((e as Error).message)
    }
  }, [id, keys.market, store.locationRegion, getCheckoutQuery, getStorage])

  const giftRegistryCheckoutHandler = useCallback(async () => {
    let registryItemsInCart = false
    let addressPrefill = false

    const hasRegistryProduct = checkout?.lineItems?.filter(item =>
      item.customAttributes.some(attr => attr.key === "registryProduct" && attr.value === "true")
    )

    if (hasRegistryProduct?.length > 0) {
      registryItemsInCart = true
    }

    if (registryItemsInCart === true) {
      registryItemsInCart = checkout?.lineItems
      if (checkout?.lineItems?.length > hasRegistryProduct?.length) {
        addressPrefill = false
      } else {
        addressPrefill = true
      }
    }

    return { registryItemsInCart, addressPrefill }
  }, [checkout])

  const saveCheckout = useCallback(
    checkout => {
      try {
        const checkoutCC = checkout?.customAttributes?.find((attr: any) => attr.key === "_click_and_collect" && attr.value === "true")
        const lineItemCC = checkout?.lineItems?.edges?.find(
          (item: any) => item?.node?.customAttributes?.find((attr: any) => attr.key === "_click_and_collect" && attr.value === "true")
        )
        const hasLineItems = checkout?.lineItems?.edges?.length
        let requiresSaving = false
        const lineItemsToSave = checkout?.lineItems?.edges?.map((item: any) => {
          return {
            variantId: item?.node?.variant?.id,
            customAttributes: [...item.node.customAttributes.map((attr: any) => ({ key: attr.key, value: attr.value }))],
            quantity: item?.node?.quantity,
          }
        })
        if (checkoutCC && !lineItemCC && hasLineItems) {
          const firstLineItem = lineItemsToSave[0]
          const clickAndCollectAttribute = firstLineItem?.customAttributes?.find((attr: any) => attr.key === "_click_and_collect")
          if (clickAndCollectAttribute) {
            const newClickAndCollectAttribute = { ...clickAndCollectAttribute }
            newClickAndCollectAttribute.value = "true"
            firstLineItem.customAttributes = [
              ...firstLineItem.customAttributes.filter((attr: any) => attr.key !== "_click_and_collect"),
              newClickAndCollectAttribute,
            ]
          } else {
            firstLineItem?.customAttributes?.push({ key: "_click_and_collect", value: "true" })
          }
          requiresSaving = true
        } else if (!checkoutCC && lineItemCC) {
          lineItemsToSave?.forEach((item: any) => {
            const clickAndCollectAttribute = item.customAttributes?.find((attr: any) => attr.key === "_click_and_collect")
            if (clickAndCollectAttribute) {
              // remove custom attribute from array
              item.customAttributes = item.customAttributes.filter((attr: any) => attr.key !== "_click_and_collect")
              requiresSaving = true
            }
          })
        }
        if (requiresSaving) {
          lineItemsReplace({
            variables: {
              countryCode,
              checkoutId: checkout?.id,
              lineItems: lineItemsToSave,
            },
          })
            .then(({ data: { checkoutLineItemsReplace: data, userErrors: errors } }) => {
              if (errors?.length) console.error(errors)
              if (data?.checkout) {
                checkout = data?.checkout
              }
              setCheckout(checkoutNormaliser(checkout))
              setStorage(keys.checkout, checkout?.id)
              setStorage(keys.market, checkout?.buyerIdentity?.countryCode)
            })
            .catch(err => {
              console.error(err)
              setCheckout(checkoutNormaliser(checkout))
              setStorage(keys.checkout, checkout?.id)
              setStorage(keys.market, checkout?.buyerIdentity?.countryCode)
            })
        } else {
          setCheckout(checkoutNormaliser(checkout))
          setStorage(keys.checkout, checkout?.id)
          setStorage(keys.market, checkout?.buyerIdentity?.countryCode)
        }
      } catch (e) {
        console.error((e as Error).message)
      }
    },
    [setCheckout, checkoutNormaliser, lineItemsReplace, countryCode, keys, setStorage]
  )

  const refreshCheckout = useCallback(async () => {
    const checkout = await getCheckout()
    setCheckout(checkoutNormaliser(checkout))
  }, [getCheckout, setCheckout, checkoutNormaliser])

  const createCheckout = useCallback(
    async (countryCode = "AU", forceNew = false) => {
      try {
        const existingCheckout = !forceNew && (await getCheckout())

        if (forceNew || !existingCheckout?.id || existingCheckout?.completedAt !== null || Object.keys(existingCheckout).length < 1) {
          const {
            data: {
              checkoutCreate: { checkout },
            },
          } = await checkoutCreate({
            variables: {
              countryCode,
              input: {
                buyerIdentity: {
                  countryCode,
                },
              },
            },
          })
          if (checkout) saveCheckout(checkout)
        } else {
          saveCheckout(existingCheckout)
        }
      } catch (e) {
        console.error((e as Error).message)
        const isThrottled = (e as Error).message?.toLowerCase()?.includes("throttled")
        if (!isThrottled) removeStorage(keys.checkout)
      }
    },
    [getCheckout, saveCheckout, checkoutCreate, removeStorage, keys]
  )

  const gotoCheckout: MouseEventHandler<HTMLButtonElement> = useCallback(
    async (e, addressPrefill, registryItemsInCart = []) => {
      e?.preventDefault()
      setLoading(true)

      //Gift registry updates

      const swymCheckoutURL = await customerRegistryPurchase(addressPrefill, registryItemsInCart, checkout)

      if (!swymCheckoutURL) {
        if (customer?.email) {
          try {
            const response = await callFunction("checkout-multipass", {
              customerEmail: customer?.email,
              createdAt: new Date().toISOString(),
              checkoutId: checkout.id,
              webUrl: checkout.webUrl,
            })

            const url = response.status !== "error" && response.body.includes("https://") ? response.body : checkout.webUrl

            if (e?.ctrlKey || e?.metaKey) {
              window.open(url, "_blank")
            } else {
              window.location.replace(url)
            }
          } catch (err) {
            if (e?.ctrlKey || e?.metaKey) {
              window.open(checkout.webUrl, "_blank")
            } else {
              window.location.replace(checkout.webUrl)
            }
          }
        } else {
          if (e?.ctrlKey || e?.metaKey) {
            window.open(checkout.webUrl, "_blank")
          } else {
            window.location.replace(checkout.webUrl)
          }
        }
      } else {
        if (swymCheckoutURL.status === "success") {
          if (e?.ctrlKey || e?.metaKey) {
            window.open(swymCheckoutURL.body.invoiceUrl, "_blank")
          } else {
            window.location.replace(swymCheckoutURL.body.invoiceUrl)
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [callFunction, checkout, customer]
  )

  const contextValue = useMemo<ContextProps>(
    () => ({
      id,
      url,
      count,
      loading,
      checkout,
      countryCode,
      gotoCheckout,
      saveCheckout,
      createCheckout,
      refreshCheckout,
      giftRegistryCheckoutHandler,
    }),
    [
      id,
      url,
      count,
      loading,
      checkout,
      countryCode,
      gotoCheckout,
      saveCheckout,
      createCheckout,
      refreshCheckout,
      giftRegistryCheckoutHandler,
    ]
  )

  return <CheckoutContext.Provider value={contextValue}>{children}</CheckoutContext.Provider>
}

export const useCheckoutContext = (): ContextProps => ({ ...React.useContext(CheckoutContext) }) as ContextProps
