import { Button } from "@chakra-ui/react"
import React from "react"
import config from "../../config.js"
import Icon from "@components/Icon/Icon"
import Link from "@components/Link"

const ContactUs = () => (
  <Button
    as={Link}
    to={config.settings.routes.CONTACT}
    variant="text"
    width="fit-content"
    fontSize={["18px", "14px"]}
    leftIcon={<Icon name="contact" size="22px" color="inherit" />}
  >
    Contact
  </Button>
)

export default ContactUs
