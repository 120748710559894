import { HeadingProps } from "@chakra-ui/react"
import { colors } from "../colors"

type HeadingSizes = "h1" | "h2" | "h3" | "h4" | "blogHeroHeading"

type HeadingThemeProps = {
  baseStyle: HeadingProps
  sizes: Record<HeadingSizes, HeadingProps>
}

export const Heading: HeadingThemeProps = {
  baseStyle: {
    color: colors.text.primary,
    fontWeight: "light",
    fontFamily: "Sofia Pro",
  },
  sizes: {
    h1: {
      fontSize: ["38px", "55px"],
      lineHeight: ["48px", "72px"],
    },
    h2: {
      fontSize: ["32px", "42px"],
      lineHeight: ["42px", "52px"],
    },
    h3: {
      fontSize: ["24px", "30px"],
      lineHeight: ["31.5px", "40px"],
    },
    h4: {
      fontSize: ["21px", "24px"],
      lineHeight: ["27px", "36px"],
    },
    blogHeroHeading: {
      fontSize: ["32px", "55px"],
      lineHeight: ["42px", "72px"],
    },
  },
}
