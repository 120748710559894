import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react"
import { Product } from "shopify-storefront-api-typings"
import { useCustomerContext } from "@providers/customer"
import { useConfigContext } from "@providers/config"
import { useCore, useStorage } from "@hooks/useCore"
import { graphql, useStaticQuery } from "gatsby"
import axios from "axios"
import { gql, useApolloClient } from "@apollo/client"
import { PRODUCT_FRAGMENT } from "@dotdev/dotheadless-app/dist/graphql/fragments"
import { v4 as uuid } from "uuid"
import deepEqual from "fast-deep-equal/react"
import { Customer } from "@usereactify/search/dist/src/types/graphql"
import { useShopify } from "@hooks/useShopify"
import { useCheckoutContext } from "./checkout"

export type WishlistProduct = Product & {
  quantity: number
  selectedSku: string
  selectedTitle: string
}

export type PostOptions = {
  version?: number
}

export type ContextProps = {
  fetched: boolean
  shareUrl: string
  sharedWishList: any
  setSharedWishList: React.Dispatch<React.SetStateAction<any>>
  addToWishList: (product: any, variant?: any, source?: string) => Promise<boolean>
  addToWishListByIds: (productHandle: any, variantId?: any, source?: string) => Promise<void>
  isInWishlist: (product: any) => boolean
  wishList: any
  shareWishList: () => Promise<void>
  removeFromWishList: (product: any) => Promise<boolean>
  removeAllFromWishList: () => Promise<boolean>
  updateWishListVariant: (productHandle: any, variantId?: any, source?: string) => Promise<boolean>
  count: number
  login: (customer: Customer) => Promise<boolean>
  logout: () => Promise<void>
  fetchWishListProductsData: (wishlist: any) => Promise<any>
}

export const WishlistContext = React.createContext<ContextProps | undefined>(undefined)

const DEFAULT_WISHLIST_NAME = "My Wishlist"

const PATHS = {
  LIST_WITH_CONTENTS: "lists/fetch-list-with-contents",
  FETCH_LISTS: "lists/fetch-lists",
  UPDATE_MULTIPLE_CTX: "lists/update-multiple-ctx",
  CREATE_MULTIPLE: "lists/create-multiple",
  USER_VALIDATE_SYNC: "lists/user-validate-sync",
  MARK_PUBLIC: "lists/markPublic",
  UPDATE: "lists/update",
  DELETE_LIST: "lists/update",
} as const

// human readable field names
// see https://api-docs.swym.it/v3/index.html#fields-in-list-item
const LIST_ID = "lid"
const LIST_NAME = "lname"
const PRODUCT_ID = "empi"
const PRODUCT_CANONICAL_URL = "du"
const PRODUCT_TITLE = "dt"
const PRODUCT_IMG_URL = "iu"
const VARIANT_ID = "epi"
const COLLECTION_NAME = "ct"
const SELECTED_VARIANT_INFO = "vi"
const PRODUCT_PRICE = "pr"
const CUSTOM_FIELDS = "cprops"

export const WishlistProvider = ({ children }: PropsWithChildren) => {
  const {
    helpers: { decodeShopifyId, isBrowser, encodeShopifyId },
  } = useCore()
  const { getProductsLight } = useShopify()
  const { customer } = useCustomerContext()
  const {
    store,
    settings: { routes, keys },
  } = useConfigContext()
  const { getStorage, setStorage, removeStorage } = useStorage()
  const client = useApolloClient()
  const { countryCode } = useCheckoutContext()

  // State setters
  const [wishList, setWishList] = useState<any>([])
  const [fetched, setFetched] = useState(false)
  const [userIdHolder, setUserIdHolder] = useState(null)
  const [sharedWishList, setSharedWishList] = useState<any>(null)

  const { plugins } = useStaticQuery<GatsbyTypes.SwymWishListQuery>(graphql`
    query SwymWishList {
      plugins: sanitySettingPlugins {
        swymWishlistPlusHost
        swymWishlistPlusPid
      }
    }
  `)

  const swymWishlistPlusPid = useMemo(
    () => (plugins?.swymWishlistPlusPid ? encodeURIComponent(plugins?.swymWishlistPlusPid) : null),
    [plugins?.swymWishlistPlusPid]
  )

  const hasMissingSwymKeys = useMemo(() => !plugins?.swymWishlistPlusHost || !plugins?.swymWishlistPlusPid, [plugins])
  const setUserId = useCallback((userId: string) => setStorage(keys.wishListUserId, userId), [keys, setStorage])

  useEffect(() => {
    if (userIdHolder != getStorage(keys.wishListUserId)) {
      setUserIdHolder(getStorage(keys.wishListUserId))
    }
  }, [getStorage, keys, setUserId, userIdHolder])

  const getUserId = useCallback(async () => {
    if (!isBrowser || hasMissingSwymKeys) return null
    try {
      const id = getStorage(keys.wishListUserId)
      if (id) {
        return id
      }
      // ? checkAndGet ?
      const endpoint = `${plugins?.swymWishlistPlusHost}/v3/provider/checkAndGet?pid=${swymWishlistPlusPid}`

      const response = await axios
        .post(
          endpoint,
          new URLSearchParams({
            js_v: "3.0.6", // ?
          })
        )
        .catch(err => {
          console.error(err)
          console.log("err?.response", err?.response)
        })

      const newId = response?.data?.get?.regid
      if (newId) {
        setUserId(newId)
        return newId
      }
    } catch (e) {
      console.warn(e)
    }

    return null
  }, [getStorage, hasMissingSwymKeys, isBrowser, keys.wishListUserId, plugins?.swymWishlistPlusHost, setUserId, swymWishlistPlusPid])

  const post = useCallback(
    async ({ path, data = {}, options = {} }: { path: string; data?: any; options?: PostOptions }) => {
      if (hasMissingSwymKeys) return null

      const endpoint = `${plugins?.swymWishlistPlusHost}/v${options.version || 3}/${path}?pid=${swymWishlistPlusPid}`

      const regid = await getUserId()
      const sessionid = uuid()

      try {
        const response = await axios.post(
          endpoint,
          new URLSearchParams({
            regid, // ?
            sessionid,
            ...data,
          })
        )

        return response
      } catch (e) {
        return null
      }
    },
    [getUserId, hasMissingSwymKeys, plugins, swymWishlistPlusPid]
  )

  const count = useMemo(
    () => (customer && wishList?.listcontents?.length ? wishList?.listcontents?.length : 0),
    [customer, wishList?.listcontents?.length]
  )

  const fetchWishLists: any = useCallback(async () => {
    const response = await post({ path: PATHS.FETCH_LISTS })

    const sortedLists = response?.data
      ?.reverse()
      ?.map((wishList: any) => ({
        ...wishList,
        default: wishList?.[LIST_NAME] === DEFAULT_WISHLIST_NAME,
      }))
      ?.sort((a: any, b: any) => {
        if (a?.[LIST_NAME] === DEFAULT_WISHLIST_NAME && b?.[LIST_NAME] !== DEFAULT_WISHLIST_NAME) return -1
        else if (a?.[LIST_NAME] !== DEFAULT_WISHLIST_NAME && b?.[LIST_NAME] === DEFAULT_WISHLIST_NAME) return 1
        return 0
      })
    return sortedLists
  }, [post])

  const fetchWishList: any = useCallback(async () => {
    const fetchedWishLists = await fetchWishLists()
    return fetchedWishLists?.find((list: any) => list?.[LIST_NAME] === DEFAULT_WISHLIST_NAME) || fetchedWishLists?.[0]
  }, [fetchWishLists])

  const fetchWishListProductsData: any = useCallback(
    async (wishlist: any) => {
      if (!wishlist) {
        return []
      }

      const ids = wishlist?.listcontents?.map((item: any) => ({
        id: item.empi,
        storefrontId: encodeShopifyId(item.empi, "Product"),
      }))
      if (!ids?.length) {
        return []
      }

      const { data } = await client.query({
        query: gql`
        query(
          $firstCollections: Int
          $firstImages: Int
          $firstMedia: Int
          $metafieldIdentifiers: [HasMetafieldsIdentifier!]!
          $firstVariants: Int
          $afterVariants: String
        ) {
          ${ids?.map(
            (id: any) => `
            product${id.id}: product(id: "${id.storefrontId}") {
              ...ProductFragment
            }
          `
          )}
        }
        ${PRODUCT_FRAGMENT}
      `,
        variables: {
          countryCode,
          firstCollections: 0,
          firstImages: 2,
          firstMedia: 0,
          metafieldIdentifiers: [],
          firstVariants: 100,
        },
      })

      return {
        ids,
        data,
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [client, countryCode, encodeShopifyId]
  )

  const refreshWishList = useCallback(async () => {
    const fetchedWishList = await fetchWishList()
    if (!deepEqual(wishList, fetchedWishList)) {
      setWishList(fetchedWishList)
    }
    setFetched(true)
  }, [fetchWishList, wishList])

  const fetchSharedWishList: any = useCallback(
    async (wishListId: any) => {
      const response = await post({
        path: PATHS.LIST_WITH_CONTENTS,
        data: {
          [LIST_ID]: wishListId,
        },
      })

      return {
        ...response?.data?.list,
        shared: true,
      }
    },
    [post]
  )

  const tryCreateList = useCallback(async () => {
    const list = await fetchWishList()
    if (list) return list?.[LIST_ID]

    const response = await post({
      path: PATHS.CREATE_MULTIPLE,
      data: {
        lists: JSON.stringify([
          {
            [LIST_NAME]: DEFAULT_WISHLIST_NAME,
          },
        ]),
      },
    })

    const listId = response?.data?.[0]?.[LIST_ID]

    return listId
  }, [fetchWishList, post])

  const isInWishlist = useCallback(
    (product: any) => {
      const productId = decodeShopifyId(String(product?.storefrontId || product?.id), "Product")
      if (!productId) {
        return false
      }
      const isInWishList = !!wishList?.listcontents?.find((item: any) => item.empi == parseInt(productId))

      return { isInWishList }

      // return !!wishList?.listcontents?.find((item: any) => item.empi === parseInt(productId))
    },
    //eslist-disable-next-line react-hooks/exhaustive-deps
    [decodeShopifyId, wishList?.listcontents]
  )

  const addToWishList = useCallback(
    async (product: any, variant?: any, source = "") => {
      const productId = decodeShopifyId(String(product?.storefrontId || product?.id), "Product")
      const resolvedVariant = variant || product?.variants?.[0]
      const variantId =
        decodeShopifyId(String(resolvedVariant?.storefrontId || resolvedVariant?.id), "ProductVariant") || String(resolvedVariant?.id)
      if (!productId || !variantId) return false

      const list = await fetchWishList()
      const defaultList = list ? list : await tryCreateList()

      const listId = defaultList.lid
      if (listId === null) return false
      let price = null

      try {
        price = parseFloat(resolvedVariant.priceV2?.amount)
      } catch (e) {
        // ignored
      }

      await post({
        path: PATHS.UPDATE_MULTIPLE_CTX,
        data: {
          listItem: JSON.stringify({
            [PRODUCT_CANONICAL_URL]: `https://${store.url}/products/${product.handle}`,
            [PRODUCT_TITLE]: product.title,
            [PRODUCT_IMG_URL]: product?.featuredImage?.originalSrc || product?.images?.[0]?.src || resolvedVariant?.image?.src,
            [PRODUCT_ID]: productId,
            [VARIANT_ID]: variantId,
            [COLLECTION_NAME]: source,
            [SELECTED_VARIANT_INFO]: "",
            [PRODUCT_PRICE]: price,
            _cv: true, // ?
            // Any custom fields can be mapped onto the product object
            [CUSTOM_FIELDS]: {
              availableForSale: resolvedVariant?.availableForSale,
              productTags: product?.tags,
              productType: product?.productType,
            },
          }),
          a: JSON.stringify([listId]),
        },
      })
      await refreshWishList()

      return true
    },
    [decodeShopifyId, fetchWishList, post, refreshWishList, store.url, tryCreateList]
  )

  const addToWishListByIds = useCallback(
    async (productHandle: string, variantId?: string, source = "") => {
      const product = (await getProductsLight({ firstImages: 1, firstVariants: 99, handles: [productHandle] }))?.filter(
        (item: any) => item?.handle
      )?.[0]
      await addToWishList(
        {
          handle: productHandle,
          id: product?.id,
          featuredImage: { originalSrc: product?.images?.[0]?.src },
          title: product?.title,
          variants: product?.variants,
        },
        variantId ? product?.variants?.find((v: any) => variantId === decodeShopifyId(v?.id, "ProductVariant")) : null,
        source
      )
    },
    [addToWishList, decodeShopifyId, getProductsLight]
  )

  const removeFromWishList = useCallback(
    async (product: any) => {
      const list = await fetchWishList()
      if (!list) return false

      const productId = parseInt(decodeShopifyId(product?.storefrontId || product?.id, "Product"))
      if (!productId) {
        return false
      }
      // const variantId = parseInt(decodeShopifyId(variant?.id, "ProductVariant"))
      const variantId = list?.listcontents?.find((item: any) => item.empi === productId)?.epi
      if (!variantId) {
        return false
      }

      await post({
        path: PATHS.UPDATE_MULTIPLE_CTX,
        data: {
          listItem: JSON.stringify({
            [PRODUCT_CANONICAL_URL]: `https://${store.url}/products/${product.handle}`,
            [PRODUCT_ID]: productId,
            [VARIANT_ID]: variantId,
          }),
          d: JSON.stringify([list?.[LIST_ID]]),
        },
      })

      setWishList({
        ...list,
        listcontents: list?.listcontents?.filter((item: any) => item.empi !== productId) || [],
      })

      await refreshWishList()
      return true
    },

    [decodeShopifyId, fetchWishList, post, refreshWishList, store.url]
  )

  const removeAllFromWishList = useCallback(
    async (wishListId?: any) => {
      const list = await fetchWishList(wishListId)
      if (list === null) return false

      for (const item of list.listcontents) {
        await post({
          path: PATHS.UPDATE_MULTIPLE_CTX,
          data: JSON.stringify({
            listItem: {
              [PRODUCT_CANONICAL_URL]: item?.[PRODUCT_CANONICAL_URL],
              [PRODUCT_ID]: item?.[PRODUCT_ID],
              [VARIANT_ID]: item?.[VARIANT_ID],
            },
            d: [list?.[LIST_ID]], // ?
          }),
        })
      }
      await refreshWishList()
      return true
    },
    [fetchWishList, post, refreshWishList]
  )

  const login = useCallback(
    async (customer: Customer) => {
      const shopifyId = decodeShopifyId(customer?.id, "Customer")
      if (!shopifyId) return false

      const userId = (
        await post({
          path: PATHS.USER_VALIDATE_SYNC,
          data: {
            platform: "shopify",
            extuid: shopifyId,
          },
        })
      )?.data?.regid

      if (!userId) return false

      setUserId(userId)
      await refreshWishList()
      return true
    },
    [decodeShopifyId, post, refreshWishList, setUserId]
  )

  const updateWishListVariant = useCallback(
    async (product: any, variant?: any, source = "") => {
      if (!(await removeFromWishList(product))) {
        return false
      }
      return await addToWishList(product, variant, source)
    },
    [addToWishList, removeFromWishList]
  )

  const shareUrl = useMemo(() => {
    return wishList ? `https://${store.url}${routes.SAVED}?sharedlist=${wishList?.lid}` : ""
  }, [routes.SAVED, store.url, wishList])

  const logout = useCallback(async () => {
    removeStorage(keys.wishListUserId)
    await refreshWishList()
  }, [keys.wishListUserId, refreshWishList, removeStorage])

  const shareWishList = useCallback(async () => {
    await post({
      path: PATHS.MARK_PUBLIC,
      data: {
        [LIST_ID]: wishList?.[LIST_ID],
      },
    })
  }, [post, wishList])

  useEffect(() => {
    if (customer) login(customer)
  }, [customer])

  useEffect(() => {
    const initWishLists = async () => {
      if (!wishList?.userinfo) {
        refreshWishList()
      }
      const sharedList = new URLSearchParams(location.search).get("sharedlist")
      if (sharedList) {
        setSharedWishList(await fetchSharedWishList(sharedList))
      }
      setFetched(true)
    }

    userIdHolder && initWishLists()
  }, [fetchSharedWishList, refreshWishList, setSharedWishList, userIdHolder, wishList?.userinfo])

  const contextValue = React.useMemo<ContextProps>(
    () => ({
      fetched,
      shareUrl,
      sharedWishList,
      setSharedWishList,
      addToWishList,
      addToWishListByIds,
      wishList,
      isInWishlist,
      removeFromWishList,
      removeAllFromWishList,
      shareWishList,
      updateWishListVariant,
      count,
      login,
      logout,
      fetchWishListProductsData,
    }),
    [
      fetched,
      shareUrl,
      sharedWishList,
      addToWishList,
      addToWishListByIds,
      wishList,
      isInWishlist,
      removeFromWishList,
      removeAllFromWishList,
      shareWishList,
      updateWishListVariant,
      count,
      login,
      logout,
      fetchWishListProductsData,
    ]
  )

  return <WishlistContext.Provider value={contextValue}>{children}</WishlistContext.Provider>
}

export const useWishlistContext = (): ContextProps => ({ ...React.useContext(WishlistContext) }) as ContextProps
