import React, { useState, useEffect, useCallback, PropsWithChildren } from "react"
import { useStorage } from "@hooks/useCore"
import config from "@root/config.js"

type ContextProps = StoreLocation & {
  loading: boolean
}

export const LocationContext = React.createContext<ContextProps | undefined>(undefined)

export const LocationProvider = ({ children }: PropsWithChildren) => {
  const { getStorage, setStorage } = useStorage()

  const {
    store,
    stores,
    settings: { keys },
  } = config as Config

  const selectedStore = stores[getStorage(keys.shopify)] || store

  const [loading, setLoading] = useState(false)

  const [settings, setSettings] = useState({
    store,
    country: getStorage(keys.country) || "",
    locations: Object.values(stores).map((store: any) => store.locationRegion),
    locating: !getStorage(keys.country)?.length,
    visitor: !selectedStore.locationCountries?.includes(getStorage(keys.country)),
  })

  const updateLocation = useCallback(
    async (countryCode: string, updateCountry = false) => {
      setLoading(true)
      const newStore: Store = Object.values(stores).find(store => store.locationCountries.includes(countryCode)) || selectedStore

      await setSettings(prevState => ({
        ...prevState,
        store,
        country: updateCountry ? countryCode : settings.country,
        locating: false,
        visitor: selectedStore.locationForceRegion ? !newStore.locationCountries?.includes(settings.country) : false,
      }))
      setLoading(false)
    },
    [stores, selectedStore, store, settings.country]
  )

  const contextValue = React.useMemo<ContextProps>(
    () => ({
      ...settings,
      updateLocation,
      loading,
    }),
    [settings, updateLocation, loading]
  )

  useEffect(() => {
    async function fetchLocation() {
      if (settings.store && settings.country) {
        setStorage(keys.country, settings.country)
        setStorage(keys.shopify, settings.store.shopifyShopDomain.split(".")[0])
      } else {
        try {
          const key = "country"
          const response = await fetch(store.locationLookupUrl)
          const data = await response.json()
          if (data[key]) updateLocation(data[key] || selectedStore.locationRegion, true)
        } catch (e) {
          updateLocation(selectedStore.locationRegion, true)
          console.warn("Error locating country", (e as Error).message)
        }
      }
    }
    fetchLocation()
  }, [selectedStore, store, settings, keys, setStorage, updateLocation])

  return <LocationContext.Provider value={contextValue}>{children}</LocationContext.Provider>
}

export const useLocationContext = (): ContextProps => ({ ...React.useContext(LocationContext) }) as ContextProps
