import React from "react"
import { IconButton as ChakraIconButton, IconButtonProps as ChakraIconButtonProps } from "@chakra-ui/react"

import Icon from "@components/Icon"
import { IconKeys } from "@components/Icon/Icon"

export interface IconButtonProps extends Pick<ChakraIconButtonProps, "aria-label"> {
  width: ChakraIconButtonProps["width"]
  height: ChakraIconButtonProps["height"]
  icon: IconKeys
  onClick?: VoidFunction
}

const IconButton = ({ width, height, icon, onClick, ...props }: IconButtonProps) => (
  <ChakraIconButton
    {...props}
    w="fit-content"
    variant="ghost"
    minW="unset"
    width={width}
    height={height}
    icon={<Icon name={icon} width={width} height={height} />}
    onClick={onClick}
  />
)

export default IconButton
