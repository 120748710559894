import React from "react"
import { LinkBox, LinkOverlay, Stack } from "@chakra-ui/react"
import Image from "@components/Image/Image"
import Link from "@components/Link"
import TextButton from "@components/TextButton"
import { useMedia } from "@hooks/useMedia"

const BenefitItem = ({ image, link, mobileTextLink, externalLink }: NormalisedBenefit) => {
  const { isBase } = useMedia()
  const linkTitle = isBase ? mobileTextLink || link?.title : link?.title
  const linkURL = externalLink ? externalLink : link?.url
  return (
    <LinkBox textAlign="center" maxW={["164px", "312px"]}>
      <LinkOverlay as={Link} to={linkURL}>
        <Stack alignItems="center">
          <Image {...image} w="94px" h="94px" objectFit="cover" />
          <TextButton icon="chevronRight">{linkTitle}</TextButton>
        </Stack>
      </LinkOverlay>
    </LinkBox>
  )
}

export default BenefitItem
