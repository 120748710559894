import React, { Dispatch, SetStateAction, useEffect, useCallback, useMemo, useState } from "react"
import { Box, BoxProps, Button, Flex, LinkBox, LinkOverlay, Stack, Text } from "@chakra-ui/react"
import Link from "@components/Link"
import { useAnalytics } from "@hooks/useAnalytics"
import { useCore } from "@hooks/useCore"
import { useRoutes } from "@hooks/useRoutes"
import { useConfigContext } from "@providers/config"
import ProductPriceRange from "@components/Product/ProductPriceRange"
import ProductBadges from "./ProductBadges"
import ProductPrice from "./ProductPrice"
import ProductWishlist from "./ProductWishlist"
import ProductCardPortait from "./ProductCardPortrait"
import { useShopify } from "@hooks/useShopify"
import ProductQuickViewButton from "./ProductQuickViewButton"
import { colors } from "@app/theme/colors"
import Icon from "@components/Icon"
import type { CollectionProps } from "@root/types/global"
import ProductReviewsBadge from "./ProductReviewsBadge"
import ProductShopTheLookProductCardImages from "./ProductShopTheLookProductCardImages"
import useProductBadges from "@hooks/useProductBadges"

export interface ProductCardProps {
  item: any
  itemRef?: React.RefObject<HTMLDivElement>
  location: any
  fullSize?: boolean
  collection?: CollectionProps | GatsbyTypes.SanityCollection
  wishlistSize?: "sm" | "lg"
  isStl?: boolean
  showBadges?: boolean
  showPlus?: boolean
  showBadgePlaceholder?: boolean
  onQuickView?: Dispatch<SetStateAction<BundleProduct | null>>
  isInstantSearch?: boolean
  handleTrackingClick?: VoidFunction
}

const ProductCard: React.FC<ProductCardProps> = ({
  item,
  itemRef,
  location,
  collection = null,
  fullSize = false,
  wishlistSize = "sm",
  isStl = false,
  onQuickView,
  isInstantSearch,
  showPlus = false,
  showBadgePlaceholder = false,
  handleTrackingClick,
}) => {
  const {
    settings: { routes },
  } = useConfigContext()
  const {
    helpers: { getUrlParameter, isBrowser },
  } = useCore()

  const { urlResolver } = useRoutes()
  const { productNormaliser, getProductPriceRanges, formatMoney } = useShopify()
  const [minPrice, setMinPrice] = useState<string | null>(null)
  const [showQuickView, setShowQuickView] = useState<boolean>(false)
  const { trackProductView } = useAnalytics()

  const product = productNormaliser(item)
  const link = urlResolver(product, routes.PRODUCT)
  const isSearch = isBrowser && location?.pathname.includes(routes.SEARCH)

  const iconButtonProps: BoxProps = {
    pos: "absolute",
    top: "10px",
    right: "10px",
    zIndex: 5,
  }
  const pageParam = useMemo(() => getUrlParameter("page"), [getUrlParameter])
  const searchParam = useMemo(() => getUrlParameter("q"), [getUrlParameter])
  const badges = useProductBadges(product?.tags)


  const previousUrl = useMemo(
    () =>
      collection
        ? `${collection?.url}/${collection?.shopify?.handle}${pageParam ? `?page=${pageParam}` : ""}`
        : isSearch && pageParam
        ? `${routes?.SEARCH}?q=${searchParam}&page=${pageParam}`
        : isSearch
        ? `${routes?.SEARCH}?q=${searchParam}`
        : null,
    [collection, isSearch, pageParam, routes?.SEARCH, searchParam]
  )

  // For product recommandations
  const variant = useMemo(
    () => (item?.variants?.edges ? item?.variants?.edges?.find((variant: any) => variant?.node?.availableForSale) : item?.variants?.[0]),
    [item?.variants]
  )

  const handleQuickViewClick = useCallback(
    e => {
      e.preventDefault()
      trackProductView(item, item?.variants?.[0], false)
      onQuickView?.(product)
    },
    [item, onQuickView, product, trackProductView]
  )

  const getStlPrices = async () => {
    const handles = item?.tags?.filter((tag: string) => tag.includes("stl:"))?.map(tag => tag?.split(":")?.[1])
    const prices = await getProductPriceRanges({ handles })
    const minPrices = prices?.map((price: any) => price?.priceRange?.minVariantPrice?.amount)
    const minPrice = parseFloat(Math.min(...minPrices).toFixed(2))
    setMinPrice(formatMoney(minPrice, "AUD"))
  }

  useEffect(() => {
    if (isStl && !minPrice) getStlPrices()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStl])
  const imageUrl = item.image || (item.featuredImage && item.featuredImage.originalSrc)
  return (
    <Box
      className="product-card__wishlist-anchor"
      ref={itemRef}
      pos="relative"
      w={fullSize ? "unset" : "205px"}
      cursor="pointer"
      onClick={handleTrackingClick}
    >
      {!isStl && (
        <ProductWishlist product={product} location={location} size={wishlistSize} {...iconButtonProps} display={["none", "block"]} />
      )}
      {showPlus && (
        <Flex
          alignItems="center"
          justifyContent="center"
          position="absolute"
          top={1}
          right={1}
          width={"38px"}
          height={"38px"}
          bg={colors.brand.beige.dark}
          borderRadius="999px"
          zIndex={1}
        >
          <Icon name="plus" size="18px" color="black"></Icon>
        </Flex>
      )}
      {/* quick view mobile */}
      {onQuickView && !isStl && (
        <Box onClick={handleQuickViewClick}>
          <ProductQuickViewButton {...iconButtonProps} display={["block", "none"]} />
        </Box>
      )}
      {/* quick view desktop */}
      <LinkBox height={`100%`} display={`flex`} flexDirection={`column`} gap={`8px`}>
        <Box position={`relative`} zIndex={2} onMouseEnter={() => setShowQuickView(true)} onMouseLeave={() => setShowQuickView(false)}>
          <LinkOverlay
            as={Link}
            to={isInstantSearch && product.url ? product.url : link?.url}
            state={
              collection
                ? { breadcrumbs: [{ title: collection?.title, url: previousUrl }] }
                : isSearch
                ? { breadcrumbs: [{ title: "Search", url: previousUrl }] }
                : undefined
            }
          >
            {isStl ? (
              <ProductShopTheLookProductCardImages
                item={product}
                onQuickView={product => onQuickView(product)}
                image={imageUrl}
                stlProductCard={isStl}
              />
            ) : (
              <ProductCardPortait item={product} fullSize={fullSize} showHoverImage={showQuickView} />
            )}
            {/* {!isStl && (
              <Box>
                <ProductReviewsBadge product={product} />
              </Box>
            )} */}
            {onQuickView && showQuickView && !isStl && (
              <Button
                type={`button`}
                display={[`none`, `block`]}
                onClick={handleQuickViewClick}
                width={`calc(100% - 50px)`}
                size={`md`}
                variant={`outline`}
                bg="white"
                position={`absolute !important`}
                bottom={`25px`}
                left={`25px`}
                pointerEvents={`all`}
              >
                Quick View
              </Button>
            )}
          </LinkOverlay>
        </Box>
        <ProductBadges tags={product?.tags} showPlaceholder={showBadgePlaceholder} />
        <Stack spacing={1} height={badges.length ? '100%' :`auto`} marginTop={badges.length ? "unset" : 'auto'} flexDirection={`column`} gap={`0px`} justifyContent={'flex-end'}>
          <LinkOverlay
            marginTop={`unset!important`}
            state={
              collection
                ? { breadcrumbs: [{ title: collection?.title, url: previousUrl }] }
                : isSearch
                ? { breadcrumbs: [{ title: "Search", url: previousUrl }] }
                : undefined
            }
            as={Link}
            to={isInstantSearch ? product.url : link?.url}
            title={product?.title}
            height={'100%'}
            display={'flex'}
            flexFlow={'column'}
            gap={`5px`}
            justifyContent={'flex-end'}
          >
            <Text
              size="lg"
              fontWeight="semibold"
              h="auto"
              overflow="hidden"
              textOverflow="ellipsis"
              marginTop={`unset`}
              paddingTop={`unset`}
              minHeight={`54px`}
            >
              {product?.title}
            </Text>

            <Flex h='auto' marginTop={`auto!important`} flexFlow={`column`} gap={`8px`}>  
              {isStl ? (
                <Text fontWeight={600}>From {minPrice}</Text>
              ) : (
                <>
                  {variant ? (
                    <ProductPrice variant={variant?.node || variant} direction={"row"} />
                  ) : (
                    <ProductPriceRange product={product} color="black" />
                  )}
                </>
              )}
              {!isStl && (
                <Box>
                  <ProductReviewsBadge product={product} />
                </Box>
              )}
            </Flex>
          </LinkOverlay>

          
        </Stack>
      </LinkBox>
    </Box>
  )
}

export default React.memo(ProductCard)
