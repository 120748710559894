import { useEffect, useMemo, useState } from "react"
import { useShopify } from "@hooks/useShopify"

const useFeaturedProducts = (collection?: GatsbyTypes.SanityCollection, products?: GatsbyTypes.SanityProduct[]) => {
  const { getCollection, getHandle, getProducts } = useShopify()
  const [items, setItems] = useState<any[]>([])

  const fetchItems = useMemo(
    () => async () => {
      const items = getHandle(collection)
        ? await getCollection({ firstProducts: 10, firstImages: 10, firstVariants: 50, handle: getHandle(collection) })
        : products?.length > 0
        ? await getProducts({ firstImages: 10, firstVariants: 50, handles: products?.map(item => getHandle(item)) })
        : []

      setItems(items || [])
    },
    [collection, products, getCollection, getProducts, getHandle]
  )

  const normalisedProducts =
    items &&
    items
      ?.map?.((item: any) => {
        const product = products?.find(product => product?.shopify?.handle === item.handle)
        return { product: item, sanityProduct: product }
      })
      ?.filter?.(product => product?.sanityProduct)

  // intentionally only run once at first render
  useEffect(() => {
    fetchItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return normalisedProducts
}

export default useFeaturedProducts
