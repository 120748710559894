import { useCallback } from "react"

import { useImage } from "@hooks/useImage"
import { useRoutes } from "@hooks/useRoutes"
import { format } from "date-fns"

type LearnPageRaw = GatsbyTypes.SanityArticle[] | GatsbyTypes.SanityArticleConnection
type PageFlexibleRaw = GatsbyTypes.SanityPageFlexible[] | GatsbyTypes.SanityPageFlexibleConnection
type CollectionRaw = GatsbyTypes.SanityCollection[] | GatsbyTypes.SanityCollectionConnection

const useSearchNormaliser = () => {
  const { urlResolver } = useRoutes()
  const { getGatsbyImage } = useImage()

  const normaliseLearnPages = useCallback(
    (raw?: LearnPageRaw) => {
      if (!raw) return []
      const nodes = "edges" in raw ? raw.edges.map(({ node }) => node) : raw

      return nodes.map(page => ({
        key: page._key,
        title: page.title,
        description: page.description,
        link: urlResolver(page)?.url,
        image: getGatsbyImage(page.image),
        categories: page.categories?.map(category => ({ handle: category?.handle?.current, title: category?.title })),
        attributes: {
          readDuration: page.attributes?.readDuration,
          publishedAt: page?.attributes?.publishedAt && format(new Date(page?.attributes?.publishedAt), "dd MMMM yyyy"),
        },
      }))
    },
    [getGatsbyImage, urlResolver]
  )

  const normalisePages = useCallback(
    (raw?: PageFlexibleRaw) => {
      if (!raw) return []
      const nodes = "edges" in raw ? raw.edges.map(({ node }) => node) : raw

      return nodes.map(page => ({
        key: page._key,
        title: page.title,
        description: page.description,
        link: urlResolver(page)?.url,
      }))
    },
    [urlResolver]
  )

  const normaliseCollections = useCallback((raw?: CollectionRaw) => {
    if (!raw) return []
    const nodes = "edges" in raw ? raw.edges.map(({ node }) => node) : raw

    return nodes.map(collection => ({
      key: collection._key,
      title: collection.title,
    }))
  }, [])

  return { normalisePages, normaliseLearnPages, normaliseCollections }
}

export default useSearchNormaliser
