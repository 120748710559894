import React, { useState, useCallback } from "react"
import { Box } from "@chakra-ui/react"
import { useStaticQuery, graphql } from "gatsby"

import { useStorage } from "@hooks/useCore"
import { useConfigContext } from "@providers/config"

export const useAnnouncement = () => {
  const { getStorage, setStorage } = useStorage()
  const {
    settings: { keys },
  } = useConfigContext()

  const [show, setShow] = useState(getStorage(keys.announcement) !== "hidden")

  const { data } = useStaticQuery<GatsbyTypes.StaticAnnouncementsQuery>(graphql`
    query StaticAnnouncements {
      data: sanitySettingAnnouncement {
        duration
        announcements {
          title
          link {
            link
            external
          }
          showCountdown
          countdownEndDate(fromNow: false, formatString: "", difference: "", locale: "")
          fallbackTitle
        }
      }
    }
  `)

  const items = data?.announcements || []
  const duration = (data?.duration && data?.duration * 1000) || 5000

  const parseTitle = (title: string) => {
    const regex = /(#.*?#)/g
    const textParts = title?.split(regex)

    return textParts?.map((part, index) => {
      if (!part) return null
      if (regex.test(part)) {
        const extractedTitle = part.slice(1, part.length - 1)
        return (
          <Box key={index} as="span" color="brand.pink" verticalAlign="text-bottom">
            {extractedTitle}
          </Box>
        )
      }

      return (
        <Box whiteSpace={"nowrap"} key={index} as="span" verticalAlign="text-bottom">
          {part}
        </Box>
      )
    })
  }

  const hide = useCallback(() => {
    setStorage(keys.announcement, "hidden")
    setShow(false)
  }, [setShow, setStorage, keys])

  return {
    data,
    items,
    show,
    hide,
    parseTitle,
    duration,
  }
}
