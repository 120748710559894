module.exports = {
  store: require(`./config.${process.env.GATSBY_ACTIVE_STORE || "purebaby-au"}.js`),
  stores: {
    "purebaby-au": require("./config.purebaby-au.js"),
    "purebaby-stage": require("./config.purebaby-stage.js"),
  },
  theme: {
    color: "#000000",
    background: "#FFFFFF",
    display: "minimal-ui",
    icon: "static/images/icon.jpg",
    logo: "static/images/logo.svg",
  },
  settings: {
    product: {
      sizeOptionName: "Size",
      colourOptionName: "Color",
    },
    customer: {
      tagPrefix: "preferences",
    },
    keys: {
      announcement: "announcement",
      country: "country",
      checkout: "checkout",
      customer: "customer",
      discounts: "discounts",
      location: "location",
      market: "market",
      newsletter: "subscribe",
      recentlyViewed: "purebaby:recentlyViewed",
      maintenance: "maintenance",
      password: "password",
      rewards: "rewards",
      shopify: "shopify",
      store: "store",
      wishListUserId: "wishlist-user-id",
      giftRegistryUserId: "giftregistry-user-id",
    },
    params: {
      checkout: "checkout_url",
      continue: "return",
      customer: "key",
      giftcard: "redeem",
      variant: "variant",
      preview: "preview",
    },
    routes: {
      HOMEPAGE: "/",
      FLEXIBLE: "",
      GENERIC: "",
      PAGE: "",
      ERROR: "/404",
      CART: "/cart",
      CONTACT: "/pages/contact",
      CHECKOUT: "/account/login/loading",
      SEARCH: "/search",
      PASSWORD: "/password",
      SAVED: "/wishlist",
      GIFTCARD: "/giftcard",
      LOGIN: "/account/login",
      REGISTER: "/account/register",
      FORGOT: "/account/forgot",
      RESET: "/account/reset",
      ACTIVATE: "/account/activate",
      INVALID: "/account/invalid_token",
      DASHBOARD: "/account",
      ORDERS: "/account/orders",
      REWARDS: "/pages/purelove-rewards",
      DETAILS: "/account/details",
      ADDRESSES: "/account/addresses",
      PREFERENCES: "/account/preferences",
      GIFTREGISTRY: "/account/gift-registry",
      GIFTREGISTRY_CREATE: "/account/gift-registry/create",
      GIFTREGISTRY_VIEW: "/account/gift-registry/view",
      SHAREGIFTREGISTRY_VIEW: "/gift-registry/view",
      WISHLIST: "/account/wishlist",
      PRODUCT: "/products",
      COLLECTION: "/collections",
      ARTICLE: "/blogs/journal",
      CATEGORY: "/blogs/category",
      JOURNAL: "/blogs/journal",
      STORE: "/pages/stores",
      SHOPTHELOOK: "/pages/shop-the-look",
      LOOK: "/pages/shop-the-look",
      CHECKLIST: "/pages/checklists",
    },
    // Starting from Reactify v5.31.0, the capability to specify which product fields to include or exclude has been introduced.
    // Define the fields you want to include or exclude for the Product Listing Page, Search Results Page and Instant Search section below.
    search: {
      searchIncludeFields: [
        "callout",
        "collections.title",
        "discount_amount",
        "handle",
        "id",
        "images",
        "link",
        "metafields",
        "presentment_price_ranges",
        "product_type",
        "storefrontId",
        "tags",
        "title",
        "variants",
        "vendor",
        "url",
      ],
      searchExcludeFields: ["link.type"],
    },
    defaultRedirects: [],
    clientPaths: ["/wishlist/*", "/account/reset/*", "/account/activate/*", "/account/orders/*"],
    sitemapExclusions: [
      "/offline-plugin-app-shell-fallback",
      "/dev-404-page",
      "/404",
      "/404.html",
      "/password",
      "/preview",
      "/account",
      "/account/login",
      "/account/forgot",
      "/account/activate",
      "/account/reset",
      "/account/orders",
      "/account/addresses",
      "/account/preferences",
      "/account/wishlist",
      "/wishlist",
      "/search",
      "/cart",
      "/collections",
    ],
  },
}
