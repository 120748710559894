import { useContext } from "react"
import { SanitySearchContext } from "@providers/sanitySearch"

const useSanitySearch = () => {
  const context = useContext(SanitySearchContext)

  if (!context) throw Error("Attempting to consume useSanitySearch hook outside of SanitySearchContext")

  return context
}

export default useSanitySearch
