import React from "react"
import { Flex, Skeleton, SkeletonText, Stack } from "@chakra-ui/react"

const LoadingResultsDesktop = () => (
  <Stack spacing={2.5} overflow="hidden">
    <SkeletonText noOfLines={2} />
    <Flex gap={2} w="full">
      {[...Array(4)].map((_, i) => (
        <Stack key={i}>
          <Skeleton w="205px" h="266px" />
          <SkeletonText />
        </Stack>
      ))}
    </Flex>
  </Stack>
)

export default LoadingResultsDesktop
