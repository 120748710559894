import React from "react"
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerOverlay,
  DrawerContent,
  Stack,
  StackDivider,
  HStack,
  Heading,
  Box,
  Flex,
} from "@chakra-ui/react"
import { Swiper, SwiperSlide } from "swiper/react"
import { useConfigContext } from "@providers/config"
import { useAppContext } from "@providers/app"
import { useNavigation } from "@hooks/useNavigation"
import Stores from "@components/Stores"
import ContactUs from "@components/ContactUs"
import { useMobileMenu } from "@hooks/useMobileMenu"
import NavigationHeaderMobileFooter from "./NavigationHeaderMobileFooter"
import NavigationHeaderMobileItem from "./NavigationHeaderMobileItem"
import IconButton from "@components/IconButton"
import TileLink from "@components/TileLink"

const NavigationHeaderMobile = () => {
  const { header: items } = useNavigation()
  const { state, dispatch, activeMobileAccountMenu, setActiveMobileAccountMenu } = useAppContext()
  const { currentMenu, hasPrevious, onMenuPush, onMenuPop, onReset } = useMobileMenu(items)
  const {
    settings: { routes },
  } = useConfigContext()

  const onToggle = React.useCallback(() => {
    onReset()
    setActiveMobileAccountMenu(false)
    dispatch({
      type: "menu",
      payload: !state.activeMenu,
    })
  }, [dispatch, onReset, state.activeMenu, setActiveMobileAccountMenu])

  const accountDetails = [
    { title: "Dashboard", url: `${routes.DASHBOARD}`, type: "link" },
    { title: "My Details", url: `${routes.DETAILS}`, type: "link" },
    { title: "Orders", url: `${routes.ORDERS}`, type: "link" },
    { title: "Rewards", url: `${routes.REWARDS}`, type: "link" },
    { title: "Wishlist", url: `${routes.WISHLIST}`, type: "link" },
    { title: "Gift Registry", url: `${routes.GIFTREGISTRY}`, type: "link" },
  ]

  // Partitioning links and tiles in order to extract tiles to build carousel
  const [links, tiles] = currentMenu.items.reduce(
    (acc: any[][], next: any) => {
      const isTile = next.type === "tile"
      acc[isTile ? 1 : 0].push(next)
      return acc
    },
    [[], []]
  )

  return (
    <Drawer isOpen={state.activeMenu} onClose={onToggle} placement="left" size="xs">
      <DrawerOverlay />
      <DrawerContent overflowY="scroll">
        {!activeMobileAccountMenu ? (
          <Stack divider={<StackDivider my={"16px !important"} />}>
            <Box px={2.5} pt={2} pb={0} minH={"47px"} d={"flex"} alignItems={"center"} justifyContent={"end"}>
              {hasPrevious ? (
                <HStack justifyContent="space-between" w={"100%"}>
                  <IconButton icon="chevronLeft" width="15px" height="15px" aria-label="previous menu" onClick={onMenuPop} />
                  <Heading size="h3">{currentMenu.title}</Heading>
                  <IconButton icon="close" width="15px" height="15px" aria-label="close" onClick={onToggle} />
                </HStack>
              ) : (
                <Flex justifyContent="flex-end">
                  <IconButton icon="close" width="15px" height="15px" aria-label="close" onClick={onToggle} />
                </Flex>
              )}
            </Box>
            <DrawerBody px={2.5} minH={tiles.length ? "500px" : "unset"}>
              <Stack spacing={3}>
                {links.map((item: MenuItemMobile) => (
                  <NavigationHeaderMobileItem primary={!hasPrevious} item={item} onMenuNext={() => onMenuPush(item.key)} key={item.key} />
                ))}
                {tiles.length && (
                  <Swiper lazy spaceBetween={10} slidesPerView="auto" style={{ width: "100%" }}>
                    {tiles.map(tile => (
                      <SwiperSlide key={tile.title} style={{ width: "auto" }}>
                        <TileLink item={tile} w="120px" h="150px" />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                )}
              </Stack>
            </DrawerBody>
            {!hasPrevious && (
              <Stack divider={<StackDivider my={"16px !important"} />}>
                <DrawerFooter px={2.5} py={1.5} justifyContent="space-between">
                  <NavigationHeaderMobileFooter />
                </DrawerFooter>
                <Stack p={2} spacing={2.5}>
                  <Stores />
                  <ContactUs />
                </Stack>
              </Stack>
            )}
          </Stack>
        ) : (
          // Used to create mobile menu
          <Stack divider={<StackDivider />}>
            <Box px={2.5} pt={2} pb={1}>
              <HStack justifyContent="space-between">
                <IconButton
                  icon="chevronLeft"
                  width="24px"
                  height="24px"
                  aria-label="previous menu"
                  onClick={() => setActiveMobileAccountMenu(false)}
                />
                <Heading size="h3">My Account</Heading>
                <IconButton icon="close" width="15px" height="15px" aria-label="close" onClick={onToggle} />
              </HStack>
            </Box>
            <DrawerBody px={2.5} minH="500px">
              <Stack spacing={3}>
                {accountDetails.map(item => {
                  // if (item?.title != "Gift Registry") {
                  //   return <NavigationHeaderMobileItem primary={hasPrevious} item={item} key={item.title} />
                  // }
                  return <NavigationHeaderMobileItem primary={hasPrevious} item={item} key={item.title} />
                })}
              </Stack>
            </DrawerBody>
            {hasPrevious && (
              <>
                <DrawerFooter p={2.5} justifyContent="space-between">
                  <NavigationHeaderMobileFooter />
                </DrawerFooter>
                <Stack p={2} spacing={3}>
                  <Stores />
                  <ContactUs />
                </Stack>
              </>
            )}
          </Stack>
        )}
      </DrawerContent>
    </Drawer>
  )
}

export default React.memo(NavigationHeaderMobile)
