export const Menu = {
  variants: {
    generic: {
      list: {
        borderRadius: 0,
        py: 2,
      },
      item: {
        pt: 0,
        pb: 2,
        _last: {
          pb: 0,
        },
      },
    },
  },
}
