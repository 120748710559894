import React from "react"
import { Button, HStack, Text } from "@chakra-ui/react"
import { useCustomerContext } from "@providers/customer"
import { useAppContext } from "@providers/app"
import Icon from "@components/Icon/Icon"

export interface AccountDrawerProps {
  activeAccountDrawer: boolean
}

const NavigationHeaderMobileFooter = () => {
  const { setActiveMobileAccountMenu, setAccountDrawer, dispatch } = useAppContext()

  const { customer } = useCustomerContext()

  const onMobileLogin = accountDrawer => {
    dispatch({
      type: "accountDrawer",
      payload: { activeAccountDrawer: true },
    }),
      setAccountDrawer(accountDrawer)
  }

  return (
    <HStack w="full">
      {customer ? (
        <>
          <HStack onClick={() => setActiveMobileAccountMenu(true)} gap={1} justifyContent="space-between" w="full">
            <HStack>
              <Icon name={customer ? "accountSelected" : "account"} size="18px" /> <Text fontSize={"large"}>My Account</Text>
            </HStack>
            <Icon name="chevronRight" size="24px" color="text.icon" />
          </HStack>
        </>
      ) : (
        <>
          <Button onClick={() => onMobileLogin("login")} variant="blue" w="full">
            Login
          </Button>
          <Button onClick={() => onMobileLogin("register")} variant="outline" w="full">
            Sign Up
          </Button>
        </>
      )}
    </HStack>
  )
}

export default React.memo(NavigationHeaderMobileFooter)
