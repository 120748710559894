import { AspectRatio, Grid, Skeleton, SkeletonText, Stack } from "@chakra-ui/react"
import React from "react"

const LoadingResultsMobile = () => (
  <Stack spacing={2.5}>
    <SkeletonText noOfLines={2} />
    <Grid templateColumns="repeat(2, 1fr)" gap={2}>
      {[...Array(8)].map((_, i) => (
        <Stack key={i}>
          <AspectRatio key={i} ratio={82 / 107}>
            <Skeleton />
          </AspectRatio>
          <SkeletonText />
        </Stack>
      ))}
    </Grid>
  </Stack>
)
export default LoadingResultsMobile
