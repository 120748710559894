import { useMemo } from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useRoutes } from "@hooks/useRoutes"

const useBenefits = () => {
  const { benefits } = useStaticQuery<GatsbyTypes.StaticSettingBenefitsQuery>(graphql`
    query StaticSettingBenefits {
      benefits: sanitySettingBenefits {
        items {
          _key
          image {
            alt
            asset {
              url
            }
          }
          link: _rawLink(resolveReferences: { maxDepth: 2 })
          mobileTextLink
          externalLink
        }
      }
    }
  `)

  const { urlResolver } = useRoutes()

  const normalisedBenefits = useMemo(
    () =>
      benefits?.items?.map(
        item =>
          ({
            key: item?._key,
            image: {
              alt: item?.image?.alt,
              src: item?.image?.asset?.url,
            },
            link: urlResolver(item?.link),
            mobileTextLink: item?.mobileTextLink,
            externalLink: item?.externalLink,
          }) as NormalisedBenefit
      ),
    [benefits, urlResolver]
  )

  return normalisedBenefits
}

export default useBenefits
