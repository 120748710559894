import React from "react"
import { HStack, Text, TextProps } from "@chakra-ui/react"
import { useShopifyPriceRange } from "@hooks/useShopify"

export interface ProductPriceRangeProps extends TextProps {
  product: any
  loading?: boolean
}

const ProductPriceRange = ({ product, loading, ...props }: ProductPriceRangeProps) => {
  const { formattedPriceRange, formattedSalePriceRange, onSale } = useShopifyPriceRange(product)

  return !loading ? (
    <HStack>
      {onSale && (
        <Text size="md" {...props} color="text.secondary" as="s">
          {formattedSalePriceRange}
        </Text>
      )}
      <Text size="md" {...props} fontWeight={onSale ? "semibold" : "regular"} color={onSale ? "brand.sale" : "text.primary"}>
        {formattedPriceRange}
      </Text>
    </HStack>
  ) : null
}

export default React.memo(ProductPriceRange)
