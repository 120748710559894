import { gql } from "@apollo/client"

const CHECKOUT_LINE_ITEMS_REPLACE = gql`
  mutation checkoutLineItemsReplace($checkoutId: ID!, $lineItems: [CheckoutLineItemInput!]!, $countryCode: CountryCode!)
  @inContext(country: $countryCode) {
    checkoutLineItemsReplace(lineItems: $lineItems, checkoutId: $checkoutId) {
      checkout {
        ...CheckoutFragment
        __typename
      }
      userErrors {
        code
        field
        message
        __typename
      }
      __typename
    }
  }

  fragment CheckoutFragment on Checkout {
    id
    ready
    requiresShipping
    note
    buyerIdentity {
      countryCode
      __typename
    }
    paymentDue {
      amount
      currencyCode
      __typename
    }
    webUrl
    orderStatusUrl
    taxExempt
    taxesIncluded
    currencyCode
    totalTax {
      amount
      currencyCode
      __typename
    }
    lineItemsSubtotalPrice {
      amount
      currencyCode
      __typename
    }
    subtotalPrice {
      amount
      currencyCode
      __typename
    }
    totalPriceV2 {
      amount
      currencyCode
      __typename
    }
    completedAt
    createdAt
    updatedAt
    email
    appliedGiftCards {
      ...AppliedGiftCardFragment
      __typename
    }
    shippingAddress {
      ...MailingAddressFragment
      __typename
    }
    shippingLine {
      handle
      priceV2 {
        amount
        currencyCode
        __typename
      }
      title
      __typename
    }
    customAttributes {
      key
      value
      __typename
    }
    order {
      id
      processedAt
      orderNumber
      subtotalPrice {
        amount
        currencyCode
        __typename
      }
      totalShippingPrice {
        amount
        currencyCode
        __typename
      }
      totalTaxV2 {
        amount
        currencyCode
        __typename
      }
      totalPriceV2 {
        amount
        currencyCode
        __typename
      }
      currencyCode
      totalRefunded {
        amount
        currencyCode
        __typename
      }
      customerUrl
      shippingAddress {
        ...MailingAddressFragment
        __typename
      }
      lineItems(first: 250) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          __typename
        }
        edges {
          cursor
          node {
            title
            variant {
              ...VariantWithProductFragment
              __typename
            }
            quantity
            customAttributes {
              key
              value
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
    lineItems(first: 250) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        __typename
      }
      edges {
        cursor
        node {
          id
          title
          variant {
            ...VariantWithProductFragment
            __typename
          }
          quantity
          customAttributes {
            key
            value
            __typename
          }
          discountAllocations {
            allocatedAmount {
              amount
              currencyCode
              __typename
            }
            discountApplication {
              targetSelection
              targetType
            }
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
    discountApplications(first: 10) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        __typename
      }
      edges {
        cursor
        node {
          ... on DiscountCodeApplication {
            code
            __typename
          }
          allocationMethod
          targetSelection
          targetType
          value {
            ... on PricingPercentageValue {
              percentage
              __typename
            }
            ... on MoneyV2 {
              amount
              currencyCode
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }

  fragment AppliedGiftCardFragment on AppliedGiftCard {
    amountUsedV2 {
      amount
      currencyCode
      __typename
    }
    balanceV2 {
      amount
      currencyCode
      __typename
    }
    presentmentAmountUsed {
      amount
      currencyCode
      __typename
    }
    id
    lastCharacters
    __typename
  }

  fragment MailingAddressFragment on MailingAddress {
    id
    address1
    address2
    city
    company
    country
    firstName
    formatted
    lastName
    latitude
    longitude
    phone
    province
    zip
    name
    countryCode: countryCodeV2
    provinceCode
    __typename
  }

  fragment VariantWithProductFragment on ProductVariant {
    ...VariantFragment
    product {
      description
      handle
      id
      productType
      tags
      vendor
      options {
        name
        values
      }
      variants(first: 250) {
        edges {
          node {
            id
            selectedOptions {
              name
              value
              __typename
            }
          }
        }
      }
      __typename
    }
    __typename
  }

  fragment VariantFragment on ProductVariant {
    id
    sku
    title
    weight
    weightUnit
    availableForSale
    currentlyNotInStock
    quantityAvailable
    requiresShipping
    selectedOptions {
      name
      value
      __typename
    }
    image {
      ...ImageFragment
      __typename
    }
    ...PriceFragment
    __typename
  }

  fragment ImageFragment on Image {
    id
    altText
    originalSrc
    transformedSrc
    __typename
  }

  fragment PriceFragment on ProductVariant {
    priceV2 {
      amount
      currencyCode
      __typename
    }
    compareAtPriceV2 {
      amount
      currencyCode
      __typename
    }
    __typename
  }
`

export default CHECKOUT_LINE_ITEMS_REPLACE
