import { Box, Button, chakra, Grid, HStack, Stack, Text } from "@chakra-ui/react"

export const MenuContainer = chakra(Box, {
  baseStyle: {
    left: 0,
    mt: 2,
    position: "absolute",
    zIndex: 10,
    w: "full",
    height: "100vh",
  },
})

export const MenuOverlay = chakra(Box, {
  baseStyle: {
    left: 0,
    position: "absolute",
    zIndex: 10,
    w: "full",
    height: "100vh",
    bottom: 0,
    bg: "rgba(0, 0, 0, 0.6)",
  },
})

export const SubNavigationMenu = chakra(Box, {
  baseStyle: {
    borderTop: "1px",
    borderColor: "brand.divider",
    left: 0,
    position: "absolute",
    zIndex: 11,
    textAlign: "left",
    w: "full",
    pointerEvents: "initial",
  },
})

export const LeftColumn = chakra(Stack, {
  baseStyle: {
    direction: "column",
    p: 4,
    pl: 6,
    bg: "white",
    height: "421px",
  },
})

export const RightColumn = chakra(HStack, {
  baseStyle: {
    columnGap: 4,
    p: 4,
    pr: 6,
    bg: "brand.beige.light",
    height: "421px",
  },
})

export const SubMenuTitle = chakra(Text, {
  baseStyle: {
    mb: 2,
    fontWeight: "semibold",
    color: "text.secondary",
    textTransform: "uppercase",
  },
})

export const LinkGrid = chakra(Grid, {
  baseStyle: {
    gridTemplateRows: "repeat(8, 1fr)",
    gridAutoFlow: "column",
    rowGap: 2,
    columnGap: 6,
  },
})

export const TileButton = chakra(Button, {
  baseStyle: {
    position: "absolute !important",
    top: "77.5%",
    left: "50%",
    transform: "translateX(-50%)",
  },
})
