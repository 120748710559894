import { useEffect } from "react"
import { useCore } from "./useCore"

export const useYotpoScript = (indentifier: any, isProduct = false) => {
  const {
    helpers: { isBrowser, isDomReady },
  } = useCore()

  useEffect(() => {
    let yotpoIsReady = false
    let timeoutId: NodeJS.Timeout

    function checkForYotpo() {
      timeoutId = setTimeout(function () {
        if (typeof window.yotpoWidgetsContainer !== "undefined") {
          yotpoIsReady = true
          try {
            window.yotpoWidgetsContainer?.initWidgets()
          } catch (error) {
            yotpoIsReady = false
          }
        }
        if (typeof window.yotpo !== "undefined") {
          if (window.yotpo?.initialized === true) {
            window.yotpo.refreshWidgets()
          }
        }
        if (yotpoIsReady === false) checkForYotpo()
      }, 1000)
    }

    if (isBrowser) checkForYotpo() // start the checking process

    return () => clearTimeout(timeoutId) // cleanup
  }, [indentifier, isDomReady, isProduct])
}
