import { colors } from "../colors"

export const Textarea = {
  baseStyle: {
    boxShadow: "none",
    borderRadius: "xxs",
    color: colors.text.primary,
    fontSize: "md",
    p: 2,
    h: "136px",
    resize: "none",
    _disabled: {
      _placeholder: {
        color: colors.brand.border,
      },
    },
    _placeholder: {
      color: colors.text.secondary,
    },
    _focus: {
      boxShadow: "none",
      outline: "none",
    },
  },
  variants: {
    outline: {
      bg: "transparent",
      borderColor: colors.text.secondary,
      _disabled: {
        color: colors.brand.border,
        opacity: 1,
      },
      _invalid: {
        borderColor: colors.brand.sale,
        boxShadow: "none",
      },
    },
  },
  defaultProps: {
    size: "base",
    variant: "outline",
  },
}
