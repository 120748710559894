export const Checkbox = {
  baseStyle: {
    control: {
      borderRadius: "2px",
      width: "24px",
      borderWidth: "1px",
      borderColor: "black",
      minHeight: "24px",
      _checked: {
        _hover: {
          bg: "brand.navy.light",
        },
        bg: "brand.navy.light",
      },
    },
  },
}
