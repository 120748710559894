module.exports = {
  id: "purebaby",
  title: "Purebaby",
  description:
    "When I couldn’t find a range to meet all my needs — fashionable, organic, functional and most importantly affordable — I was inspired to create it myself.",
  url: "purebaby.com.au",

  locationRegion: "AU",
  locationCountries: "AU",
  locationForceRegion: true,
  locationLookupUrl: "https://ipapi.co/json/",

  shopifyPlus: true,
  shopifyApiVersion: "2023-04",
  shopifyShopDomain: "purebaby-au.myshopify.com",
  shopifyCheckoutUrl: "checkout.purebaby.com.au",
  shopifyStorefrontToken: "d6d2404b7ded07f809182d3a14c7ef21",
  shopifyAdminToken: "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",
  shopifyMultiPassToken: "8213730446fedc10cae09d064904c2ac",

  sanityProjectId: "kaeaimuz",
  sanityDataset: "production",
  sanityApiVersion: "2022-02-24",
  sanityToken:
    "skwy5tCO4pDJNSldUm52qbtykX6Q2K7kU4oprIi57uc0NvMJKYArr3oJDvpJqsXo0nrebN9xSeig2h9PzVwOYrlxEQzfzdpHl07IhUTWgBrsU1WLfP5thRlwf36lPw2BwJjUkjr0bD7Btl9ihxvmprU4WXeEopUO3zBxRMaemk3Dq6ToHMM8",

  searchIndex: "purebaby-au",
  searchConfig: "https://config.search.reactify.app/?shop=purebaby-au.myshopify.com",
  gtm: "GTM-P457CB",
  googleMetaVerification: "DocQR2xd_eIuK711SBm5QoXch5_q2H2gjArJLUBIdNc",
  googleMapsApiKey: "AIzaSyC2pbhg5eMUosOwcUVcn0RWuYaqbdGMXmc",
  googleMapsApiVersion: "3.37",
  api: {
    ap21CheckStock: "https://purebaby-au.myshopify.com/apps/dotapparel21/api-checkStock",
    dapLoyalty: "https://purebaby-dotapparel-loyalty-au.web.app",
    yotpo: "https://loyalty.yotpo.com",
  },

  yotpo: {
    yotpoApiKey: "6Gs5n1kS4gC033hQYWocqAtt",
    yotpoGUID: "u4YB5yJZnOd7mvhMh6fUZg",
    downloadActionName: "Download the Purebaby App",
  },

  klaviyoApiKey: "",
  klaviyoPublicKey: "Xv7Kmh",

  emailSender: "wholesale@purebaby.com.au",

  facebook: {
    facebookAppId: "321418229190246",
  },
}
