export const Accordion = {
  baseStyle: {
    container: {
      _first: {
        borderTopWidth: 0,
      },
    },
    button: {
      px: 2,
      py: 1,
      fontSize: "18px",
      fontWeight: "semibold",
    },
    panel: {
      px: 2,
      pt: 0,
      pb: 3,
    },
  },
}
