import React, { memo, useMemo } from "react"
import { IconButton } from "@chakra-ui/react"

import { useConfigContext } from "@providers/config"
import Link from "@components/Link"
import { useWishlistContext } from "@providers/wishlist"

const WishlistWidget = () => {
  const {
    settings: { routes },
  } = useConfigContext()
  const { count } = useWishlistContext()

  const icon = useMemo(() => {
    return (
      <svg width="22" height="19" viewBox="0 0 22 19" fill={count ? "#4D4A47" : "none"} xmlns="http://www.w3.org/2000/svg">
        <title id={`Wishlist`}></title>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.4029 2.47915L11.0003 4.23992L9.59654 2.47915C7.71276 0.512158 4.65674 0.512158 2.77416 2.47915C0.890383 4.43167 0.890383 7.60347 2.77416 9.5572L10.3801 17.6331C10.8395 18.1275 10.5407 18.1275 11.0002 17.6331L19.2253 9.5572C21.1103 7.60347 21.1103 4.43167 19.2253 2.47915C17.3427 0.512158 14.2879 0.512158 12.4029 2.47915Z"
          stroke="currentColor"
        />
      </svg>
    )
  }, [count])

  return (
    <Link to={routes.WISHLIST}>
      <IconButton variant="ghost" aria-label={`Open wishlist (${count})`} icon={icon} />
    </Link>
  )
}

export default memo(WishlistWidget)
