import { TextProps } from "@chakra-ui/react"
import { colors } from "../colors"

type TextSizes = "xl" | "lg" | "md" | "sm" | "xs" | "xxs"
type TextVariants = "label"

type TextThemeProps = {
  baseStyle: TextProps
  sizes: Record<TextSizes, TextProps>
  variants: Record<TextVariants, TextProps>
}

export const Text: TextThemeProps = {
  baseStyle: {
    color: colors.text.primary,
    fontSize: "16px",
    fontFamily: "Sofia Pro",
    fontWeight: "normal",
    lineHeight: "24px",
  },
  sizes: {
    xl: {
      fontSize: "21px",
    },
    lg: {
      fontSize: "18px",
      lineHeight: "27px",
    },
    md: {
      fontSize: "16px",
      lineHeight: "24px",
    },
    sm: {
      fontSize: "14px",
      lineHeight: "21px",
    },
    xs: {
      fontSize: "12px",
      lineHeight: "18px",
    },
    xxs: {
      fontSize: "10px",
      lineHeight: "12px",
    },
  },
  variants: {
    label: {
      fontSize: "12px",
      lineHeight: "16px",
      fontWeight: "semibold",
      textTransform: "uppercase",
    },
  },
}
