import { useStaticQuery, graphql } from "gatsby"
import { useCore } from "@hooks/useCore"
import { useConfigContext } from "@providers/config"

export const useMeta = () => {
  const {
    settings: { routes },
  } = useConfigContext()
  const { helpers } = useCore()
  const { global, languages, organisation, social, tracking, scripts } = useStaticQuery<GatsbyTypes.StaticMetaQuery>(graphql`
    query StaticMeta {
      global: sanityTemplateGlobal {
        title
        description
      }
      languages: sanitySettingLanguages {
        hrefLangs: languages {
          _key
          language
          region
          url
        }
      }
      organisation: sanitySettingOrganisation {
        title
        description
        author
        logo {
          asset {
            url
          }
        }
        addressLocality
        addressRegion
        postalCode
        streetAddress
        separator
        url
      }
      social: sanitySettingSocial {
        channels {
          title
          url
        }
      }
      tracking: sanitySettingTracking {
        googleSiteVerifications
      }
      scripts: sanitySettingScripts {
        trackingHeader {
          code
        }
        trackingBody {
          code
        }
        trackingFooter {
          code
        }
      }
    }
  `)

  const mapping = {
    page: helpers.schemaWebSite,
    blog: helpers.schemaBlog,
    article: helpers.schemaArticle,
    product: helpers.schemaProduct,
    collection: helpers.schemaWebSite,
    search: helpers.schemaSearch,
    store: helpers.schemaStore,
  }

  const getData = ({ breadcrumbs, data, language, routes, template, url }) =>
    helpers.schemaData({ breadcrumbs, data, global, language, organisation, routes, social, template, tracking, url }, mapping)

  const getTags = (data: any) => helpers.metaTags(data)

  const getLanguages = (route: any) =>
    languages?.hrefLangs?.map(({ _key, language, region, url }, index) => ({
      key: _key,
      primary: !index,
      href: `${url || organisation?.url}${route || ""}`,
      hrefLang: `${region ? `${language}-${region}` : `${language}`}`,
      language: `${region ? `${language}-${region}` : `${language}`}`,
      rel: "alternate",
    }))

  const extendProductSchema = (schema: any) => {
    const price = schema.offers.highPrice || schema.offers.lowPrice
    const priceCurrency = "AUD"

    const newOffers = schema?.offers?.offers?.map((offer: any) => ({
      mpn: offer.sku,
      price,
      priceCurrency,
      itemCondition: "new",
      hasMerchantReturnPolicy: {
        "@type": "MerchantReturnPolicy",
        applicableCountry: "Au",
        returnPolicyCategory: "https://schema.org/MerchantReturnFiniteReturnWindow",
        merchantReturnDays: 60,
        returnMethod: "https://schema.org/ReturnByMail",
        returnFees: "https://schema.org/FreeReturn",
      },
      shippingDetails: {
        "@type": "OfferShippingDetails",
        shippingRate: {
          "@type": "MonetaryAmount",
          value: 10,
          currency: "AUD",
        },
        shippingDestination: {
          "@type": "DefinedRegion",
          addressCountry: "Au",
        },
        deliveryTime: {
          "@type": "ShippingDeliveryTime",
          handlingTime: {
            "@type": "QuantitativeValue",
            minValue: 2,
            maxValue: 4,
            unitCode: "DAY",
          },
          transitTime: {
            "@type": "QuantitativeValue",
            minValue: 2,
            maxValue: 4,
            unitCode: "DAY",
          },
        },
      },
    }))

    return {
      ...schema,
      offers: {
        ...schema.offers,
        offers: newOffers,
      },
    }
  }

  const getSchemas = (data: any) => {
    return [helpers.schemaOrg(data), helpers.schemaContent(data), helpers.schemaBreadcrumbs(data)]
      ?.filter(schema => schema["@type"] !== "Organization" || data?.pageUrl === `${data.siteURL}${routes.HOMEPAGE}`)
      .map(schema => {
        if (schema["@type"] === "Product") return extendProductSchema(schema)
        return schema
      })
  }

  const getTracking = () =>
    helpers?.isDomReady
      ? [
          helpers.renderTrackingScripts(scripts?.trackingHeader?.code || "", { id: "tracking-head", placement: "head" }),
          helpers.renderTrackingScripts(scripts?.trackingBody?.code || "", { id: "tracking-body", placement: "body" }),
          helpers.renderTrackingScripts(scripts?.trackingFooter?.code || "", { id: "tracking-foot", placement: "foot" }),
        ]
      : []

  return {
    getData,
    getLanguages,
    getSchemas,
    getTags,
    getTracking,
  }
}
