import React, { memo } from "react"
import { Box, HStack, Heading, Stack, StackDivider } from "@chakra-ui/react"
import { Results, Suggestions } from "@usereactify/search"

import useSearchSettings from "@app/hooks/useSearchSettings"
import useSanitySearch from "@app/hooks/useSanitySearch"
import { useConfigContext } from "@app/providers/config"
import SearchTermList from "./Search"
import LoadingResultsDesktop from "./Product/LoadingResultsDesktop"
import { Swiper, SwiperSlide } from "swiper/react"

import ProductCard from "@components/Product/ProductCard"
import { SearchSuggestions } from "./Search/SearchSuggestions"
// import { useYotpoScript } from "@app/hooks/useYotpoScript"

const InstantSearchDesktop = ({ onClose }) => {
  const { suggestions } = useSearchSettings()

  const {
    sanityResults: { learnPages, collections },
    searchQuery,
  } = useSanitySearch()

  const collectionTitles = collections.map(collection => collection.title as string)
  const learnPageTitles = learnPages.map(page => page.title as string)
  const learnPageSuggestions = suggestions.learnPages

  const [noResults, setNoResults] = React.useState(false)
  // useYotpoScript(searchQuery)
  const renderResultCardProduct = React.useCallback(
    props => {
      const badges = props?.product?.tags?.some(tag => tag?.includes("label:"))
      if (props.product.tags.includes("stl")) {
        return null
      }
      return (
        <Box key={props.product.id} mb={3.5} mr={2} onClick={onClose}>
          <ProductCard key={props.product.id} item={props.product} location={null} isInstantSearch={true} showBadgePlaceholder={!!badges} />
        </Box>
      )
    },
    [onClose]
  )

  const renderNoResults = React.useCallback(
    props => {
      return (
        <>
          <Swiper
            slidesPerView={3.7}
            spaceBetween={10}
            breakpoints={{
              1850: {
                slidesPerView: 5.7,
                spaceBetween: 20,
              },
              1400: {
                slidesPerView: 4.2,
              },
              1250: {
                slidesPerView: 3.2,
              },
              768: {
                slidesPerView: 2.5,
              },
            }}
          >
            {suggestions.products.map(({ product }) => {
              const badges = product?.tags?.some(tag => tag?.includes("label:"))
              return (
                <SwiperSlide key={product.id} mb={3.5} onClick={onClose}>
                  <ProductCard
                    key={product.id}
                    item={product}
                    location={null}
                    onClose={onClose}
                    fullSize={true}
                    showBadgePlaceholder={!!badges}
                    {...props}
                  />
                </SwiperSlide>
              )
            })}
          </Swiper>
        </>
      )
    },
    [onClose, suggestions.products]
  )

  return (
    <HStack divider={<StackDivider />} spacing={6} alignItems={`flex-start`}>
      <Stack divider={<StackDivider />} spacing={3} alignSelf="flex-start" minW="285px" maxW="285px">
        {collectionTitles.length || searchQuery !== "" ? (
          <Suggestions field="title" render={SearchSuggestions} />
        ) : (
          <SearchTermList title="Popular Searches" items={suggestions.searchTerms} onClose={onClose} />
        )}
        {learnPageTitles.length ? (
          <SearchTermList title="Learn Pages" items={learnPageTitles} query={searchQuery} link={true} />
        ) : (
          <SearchTermList title="Popular Learn Pages" items={learnPageSuggestions} link={true} onClose={onClose} />
        )}
      </Stack>
      <Box overflow="hidden" width="100%">
        <Stack spacing={2.5} overflow="hidden">
          <Heading as="h3" size="h3" fontWeight={["semibold", "light"]}>
            {noResults ? "Popular Products" : "Suggested Products"}
          </Heading>
          <Results
            pageSize={4}
            renderResultCardProduct={renderResultCardProduct}
            listStyle={{
              display: "flex",
              overflow: "auto",
            }}
            renderResultCardCallout={() => null}
            renderNoResults={renderNoResults}
            renderLoading={() => <LoadingResultsDesktop />}
          />
        </Stack>
      </Box>
    </HStack>
  )
}

export default memo(InstantSearchDesktop)
