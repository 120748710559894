/* eslint-disable */
import { gql } from "@apollo/client"

const GET_PRODUCT_PRICE_RANGES = (handles: string[]) => gql`
  query(
    $countryCode: CountryCode!
  ) @inContext(country: $countryCode) {
    ${handles?.map(
      handle => `
      product${handle?.replace(/-/g, "")}: productByHandle(handle: "${handle}") {
        handle
        priceRange {
          maxVariantPrice {
            amount
          }
          minVariantPrice {
            amount
          }
        }
      }
    `
    )}
  }
`

export default GET_PRODUCT_PRICE_RANGES

/* eslint-enable */
