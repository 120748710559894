import { colors } from "../colors"

export const Switch = {
  baseStyle: {
    thumb: {
      transform: "translate(12%, 12%)",
      _checked: {
        transform: "translate(95%, 12%)",
      },
      height: "32px",
      width: "32px",
    },
    track: {
      bg: colors.text.disabled,
      width: "67px",
      height: "40px",
      p: 0,
      _focus: {
        boxShadow: "0 0 0 2px white, 0 0 0 4px rgba(66, 153, 225, 0.6)",
      },
      _hover: {
        bg: colors.text.secondary,
        _disabled: {
          bg: colors.brand.divider,
        },
        _checked: {
          bg: colors.brand.navy.dark,
        },
      },
      _checked: {
        bg: colors.brand.navy.light,
      },
      _disabled: {
        bg: colors.brand.divider,
      },
    },
  },
}
