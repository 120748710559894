import React, { memo, useMemo } from "react"
import { Box, IconButton } from "@chakra-ui/react"
import { useCheckoutContext } from "@providers/checkout"

import CartCount from "./CartCount"
import { useAppContext } from "@providers/app"

const CartWidget: React.FC = () => {
  const { count } = useCheckoutContext()
  const { dispatch } = useAppContext()

  const handleActiveCart = React.useCallback(() => {
    dispatch({ type: "cart", payload: { activeCart: true, addedToCart: false } })
  }, [dispatch])

  const icon = useMemo(() => {
    return (
      <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.02344 0.958984H4.13863L7.67099 15.1648H21.7204" stroke="currentColor" strokeLinecap="round" />
        <path d="M5.25391 3.00781H20.288V8.06123L7.40163 12.9114" stroke="currentColor" strokeLinecap="round" />
        <ellipse cx="7.40252" cy="18.59" rx="1.43182" ry="1.43182" stroke="currentColor" />
        <ellipse cx="20.2892" cy="18.59" rx="1.43182" ry="1.43182" stroke="currentColor" />
      </svg>
    )
  }, [])

  return (
    <Box pos={"relative"}>
      <IconButton
        variant="ghost"
        title={`Open cart (${count})`}
        aria-label={`Open cart (${count})`}
        icon={icon}
        onClick={handleActiveCart}
      />
      {count ? <CartCount count={count} /> : null}
    </Box>
  )
}

export default memo(CartWidget)
