import React from "react"
import { Menu, HStack, Center } from "@chakra-ui/react"

import { useNavigation } from "@hooks/useNavigation"
import NavigationHeaderDesktopItem from "./NavigationHeaderDesktopItem"
import { NavigationHeaderProps } from "../NavigationHeader"

const NavigationHeaderDesktop = ({ active, setActive }: NavigationHeaderProps) => {
  const { header: items } = useNavigation()

  const handleActive = React.useCallback((key: string | null) => setActive(key), [setActive])
  const handleClick = React.useCallback(() => setActive(null), [setActive])

  return (
    <Menu isLazy>
      <Center>
        <HStack as="nav" role="navigation" aria-label="Main navigation" spacing={0}>
          {items.map((item: MenuItemDesktop) => (
            <NavigationHeaderDesktopItem
              {...item}
              active={active}
              key={item.title.toString()}
              handleActive={handleActive}
              handleClick={handleClick}
            />
          ))}
        </HStack>
      </Center>
    </Menu>
  )
}

export default React.memo(NavigationHeaderDesktop)
