import phone from "google-libphonenumber/dist/libphonenumber"
const PNF = phone.PhoneNumberFormat
const phoneUtil = phone.PhoneNumberUtil.getInstance()

export const useValidation = () => {
  const isEmail = (email: string) => {
    const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/
    return !!email.match(regex)
  }

  const formatPhoneNumber = (phone: string) => {
    try {
      return phoneUtil.format(phoneUtil.parseAndKeepRawInput(phone, "AU"), PNF.E164)
    } catch (e) {
      return null
    }
  }

  return { isEmail, formatPhoneNumber }
}
