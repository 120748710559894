import React, { useState, useEffect, useCallback, PropsWithChildren } from "react"
import { useQuery } from "@apollo/client"
import { useCore, useStorage } from "@hooks/useCore"
// import { useCheckout } from "@hooks/useCheckout"
import { useConfigContext } from "@providers/config"
import GET_CUSTOMERMETAFIELDS from "@app/queries/getCustomerMetafields"
import { useKlaviyo } from "@app/hooks/useKlaviyo"

type ContextProps = {
  customer: any
  customerMetafields: any
  getCustomer: () => void
  setCustomer: (customer: any) => void
  clearCustomer: () => void
  saveCustomer: (customerAccessToken: any) => void
}

export const CustomerContext = React.createContext<ContextProps | undefined>(undefined)

export const CustomerProvider = ({ children }: PropsWithChildren) => {
  const [customerMetafields, setCustomerMetafields] = useState()

  const {
    graphql: {
      queries: { GET_CUSTOMER },
    },
  } = useCore()
  const { getStorage, setStorage, removeStorage } = useStorage()
  const {
    settings: { keys },
  } = useConfigContext()
  // const { updateCustomer } = useCheckout()
  const { identify } = useKlaviyo()
  const [customer, setCustomer] = useState(false)

  const { refetch: getCustomerQuery } = useQuery(GET_CUSTOMER, {
    fetchPolicy: "no-cache",
    skip: true,
  })

  const { refetch: getCustomerMetafieldsQuery } = useQuery(GET_CUSTOMERMETAFIELDS, {
    fetchPolicy: "no-cache",
    skip: true,
  })

  useEffect(() => {
    getCustomer()
    getCustomerMetafields()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getCustomerMetafields = useCallback(async () => {
    const accessToken = getStorage(keys.customer)?.accessToken

    if (accessToken) {
      try {
        const {
          data: { customer, customerUserErrors },
        } = await getCustomerMetafieldsQuery({
          customerAccessToken: accessToken,
        })
        if (!customerUserErrors?.length) setCustomerMetafields(customer)
      } catch (e) {
        console.error((e as Error).message)
      }
    }
  }, [getStorage, keys.customer, getCustomerMetafieldsQuery])

  const getCustomer = useCallback(async () => {
    const accessToken = getStorage(keys.customer)?.accessToken

    if (accessToken) {
      try {
        const {
          data: { customer, customerUserErrors },
        } = await getCustomerQuery({
          customerAccessToken: accessToken,
          countryCode: "AU",
        })
        if (!customerUserErrors?.length) {
          await getCustomerMetafields(accessToken)
          window.localStorage.setItem("email", customer.email)
          setCustomer(customer)
        }

        if (customerUserErrors?.length) removeStorage(keys.customer)
      } catch (e) {
        console.error((e as Error).message)
      }
    }
  }, [getStorage, keys.customer, getCustomerQuery, removeStorage, getCustomerMetafields, setStorage])

  const saveCustomer = useCallback(
    async customerAccessToken => {
      try {
        const { accessToken, expiresAt } = customerAccessToken
        setStorage(keys.customer, { accessToken, expiresAt })
        getCustomer()
        // updateCustomer(accessToken)
      } catch (e) {
        console.error((e as Error).message)
      }
    },
    [getCustomer, setStorage, keys]
  )

  const clearCustomer = useCallback(() => {
    try {
      removeStorage(keys.customer)
      window.localStorage.removeItem("email")
      setCustomer(false)
      setCustomerMetafields(null)
    } catch (e) {
      console.error((e as Error).message)
    }
  }, [setCustomer, removeStorage, keys])

  const contextValue = React.useMemo<ContextProps>(
    () => ({
      customer,
      getCustomer,
      setCustomer,
      saveCustomer,
      clearCustomer,
      customerMetafields,
    }),
    [customer, getCustomer, saveCustomer, clearCustomer, customerMetafields]
  )

  // intentionally only run on customer change
  useEffect(() => {
    if (customer?.email) {
      identify(customer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer])

  return <CustomerContext.Provider value={contextValue}>{children}</CustomerContext.Provider>
}

export const useCustomerContext = (): ContextProps => ({ ...React.useContext(CustomerContext) }) as ContextProps
