export const Tabs = {
  baseStyle: {
    tablist: {
      overflowX: "scroll",
      scrollbarWidth: "none",
      "::-webkit-scrollbar": { display: "none" },
    },
    tabpanel: {
      px: 0,
      py: 2.5,
    },
    tab: {
      flexShrink: 0,
      pb: 2,
      px: 2.5,
    },
  },
  variants: {
    line: {
      tablist: {
        borderBottom: "none",
      },
      tab: {
        color: "text.secondary",
        borderBottom: "4px solid",
        borderColor: "brand.divider",
        _selected: { color: "text.primary", borderColor: "brand.navy.light", bg: "none", outline: "none" },
        _focus: { boxShadow: "none", bg: "none" },
        _active: { bg: "none" },
      },
    },
  },
}
