import React from "react"
import { Box, ColorProps, HStack, Text, TextProps, ThemingProps } from "@chakra-ui/react"
import Link from "@components/Link"
import Icon from "@components/Icon/Icon"

export interface NavigationHeaderMobileItem {
  item: MenuItemMobile
  primary: boolean
  onMenuNext: VoidFunction
}

type SizeType = ThemingProps<"text">["size"]
type ColorType = ColorProps["color"]
type FontWeightType = TextProps["fontSize"]

const renderLink = (item: MenuItem, size: SizeType, color: ColorType, fontWeight: FontWeightType, onMenuNext: VoidFunction) => {
  switch (item.type) {
    case "link":
      return (
        <Text as={Link} size={size} w="full" to={item.url} color={color} fontWeight={fontWeight}>
          {item.title}
        </Text>
      )
    case "sub":
      return (
        <HStack key={item.title} justifyContent="space-between" cursor="pointer" onClick={onMenuNext}>
          <Text size={size} color={color} fontWeight={fontWeight}>
            {item.title}
          </Text>
          <Icon name="chevronRight" size="24px" color="text.icon" />
        </HStack>
      )
    case "collection":
      return (
        <Text as={Link} size={size} w="full" to={item.url} color={color} fontWeight={fontWeight}>
          {item.title}
        </Text>
      )
    case "page":
      return (
        <Text as={Link} size={size} w="full" to={item.url} color={color} fontWeight={fontWeight}>
          {item.title}
        </Text>
      )
    case "product":
      return (
        <Text as={Link} size={size} w="full" to={item.url} color={color} fontWeight={fontWeight}>
          {item.title}
        </Text>
      )
    default:
      return <></>
  }
}

const NavigationHeaderMobileItem = ({ item, primary, onMenuNext }: NavigationHeaderMobileItem) => {
  const size: SizeType = primary ? "xl" : "lg"
  const fontWeight: FontWeightType = primary ? "light" : "regular"
  const color: ColorType = item.accent === "sale" ? "brand.sale" : "text.primary"

  return <Box>{renderLink(item, size, color, fontWeight, onMenuNext)}</Box>
}

export default React.memo(NavigationHeaderMobileItem)
