import React, { useMemo } from "react"
import { Image as ChakraImage, ImageProps as ChakraImageProps } from "@chakra-ui/react"
import { Illustrations, Benefits } from "./index"

export type ImageKeys = keyof typeof Illustrations | keyof typeof Benefits

export interface ImageProps extends ChakraImageProps {
  name?: ImageKeys
}

const images = {
  ...Illustrations,
  ...Benefits,
}

const Image = ({ name, maxHeight = "100%", src, width, height, ...props }: ImageProps) => {
  const image = useMemo(() => (name ? images[name] : src), [name, src])
  return (
    <ChakraImage
      // style={{ display: "none" }}
      // onLoad={e => (e.target.style.display = "block")}
      loading={props?.loading || "lazy"}
      src={image}
      height={height}
      width={width}
      {...props}
      objectFit={"cover"}
      maxHeight={maxHeight}
    />
  )
}

export default React.memo(Image)
