import React, { memo, forwardRef, useMemo } from "react"
import { Icon as ChakraIcon, IconProps as ChakraIconProps, LayoutProps } from "@chakra-ui/react"
import { BrandIcons, UIIcons, SocialIcons, PaymentIcons, MapIcons } from "./index"

export type IconKeys =
  | keyof typeof BrandIcons
  | keyof typeof UIIcons
  | keyof typeof SocialIcons
  | keyof typeof PaymentIcons
  | keyof typeof MapIcons

type Icon = {
  id: string
  url: string
  viewBox: string
}

type Icons = {
  [key: IconKeys | string]: Icon
}

export interface IconProps extends ChakraIconProps {
  name: IconKeys
  title?: string
  size?: LayoutProps["height"]
}

const icons: Icons = {
  ...BrandIcons,
  ...UIIcons,
  ...SocialIcons,
  ...PaymentIcons,
  ...MapIcons,
}

const Icon = forwardRef(({ name, title, size, color = "text.icon", width = "20px", height = "20px", ...props }: IconProps) => {
  const icon = useMemo(() => icons[name], [name])

  return icon ? (
    <ChakraIcon id={icon.id} height={size || height} width={size || width} color={color} fill="none" viewBox={icon.viewBox} {...props}>
      <title id={`${icon.id}Title`}>{title || name}</title>
      <use xlinkHref={icon.url} />
    </ChakraIcon>
  ) : (
    <p>[{title}]</p>
  )
})

export default memo(Icon)
