import { useCallback, useMemo, useState } from "react"

export const useMobileMenu = (menu: MenuItem[]) => {
  const [menuHistory, setMenuHistory] = useState<string[]>([])

  const optimiseMenu = useCallback((menu: MenuItem) => {
    let result = {
      ...menu,
    }

    // Collapse menus which only have one menu item identical to the current menu
    if (result?.items?.length === 1) {
      result = {
        ...result,
        items: result?.items?.[0]?.items,
      }
    }

    if (result?.items?.length > 0) {
      result.items = result?.items?.map((item: any) => optimiseMenu(item))
    }

    return result
  }, [])

  const optimisedMenu = useMemo(() => menu?.map((item: any) => optimiseMenu(item)), [menu, optimiseMenu])

  const getMenu = useCallback(
    (index = 0, menuNode: MenuItem | null = null): MenuItem => {
      const currentKey = menuHistory[index]
      const compareFn = ({ key }: MenuItem) => key === currentKey
      const newNode = menuNode ? menuNode.items.find(compareFn) : optimisedMenu.find(compareFn)

      if (index >= menuHistory.length - 1) {
        return newNode
      }

      return getMenu(index + 1, newNode)
    },
    [optimisedMenu, menuHistory]
  )

  const onMenuPush = (key: string) => setMenuHistory(menuHistory => [...menuHistory, key])
  const onMenuPop = () => setMenuHistory(menuHistory => menuHistory.slice(0, menuHistory.length - 1))
  const onReset = () => setMenuHistory([])

  const currentMenu = menuHistory.length ? getMenu() : ({ items: optimisedMenu } as MenuItem)
  const hasPrevious = !!menuHistory.length

  return { currentMenu, hasPrevious, onMenuPush, onMenuPop, onReset }
}
