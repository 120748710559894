import { colors } from "../colors"
import { borders } from "../borders"

export const Radio = {
  baseStyle: {},
  variants: {
    primary: {
      parts: ["control", "label"],
      label: {
        fontSize: "16px",
      },
      control: {
        border: borders.radio.black + " !important",
        background: colors.white,
        color: colors.accents.navy.primary,
        width: "24px",
        height: "24px",
        _hover: {
          border: borders.radio.black,
          background: "white",
          color: colors.accents.navy.primary,
        },
        _before: {
          //marginLeft: "-1px",
          // marginBottom: "-1px",
          width: "14px",
          height: "14px",
        },
        _checked: {
          border: borders.radio.black,
          background: "white",
          color: colors.accents.navy.primary,
          width: "24px",
          height: "24px",
          _hover: {
            border: borders.radio.black,
            background: "white",
            color: colors.accents.navy.primary,
          },
          _before: {
            //marginLeft: "-1px",
            // marginBottom: "-1px",
            width: "14px",
            height: "14px",
          },
        },
      },
    },
  },
}
