import React from "react"
import { As, Button, ButtonProps, Heading, HStack } from "@chakra-ui/react"
import Icon, { IconKeys } from "@components/Icon/Icon"

export type TextButtonProps = Omit<ButtonProps, "leftIcon" | "rightIcon" | "variant"> & {
  icon?: IconKeys
  as?: As<any>
  to?: string
  iconPlacement?: "left" | "right"
  iconSpacing?: number
  iconSize?: string
  fill?: string
}

const TextButton = ({
  icon,
  iconPlacement = "right",
  iconSize = "16px",
  children,
  iconSpacing = 0.5,
  fill = "none",
  ...props
}: TextButtonProps) => {
  const iconEl = icon ? <Icon name={icon} size={iconSize} color="inherit" fill={fill} /> : null

  return (
    <Button {...props} variant="text" px={0}>
      <HStack alignItems="center" spacing={iconSpacing}>
        {icon && iconPlacement === "left" && iconEl}
        <Heading as="span" size="inherit" fontWeight="inherit" color="inherit" whiteSpace={"break-spaces"}>
          {children}
        </Heading>
        {icon && iconPlacement === "right" && iconEl}
      </HStack>
    </Button>
  )
}

export default TextButton
