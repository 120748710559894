import { gql } from "@apollo/client"

const GET_SEARCH_DATA = gql`
  query GetSearchData($search: String, $limit: Int) {
    learnPages: allArticle(where: { title: { matches: $search } }, limit: $limit) {
      _key
      title
      description
      image {
        asset {
          _id
          url
        }
        alt
      }
      url
      handle
      category {
        _key
        handle {
          current
        }
      }
      attributes {
        readDuration
        publishedAt
      }
    }
    collections: allCollection(where: { title: { matches: $search } }, limit: $limit) {
      _key
      title
    }
    pages: allPageFlexible(where: { title: { matches: $search } }, limit: $limit) {
      _key
      title
      description
      url
      handle {
        current
      }
    }
  }
`
export default GET_SEARCH_DATA
