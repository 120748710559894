import { useStaticQuery, graphql } from "gatsby"
import { useCore } from "@hooks/useCore"
import { useRoutes } from "@hooks/useRoutes"
import { useConfigContext } from "@providers/config"

import type { Location } from "@root/types/global"

type BreadcrumbProps = {
  data: any
}

export const useBreadcrumb = (location?: Location) => {
  const {
    helpers: { capitalise },
  } = useCore()
  const {
    settings: { routes },
  } = useConfigContext()
  const { urlResolver } = useRoutes()
  const breadcrumb = urlResolver({ title: "Home" }, "/home")

  const { global, organisation } = useStaticQuery<GatsbyTypes.StaticBreadcrumbsQuery>(graphql`
    query StaticBreadcrumbs {
      global: sanityTemplateGlobal {
        title
      }
      organisation: sanitySettingOrganisation {
        title
        separator
      }
    }
  `)

  const cleanTitle = (title: string) =>
    title
      ?.replace(global?.title || "", "")
      ?.replace(organisation?.title || "", "")
      ?.replace(` ${organisation?.separator} `, "")
      ?.replace(organisation?.separator || "", "")

  const breadcrumbState = (state: any) => {
    return location?.pathname.includes(`${routes.COLLECTION}/`) && typeof window !== "undefined"
      ? {
          ...state,
          breadcrumbs: [
            {
              title: cleanTitle(window.document.title),
              url: location.pathname,
            },
          ],
        }
      : state
  }

  const parentResolver = (parentCollections: Array<string>, item: any) => {
    if (item?.parentCollection?.shopify?.handle) {
      parentCollections.unshift(item.parentCollection)
      parentResolver(parentCollections, item.parentCollection)
    }
  }

  const buildBreadcrumbs = ({ data }: BreadcrumbProps): Array<BreadcrumbProps> => {
    const items: Array<any> = [breadcrumb]
    const item =
      data?.page ||
      data?.product ||
      data?.collection ||
      data?.article ||
      data?.journal ||
      data?.search ||
      data?.store ||
      data?.checklist?.link ||
      data
    const currentUrl = urlResolver(item)?.url
    const paths = currentUrl?.split("/")?.slice(1)

    if (location?.pathname.includes(routes.PRODUCT)) {
      if (location.state?.breadcrumbs) {
        items.push(...location.state.breadcrumbs)
      }
    }

    if (location?.pathname.includes(routes.SEARCH)) {
      if (location.state?.breadcrumbs) {
        items.push(...location.state.breadcrumbs)
      }
    }

    if (item) {
      if (data?.collection) {
        const parentCollections = [item]
        parentResolver(parentCollections, item)
        items.push({ title: "Collections", url: routes.COLLECTION })
        parentCollections.map(collection => {
          urlResolver(collection)
          items.push({
            ...item,
            title: collection?.title?.trim(),
            url: urlResolver(collection).url,
          })
        })
      } else if (data?.checklist) {
        items.push({ title: "Checklists", url: "/checklists" })
        items.push({
          ...item,
          title: item?.title?.trim(),
          url: `/pages${urlResolver(item)?.url}`,
        })
      } else if (data?.journal) {
        items.push({
          ...item,
          title: data.url.includes("learn") ? item?.title.trim() : item?.title?.replace("Purebaby", "")?.trim(),
          url: urlResolver(item)?.url,
        })
      } else if (data?.search) {
        items.push({
          ...item,
          title: item?.title?.replace("Purebaby", "")?.trim(),
          url: urlResolver(item)?.url,
        })
        items.push({
          ...item,
          title: "Search",
          url: urlResolver(item)?.url,
        })
      } else {
        paths?.map(path => {
          if (
            path === item?.handle?.current ||
            path === item?.shopify?.handle ||
            location?.pathname.includes(routes.STORE) ||
            location?.pathname.includes(routes.CHECKLIST)
          ) {
            items.push({
              ...item,
              title: item?.title?.trim(),
              url: urlResolver(item)?.url,
            })
          } else {
            if (path !== "products" && path !== "collections") {
              items.push({
                ...item,
                title: `${capitalise(path?.trim())}`,
                url: `/${path}`,
              })
            }
          }
        })
      }
    }

    // filter duplicates
    return items?.filter((item, index, self) => {
      return self.findIndex(i => i?.url === item?.url) === index
    })
  }

  return {
    breadcrumb,
    buildBreadcrumbs,
    breadcrumbState,
  }
}
